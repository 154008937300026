/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from '../TemplateDetailsAuditor/index.less';
import {useTranslation} from 'react-i18next';

import { Card, Form, Input/*, Button, Divider*/ } from 'antd';
// import { EnvironmentOutlined } from '@ant-design/icons';
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon';

const TemplateDetailsLocation = () => {
  const {t} = useTranslation('templates');

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <Form layout="vertical" className="custom-form-big">
        <Form.Item label={t('details.location')} name="location" className="suffix">
          <Input
            placeholder={t('details.placeholderLocation')}
            className="addonAfter"
            suffix={
              <div className={'flex flex-row justify-center items-center'}>
                <MapPinLineIcon size="18px" color="rgba(0, 0, 0, 0.25)" />
              </div>
            }
          />
        </Form.Item>
      </Form>
      {/*<div className={'row-flex justify-between'}>*/}
      {/*  <Button type="text" icon={<EditOutlined />} >{t('details.newNote')}</Button>*/}
      {/*  <Divider className={styles.templateDetailCardDivider} type="vertical" />*/}
      {/*  <Button type="text" icon={<FlagOutlined />}>{t('details.incidence')}</Button>*/}
      {/*  <Divider className={styles.templateDetailCardDivider} type="vertical" />*/}
      {/*  <Button type="text" icon={<CheckSquareOutlined />}>{t('details.task')}</Button>*/}
      {/*</div>*/}
    </Card>
  );
};

TemplateDetailsLocation.propTypes = {
  entity: PropTypes.object,
};
TemplateDetailsLocation.defaultProps = {
  entity: {},
};

export default memo(TemplateDetailsLocation);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsLocation = (value, entity) => {
  return (
    <TemplateDetailsLocation entity={entity}/>
  );
};
