import {useMemo, useState, useEffect} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import NomenclaturesActivitiesApiService from '@/modules/settings/services/NomenclaturesActivitiesApiService';
import {CACHE_KEY_NOMENCLATURES_ACTIVITIES} from '@/modules/settings/constants/nomenclaturesActivities';
import {fields} from '@/modules/settings/constants/nomenclaturesActivities.form';
import {useQuery} from 'react-query';

export const useGetOneNomenclaturesActivities = createGetOneHook(NomenclaturesActivitiesApiService, 'id', CACHE_KEY_NOMENCLATURES_ACTIVITIES);
export const useFields = createFieldsHook('nomenclaturesActivities', fields);

export const useGetActivities = (ids) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(ids?.length)};
  }, [ids]);

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    if (ids?.length === 0) setActivities([]);
  }, [ids?.length]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_NOMENCLATURES_ACTIVITIES, ids], async () => {
    ids?.map(async (id) => {
      const {data} = await NomenclaturesActivitiesApiService.getOneActivity({id});
      setActivities(prevState => ([...prevState?.filter(x => ids?.includes(x?._id)), data]));
      return data;
    });
    return {data: activities};
  }, queryConfig);

  let set = new Set(activities.map( JSON.stringify ) );
  let realData = Array.from( set ).map( JSON.parse );

  return {isLoading, isError, error, data, activities: realData};
};
