/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Table, Typography } from 'antd';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import Incidents from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/Incidents';

const { Text } = Typography;

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 999999;

const scroll = { x: 500, y: 250 };

const getKeyToTranslate = (key) => {
  switch (key) {
    case 'noFormat': return 'ruleNone';
    case 'greaterThan': return 'ruleGreaterThan';
    case 'lessThan': return 'ruleLessThan';
    case 'equal': return 'ruleEqual';
    default: return 'ruleBetween';
  }
};

const getMeasure = (value) => {
  switch (value) {
    case 'celsius': return '°C';
    case 'fahrenheit': return '°F';
    case 'others': return 'others';
    case 'chlorine': return 'chlorine';
    case 'co2': return 'co2';
    case 'atp': return 'atp';
    case 'mm': return 'mm';
    case 'cm': return 'cm';
    case 'm': return 'm';
    case 'm2': return 'm2';
    case 'm3': return 'm3';
    case 'ph': return 'Ph';
    case 'ppm': return 'ppm';
      // case 'kelvin': return 'Kelvin';
      // case 'rankine': return 'Rankine';
      // case 'réaumur': return 'Réaumur';
      // case 'rømer': return 'Rømer';
      // case 'delisle': return 'Delisle';
    default: return 'none';
  }
};

const TemplateDetailsInSituMeasure = ({question, isIncidence, title, idx}) => {
  const {t} = useTranslation('templates');

  const cols = useMemo(() =>
    question?.readingRegistry?.options?.map(item =>
      ({...item, title: item?.label, fixed: item?.key === '_id' ? 'left' : null, editable: true, dataIndex: item?.key})
    ) || [],[question?.readingRegistry?.options]);

  const readingRegistryResponses = useMemo(() => {
    return question?.answer?.templateAnswer?.readingRegistry || [];
  }, [question?.answer?.templateAnswer?.readingRegistry]);

  const placesMeasure = useMemo(() => {
    return question?.readingRegistry?.placesMeasure || [];
  }, [question?.readingRegistry?.placesMeasure]);

  const dataSource = useMemo(() => readingRegistryResponses?.length ? readingRegistryResponses?.reduce((result, item) => {
    const placeKey = placesMeasure.find((x) => x._id === item.placesMeasure)._id;
    const placeObj = result.find((x) => x._id === placeKey);

    if (!placeObj) {
      result.push({
        _id: placeKey,
        [item.optionKey]: item.value,
      });
    } else {
      placeObj[item.optionKey] = item.value;
    }

    return result;
  }, placesMeasure) : placesMeasure || [], [readingRegistryResponses, placesMeasure]);

  const columns = useMemo(() => cols.map((col) => {
    if (col.key === 'place') {
      return {
        ...col,
        dataIndex: col.key,
        fixed: 'left',
        width: 200,
      };
    }

    return {
      ...col,
      width: 160,
      dataIndex: col.key,
      editable: true,
      title: <div className="w-full">
        <div className="w-full">{col.title}</div>

        <div className="w-full flex items-start justify-center flex-col" style={{color: '#4db7f9'}}>
          <div>
            {t(getMeasure(col?.measure)) || 'N/A'}
          </div>

          <div className="w-full flex items-center justify-start">
            <div className="mr-2">{t(getKeyToTranslate(col?.operation))}</div>
            {col?.operation === 'noFormat'
              ? col?.minValue
              : col?.operation === 'between'
                ? <Text
                  style={{ width: 100, color: 'var(--primary-color)' }}
                  ellipsis={{ tooltip: `${col?.minValue} - ${col?.maxValue}` }}
                >
                  {`(${col?.minValue || DEFAULT_MIN} - ${col?.maxValue || DEFAULT_MAX})`}
                </Text>
                : `(${col?.minValue || 0})`}
          </div>
        </div>
      </div>
    };
  }), [cols, t]);

  if(isIncidence){
    return <Card
      key={idx}
      className={classNames(styles.templateTextCard, idx !==0 && 'mt-2')}
      bodyStyle={{padding: '6px'}}
    >
      <div>
        {title}
      </div>
      <div className={'font-bold mb-1'}>{question?.name || question?.label}</div>
      {!dataSource || dataSource?.length === 0 ?
        'N/A' : <Table
          rowClassName={() => 'editable-row'}
          className={styles.inSituMeasureTable}
          dataSource={dataSource}
          columns={columns}
          scroll={scroll}
        />}
    </Card>;
  }

  return (
    <Card
      className={styles.templateTextCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      {!dataSource || dataSource?.length === 0 ?
        'N/A' :
        <Table
          rowClassName={() => 'editable-row'}
          className={styles.inSituMeasureTable}
          dataSource={dataSource}
          columns={columns}
          scroll={scroll}
          pagination={false}
        />}
      {question?.issuesAudit?.length > 0 && <Incidents data={question?.issuesAudit} question={question}/>}
    </Card>
  );
};

TemplateDetailsInSituMeasure.propTypes = {
  question: PropTypes.object,
  title: PropTypes.string,
  idx: PropTypes.number,
  isIncidence: PropTypes.bool,
};
TemplateDetailsInSituMeasure.defaultProps = {
  question: {},
};

export default memo(TemplateDetailsInSituMeasure);
