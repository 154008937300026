import {useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import NomenclaturesPaymentTypeApiService from '@/modules/settings/services/NomenclaturesPaymentTypeApiService';
import {CACHE_KEY_NOMENCLATURES_PAYMENT_TYPE} from '@/modules/settings/constants/nomenclaturesPaymentType';
import {fields} from '@/modules/settings/constants/nomenclaturesPaymentType.form';
import {useQuery} from 'react-query';

export const useGetOneNomenclaturesPaymentType = createGetOneHook(NomenclaturesPaymentTypeApiService, 'id', CACHE_KEY_NOMENCLATURES_PAYMENT_TYPE);
export const useFields = createFieldsHook('nomenclaturesPaymentType', fields);

export const useGetOne= (paymentId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(paymentId)};
  }, [paymentId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_NOMENCLATURES_PAYMENT_TYPE, paymentId], async () => {
    const {data} = await NomenclaturesPaymentTypeApiService.getCustomOne({paymentId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};
