import React, {memo, useMemo} from 'react';
import {userOptions} from '@/settings';
import PropTypes from 'prop-types';
import UserActionsMenu from '@/components/UserOptions/components/UserActionsMenu';
import NavMenu from '@/layout/Menu';
import compact from 'lodash/compact';
import {useHandleClick} from '@/components/UserOptions/hooks';
import useNavigation from '@/hooks/useNavigation';
import classNames from 'classnames';
import {useResponsive} from '@/contexts/ResponsiveContext';

const DEFAULT_CONFIG = {
  options: [],
  useHandleClick
};

const UserOptions = ({theme, mode, className, collapsed, disabled, popupProps}) => {
  const config = useMemo(() => Object.assign({}, DEFAULT_CONFIG, userOptions), []);
  const options = useMemo(() => compact(config?.options), [config]);
  const menu = useNavigation(options);

  const {useHandleClick} = config;

  const handleClick = useHandleClick();
  const {isMobile} = useResponsive();

  return <div className={classNames(className, 'sidebar-user-options-menu', isMobile ? 'sidebar-user-options-menu-mobile' : '')}>
    <NavMenu mode={mode} menu={menu} theme={theme} onClick={handleClick} menuProps={{selectable: false}}
      subMenuProps={{disabled}}/>
    {config?.userActions &&
    <UserActionsMenu
      popupProps={popupProps}
      config={config?.userActions} mode={mode} theme={theme} collapsed={collapsed}
      onClick={handleClick} subMenuProps={{disabled}}/>}
  </div>;
};

export default memo(UserOptions);

UserOptions.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  popupProps: PropTypes.object,
  theme: PropTypes.oneOf(['dark', 'light']),
  collapsed: PropTypes.any,
  disabled: PropTypes.bool,
};

UserOptions.defaultProps = {
  mode: 'inline',
  theme: 'dark',
};
