import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {message} from 'antd';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import ClientsApiService from '@/modules/clients/services/ClientsApiService';
import {CACHE_KEY_CLIENT, CACHE_KEY_LIST_CLIENTS} from '@/modules/clients/contants/clients';
import {fields} from '@/modules/clients/contants/clients.form';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {useTranslation} from 'react-i18next';
import SelectCountryEmployee from '@/components/SelectCountry';
import SelectStateEmployee from '@/components/SelectStateCell';
import SelectCityEmployee from '@/components/SelectCityCell';
import {useParams, useLocation} from 'react-router-dom';
import ClientsEstablishmentApiService from '@/modules/clients/services/ClientsEstablishmentApiService';
import {CACHE_KEY_LIST_CLIENTS_DIRECTORY} from '@/modules/clients/contants/clientsDirectory';
import ClientStatus from '@/modules/clients/components/ClientStatus';
import useAlerts from '@/hooks/useAlerts';
import {CACHE_KEY_LIST_CLIENTS_TREE} from '@/modules/clients/contants/clientsTree';
import {CACHE_KEY_LIST_CLIENT_CHILD_STRUCTURES} from '@/modules/clients/contants/clientChildrenStructures';

export const useGetOneClients = createGetOneHook(ClientsApiService, 'id', CACHE_KEY_CLIENT);

export const useGetOneClient = (clientId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(clientId)};
  }, [clientId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_CLIENT, clientId], async () => {
    const {data} = await ClientsApiService.getOneClient({clientId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};

export const useGetOneClientForAudit = (clientId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(clientId)};
  }, [clientId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_CLIENT, clientId], async () => {
    if (typeof clientId !== 'string') return clientId;

    const {data} = await ClientsApiService.getOneClient({clientId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};

export const useGetOneClientCombined = (clientId, type) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(clientId && type)};
  }, [clientId, type]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_CLIENT, CACHE_KEY_LIST_CLIENTS_DIRECTORY, clientId, type], async () => {
    if (type === 'STRUCTURE') {
      const {data} = await ClientsApiService.getOneClient({clientId});
      return data;
    } else if (type === 'ESTABLISHMENT') {
      const {data} = await ClientsEstablishmentApiService.getOneEstablishment({clientId});
      return data;
    }
  }, queryConfig);
  return {isLoading, isError, error, data};
};

const useFieldsBase = createFieldsHook('clients', fields);

export const useFields = (isViewMode, form, initValues) => {
  const fields = useFieldsBase(isViewMode);
  const {t} = useTranslation('clients');

  const {id} = useParams();
  const {pathname, id: idByState} = useLocation();

  const initialValues = initValues || form.getFieldsValue();

  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState('');
  const [active, setActive] = useState(form?.getFieldValue('active') || true);

  const clearOnChange = useCallback((type) => {
    type === 'country' ? form?.setFieldsValue({province: null, municipality: null}) : form?.setFieldsValue({municipality: null});
  }, [form]);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) setActive(true);
  }, [form, initialValues]);

  useEffect(() => {
    if (initialValues?.country) {
      setCountryId(initialValues?.country);
    }
  }, [initialValues?.country]);

  useEffect(() => {
    if (initialValues?.provinceOrState || initialValues?.province) setStateId(initialValues?.provinceOrState || initialValues?.province);
  }, [initialValues?.province, initialValues?.provinceOrState]);

  return useMemo(() => {
    const fieldsMap = [...fields];
    if (!isViewMode) {
      fieldsMap.splice(
        3, 0,
        {
          key: 'country',
          placeholder: t('form.placeholderEmployeeCountry'),
          label: t('form.country'),
          colSpan: 1,
          onlyEdit: true,
          initialValue: initialValues?.country,
          // eslint-disable-next-line react/display-name
          widget: ({...props}) => <SelectCountryEmployee setCountryId={setCountryId} clearOnChange={() => clearOnChange('country')} {...props} />
        }
      );
      fieldsMap.splice(
        4, 0,         {
          key: 'province',
          placeholder: t('form.placeholderEmployeeProvinceOrState'),
          label: t('form.provinceOrState'),
          colSpan: 1,
          dependencies: ['country'],
          onlyEdit: true,
          disabled: !countryId,
          initialValue: initialValues?.provinceOrState || initialValues?.province,
          // eslint-disable-next-line react/display-name
          widget: ({...props}) => <SelectStateEmployee countryId={countryId} setStateId={setStateId} translation="clients" clearOnChange={() => clearOnChange('province')} {...props} />
        }
      );
      fieldsMap.splice(
        5, 0, {
          key: 'municipality',
          placeholder: t('form.placeholderEmployeeMunicipality'),
          label: t('form.municipality'),
          colSpan: 1,
          dependencies: ['province'],
          onlyEdit: true,
          disabled: !stateId,
          initialValue: initialValues?.municipality,
          // eslint-disable-next-line react/display-name
          widget: ({...props}) => <SelectCityEmployee stateId={stateId} translation="clients" {...props} />
        }
      );
      fieldsMap.splice(
        8, 0, {
          key: 'reportType',
          label:  t('form.reportType.label'),
          placeholder:  t('form.reportType.placeholder'),
          required: true,
          widget: 'select',
          initialValue: 'percent',
          options: [
            {label: t('form.reportType.percent'), value: 'percent'},
            {label: t('form.reportType.issues'), value: 'issues'},
          ],
          className: 'custom-select',
          colSpan: 1
        }
      );
      fieldsMap.splice(
        19, 0, {
          key: 'active',
          label: t('form.status'),
          colSpan: 1,
          widget: ClientStatus,
          initialValue: true,
          onlyEdit: true,
          value: active,
          onChange: () => setActive(!active)
        }
      );
      fieldsMap.splice(
        20, 0, {
          key: 'inactivityDate',
          label: t('form.inactivityDate'),
          placeholder: t('form.placeholderInactivityDate'),
          widget: 'date-picker',
          mode: 'date',
          colSpan: 1,
          className: 'custom-date-picker',
          dependencies: ['active'],
          widgetProps: {
            format: 'DD-MM-YYYY',
            mode: 'date',
            disabled: active && !initialValues?.inactivityDate
          }
        }
      );
      if (pathname?.includes(id+'/structure') || idByState)
        fieldsMap.splice(
          fieldsMap?.length, 0, {
            key: 'parent',
            colSpan: 1,
            onlyEdit: true,
            initialValue: id || idByState,
            className: 'hidden-field',
            widgetProps: {
              hidden: true
            }
          }
        );
    }
    return fieldsMap;
  }, [active, clearOnChange, countryId, fields, id, idByState, initialValues?.country, initialValues?.inactivityDate, initialValues?.municipality, initialValues?.province, initialValues?.provinceOrState, isViewMode, pathname, stateId, t]);
};

export const useGetChildren = (parentId) => {

  const queryConfig = useMemo(() => {
    return {enabled: !!(parentId)};
  }, [parentId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_LIST_CLIENTS, parentId], async () => {
    const {data} = await ClientsApiService.getChildren({parentId});
    return (data);
  }, queryConfig);

  return {isLoading, isError, error, data};
};

export const useRemove = (parentId) => {
  const {t} = useTranslation('common');
  const queryClient = useQueryClient();
  const {putErrorCode} = useAlerts();

  const {mutateAsync: remove, isLoading} = useMutation(ClientsApiService.delete,
    {
      onSuccess: () => {
        message.success(t('deleteSuccess'));
        queryClient.invalidateQueries([CACHE_KEY_LIST_CLIENTS, parentId]);
        queryClient.invalidateQueries([CACHE_KEY_LIST_CLIENTS_TREE, parentId]);
        queryClient.invalidateQueries(CACHE_KEY_LIST_CLIENTS_TREE);
        queryClient.invalidateQueries(CACHE_KEY_LIST_CLIENT_CHILD_STRUCTURES);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    }
  );
  return {
    remove,
    isLoading
  };
};

export const useDuplicateStructure = () => {
  const {t} = useTranslation('common');
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: duplicate, isLoading: isDuplicating} = useMutation(ClientsApiService.duplicate,
    {
      onSuccess: () => {
        message.success(t('duplicateSuccess'));
        queryClient.invalidateQueries(CACHE_KEY_LIST_CLIENTS_TREE);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
  return {duplicate, isDuplicating};
};
