/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/9/21
 */
import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import NodeTreeIcon from 'remixicon-react/NodeTreeIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import FunctionLineIcon from 'remixicon-react/FunctionLineIcon';
import {useRenderViewType} from '@/hooks/useRenderViewType';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {getActiveColor} from '@/utils/utils';
import styles from './index.less';

const ButtonGroup = ({isByRoute = false, isByContext = false, actions = [],...props}) => {

  const {pathname} = useLocation();
  const {push} = useHistory();
  const {view: viewByParams} = useParams();

  const {view, setView} = useRenderViewType();

  const mappedActions = useMemo(() => {
    return actions?.map(item => ({label: item?.icon, value: item?.link, ...item}));
  }, [actions]);

  const onChange = useCallback((evt) => {
    setView(evt.target.value);
  }, [setView]);

  const goTo = useCallback((evt) => {
    push(viewByParams ? `${pathname?.replace(viewByParams, evt.target.value)}` : `${pathname}/${evt.target.value}`);
  }, [pathname, push, viewByParams]);

  const options = useMemo(() => {
    return [
      {
        label: <NodeTreeIcon size="1.1rem" color={getActiveColor(view, 'tree')}/>, value: 'tree',
      },
      {
        label: <FileListLineIcon size="1.1rem" color={getActiveColor(view, 'flat')} />, value: 'flat',
      },
      {
        label: <FunctionLineIcon size="1.1rem" color={getActiveColor(view, 'directory')} />, value: 'directory'
      }
    ];
  }, [view]);

  if (isByRoute)
    return (
      <div className={styles.btnGroup}>
        <Radio.Group options={mappedActions} onChange={goTo} value={view} optionType="button" {...props}  className="w-full" />
      </div>
    );

  if (isByContext)
    return (
      <div className={styles.btnGroup}>
        <Radio.Group options={options} onChange={onChange} value={view} optionType="button" {...props} />
      </div>
    );

  return null;
};

ButtonGroup.propTypes = {
  isByRoute: PropTypes.bool,
  isByContext: PropTypes.bool,
  actions: PropTypes.array,
};

export default memo(ButtonGroup);
