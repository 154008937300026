import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Upload} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import useUploadImage from '@/components/UploadImage/useUploadImage';
import {useTranslation} from 'react-i18next';
import style from './index.less';
import classNames from 'classnames';
import InboxUnarchiveFillIcon from 'remixicon-react/InboxUnarchiveFillIcon';

const UploadImage = ({onChange, value, disabled, btnProps, btnText, className, size, shape = 'circle'}) => {
  const {t} = useTranslation('common');
  const {props, handleChange, imageUrl, loading} = useUploadImage(value, onChange);
  return (
    <ImgCrop rotate>
      <Upload
        disabled={disabled}
        name="avatar"
        listType="picture-card"
        className={classNames(style.avatarUploader, className)}
        showUploadList={false}
        {...props}
        onChange={handleChange}>
        <Avatar src={imageUrl} icon={shape === 'circle' ? <UserOutlined/> : 'B'} size={size || 90} shape={shape} />
        <Button
          type="default"
          className={style.buttonUploader}
          loading={loading}
          icon={<InboxUnarchiveFillIcon size="1rem" className="mr-2"/>}
          {...btnProps}
        >
          {loading ? t('loading') : t(btnText)}
        </Button>
      </Upload>
    </ImgCrop>
  );
};

export default memo(UploadImage);

UploadImage.defaultProps = {
  value: '',
  btnProps: {},
  btnText: 'explore',
};

UploadImage.propTypes = {
  button: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  btnProps: PropTypes.object,
  btnText: PropTypes.string,
  size: PropTypes.number,
  shape: PropTypes.string,
};

