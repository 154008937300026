/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 10/6/22
 */
import React, {memo} from 'react';
import { Skeleton, Card } from 'antd';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import styles from './index.less';

const HeaderSkeleton = () => {
  return (
    <div className="md:px-48 w-full h-full wrapper-run-audit-content my-4">
      <div className={classNames('md:px-48 md:ml-10', styles.sectionsDrawerContent)} style={{ background: 'transparent' }}>
        <Scrollbars
          style={{ height: 'calc(100vh - 145px)' }}
          renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
          disableHorizontalScrolling
        >
          <div className="mr-4 sm-mr-2">
            <div className="w-full mb-4">
              <Skeleton.Button className="w-full" active size={60} shape="square" block={false} />
            </div>

            <div className={classNames(styles.siteDrawerRenderInCurrentWrapper,'flex flex-row w-full')}>
              <Card className="w-full">
                <Skeleton />
              </Card>
            </div>
          </div>

          <div className="mr-4 sm-mr-2">
            <div className="w-full my-4">
              <Skeleton.Button className="w-full" active size={60} shape="square" block={false} />
            </div>

            <div className={classNames(styles.siteDrawerRenderInCurrentWrapper,'flex flex-row w-full')}>
              <Card className="w-full">
                <Skeleton />
              </Card>
            </div>
          </div>

          <div className="mr-4 sm-mr-2">
            <div className="w-full my-4">
              <Skeleton.Button className="w-full" active size={60} shape="square" block={false} />
            </div>

            <div className={classNames(styles.siteDrawerRenderInCurrentWrapper,'flex flex-row w-full')}>
              <Card className="w-full">
                <Skeleton />
              </Card>
            </div>
          </div>

        </Scrollbars>
      </div>
    </div>
  );
};

HeaderSkeleton.propTypes = {};

export default memo(HeaderSkeleton);
