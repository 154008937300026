/**
 * @author: Angel Labrada
 * @date: 9/7/22
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesAreaTypeApiService from '@/modules/settings/services/NomenclaturesAreaTypeApiService';

const SelectAreaAccess = ({value, onChange, ...props}) => {

  return (
    <SelectElementCmp
      queryKey={'register-facility-select'}
      service={NomenclaturesAreaTypeApiService}
      includeValue={''}
      value={typeof value === 'object' ? value?._id : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      {...props}
      filters={null}
    />
  );
};

SelectAreaAccess.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  setRegisterType: PropTypes.func,
  form: PropTypes.object,
};


export default memo(SelectAreaAccess);

const AreaAccessView = ({value}) => {

  return (
    <div>
      {value?.name || 'N/A'}
    </div>
  );
};

AreaAccessView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table
 * */
export const renderAreaAccess = (text) => {
  return (
    <AreaAccessView value={text}/>
  );
};
