/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 20/12/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
// import UsersApiService from '@/modules/users/services/UsersApiService';
import {OperatorFilter, TermFilter} from '@dofleini/query-builder';
import {useGetOne} from '@/modules/users/hooks/useUsers';
import EmployeesApiService from '@/modules/employees/services/EmployeesApiService';

const AuditsSelectAuditor = ({value, onChange, isViewMode, ...props}) => {
  const {data} = useGetOne(value);

  const filters = new OperatorFilter({
    type: 'AND', filters: [
      new TermFilter({field: 'isRoot', value: true}),
      new TermFilter({field: 'verified', value: true}),
    ]
  });

  if (isViewMode) {
    return (
      <>
        {data?.name || 'N/A'}
      </>
    );
  }

  return (
    <SelectElementCmp
      queryKey={'audit-auditor'}
      filters={filters}
      service={EmployeesApiService}
      customMethod="/ms-core/api/workers/search-users"
      includeValue={''}
      value={typeof value === 'object' ? value?.fullName : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      nameToShow="fullName"
      {...props}
    />
  );
};

AuditsSelectAuditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  isViewMode: PropTypes.func,
};

export default memo(AuditsSelectAuditor);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {object} entity this is entity type
 * */
export const renderService = (text, entity) => {
  return (
    <AuditsSelectAuditor value={text} entity={entity} isViewMode={true}/>
  );
};

