import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import SelectElementCmp from '@/utils/SelectElementCmp';
import GeoApiService from '@/modules/employees/services/GeoApiService';
import { FilterFactory } from '@dofleini/query-builder';
import {useTranslation} from 'react-i18next';
import {useGetOneCity} from '@/hooks/useGeoPlaces';

const SelectCity = ({ value, onChange, filter, stateId, translation, ...props }) => {
  const {t} = useTranslation(translation);

  const handleChange = useCallback((val) => {
    onChange(val);
  }, [onChange]);

  const filters = FilterFactory.add({}, filter);

  if (!stateId) {
    return (
      <Select disabled={!stateId} placeholder={t('form.placeholderEmployeeMunicipality')} className="custom-select"/>
    );
  }

  return (
    <SelectElementCmp
      queryKey={'geo-places-cities'}
      filters={filters.toQuery()}
      service={GeoApiService}
      customMethod={`/ms-core/api/cities/${stateId}/search`}
      additionalParams={filters.toQuery()}
      includeValue={''}
      value={typeof value === 'object' ? value?.name : value}
      onChange={handleChange}
      sort={{ code: 1 }}
      customMapItem={(v) => ({...v, name: v.name })}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      {...props}
    />
  );
};

SelectCity.propTypes = {
  value: PropTypes.string || PropTypes.object,
  onChange: PropTypes.func,
  setStateId: PropTypes.func,
  filter: PropTypes.object,
  stateId: PropTypes.string,
  translation: PropTypes.string,
};
SelectCity.defaultProps = {
  entity: {},
};

export default memo(SelectCity);

const CityView = ({value}) => {

  const {data} = useGetOneCity(value);

  return (
    <div>
      {data?.name || 'N/A'}
    </div>
  );
};

CityView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderCity = (text) => {
  return (
    <CityView value={text}/>
  );
};
