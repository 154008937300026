/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 25/5/22
 */
import i18n from 'i18next';
import 'moment/min/locales';
import {ValidatorProvider} from '@dofleini/validator';
import defaultsDeep from 'lodash/defaultsDeep';

import * as EN from '../locales/en';
import * as ES from '../locales/es';

// the translations
const resources = {
  es: {},
  en: {}
};

Object.keys(EN).forEach(name => {
  resources.en[name] = name === 'common' ? EN[name] : defaultsDeep(EN[name], EN.common);
});
Object.keys(ES).forEach(name => {
  resources.es[name] = name === 'common' ? ES[name] : defaultsDeep(ES[name], ES.common);
});

let lng = localStorage.getItem('i18nextLngSinglePage');
if (!lng) {
  lng = 'es';
  localStorage.setItem('i18nextLngSinglePage', lng);
}

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  debug: process.env.NODE_ENV !== 'production',
  lng,
  resources,

  fallbackLng: lng,

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChangedForSinglePage loaded',
    bindStore: 'added removed',
    nsMode: 'common.json'
  },
};

i18n.on('languageChangedForSinglePage', function (lng) {
  localStorage.setItem('i18nextLngSinglePage', lng);
  i18n.__currentLng__ = lng;
  if (i18n.languageChangedForSinglePage)
    i18n.languageChanged(lng);
});


i18n.subscribe = (callback) => {
  i18n.languageChangedForSinglePage = callback;
  callback(i18n.__currentLng__);
};

i18n.init(options);

ValidatorProvider.In18 = i18n;

export {i18n as i18nSinglePage};

