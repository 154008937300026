/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import TemplateDetailAuditor from '@/modules/templates/components/Audits/TemplateDetailsAuditor';
import TemplateDetailsAuditionDate from '@/modules/templates/components/Audits/TemplateDetailsAuditionDate';
import TemplateDetailsLocation from '@/modules/templates/components/Audits/TemplateDetailsLocation';
import TemplateDetailsNumber from '@/modules/templates/components/Audits/TemplateDetailsNumber';
import TemplateDetailsSelect from '@/modules/templates/components/Audits/TemplateDetailsSelect';
import TemplateDetailsText from '@/modules/templates/components/Audits/TemplateDetailsText';
import TemplateDetailsInSituMeasure from '@/modules/templates/components/Audits/TemplateDetailsInSituMeasure';
import TemplateDetailObjective from '@/modules/templates/components/Audits/TemplateDetailsObjective';
import TemplateDetailSummary from '@/modules/templates/components/Audits/TemplateDetailsSummary';
import TemplateDetailEvolution from '@/modules/templates/components/Audits/TemplateDetailsEvolution';
import TemplateDetailsAuditDateRange from '@/modules/templates/components/Audits/TemplateDetailsAuditDateRange';

const WrapperFieldType = ({type, question, sectionId, isCover}) => {
  const render = useMemo(() => {
    switch (type) {
      case 'audit_date':
        return <TemplateDetailsAuditDateRange question={question} isCover={isCover} />;
      case 'date':
        return <TemplateDetailsAuditionDate question={question} isCover={isCover} />;
      case 'location':
        return <TemplateDetailsLocation question={question} isCover={isCover} />;
      case 'number':
        return <TemplateDetailsNumber question={question} isCover={isCover} type={type}/>;
      case 'select':
        return <TemplateDetailsSelect question={question} isCover={isCover} />;
      case 'auditor':
        return <TemplateDetailAuditor question={question} isCover={isCover} />;
      case 'objective':
        return <TemplateDetailObjective question={question} isCover={isCover} />;
      case 'evolution':
        return <TemplateDetailEvolution question={question} isCover={isCover} />;
      case 'summary':
        return <TemplateDetailSummary question={question} isCover={isCover} />;
      case 'readingRegistry':
        return <TemplateDetailsInSituMeasure question={question} sectionId={sectionId} />;
      default:
        return <TemplateDetailsText question={question} isCover={isCover} />;
    }
  }, [isCover, question, sectionId, type]);

  return (
    <div className="py-2">{render}</div>
  );
};

WrapperFieldType.propTypes = {
  type: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
  sectionId: PropTypes.string,
};

export default memo(WrapperFieldType);
