/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import {useTranslation} from 'react-i18next';
import ClientsStatusCell from '@/modules/clients/components/ClientsStatusCell';

const ClientStatus = ({value, onChange}) => {
  const {t} = useTranslation('clients');

  return (
    <Radio.Group onChange={onChange} value={value}>
      <Radio value={true}>{t('form.active')}</Radio>
      <Radio value={false}>{t('form.inactive')}</Radio>
    </Radio.Group>
  );
};

ClientStatus.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func
};

export default memo(ClientStatus);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {object} entity this is entity type
 * */
export const renderCustomStatus= (text, entity) => {
  return (
    <ClientsStatusCell status={text} entity={entity} isViewMode={true}/>
  );
};
