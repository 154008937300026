export const CACHE_KEY_LIST_TEMPLATE_TASKS = 'templates-tasks';
export const CACHE_KEY_TEMPLATE_TASKS = 'templates-tasks-one';
export const ROUTE_PATH_TEMPLATE_TASKS = '/template-issues/list/template-tasks';
export const TRANSLATION_PREFIX_TEMPLATE_TASKS = 'tasks';

export const STATUS_TASK = {
  toDo: 'toDo',
  complete: 'complete',
};

export  const STATUS_TASK_APPEARANCE = {
  toDo: {color: '#389e0d'},
  complete: {color: '#096dd9'},
};
