import React, {createContext, useContext, useCallback, useState} from 'react';

const ExpandableRowsContext = createContext({
  defaultExpandedRowKeys: [],
  onExpandedRowsChange: val => val,
});

const useExpandableRows = () => {
  const context = useContext(ExpandableRowsContext);
  if (context === undefined) {
    throw new Error('useExpandableRows must be used within a ExpandableRowsProvider');
  }

  return context;
};

const ExpandableRowsProvider = ({ ...props }) => {
  const [defaultExpandedRowKeys, setSelectedRows] = useState([]);

  /** Select row(s) */
  const onExpandedRowsChange = useCallback((rows) => {
    setSelectedRows(rows);
  }, []);

  return (
    <ExpandableRowsContext.Provider
      value={{
        defaultExpandedRowKeys,
        onExpandedRowsChange,
      }}
      {...props}
    />
  );
};

export { useExpandableRows, ExpandableRowsProvider };
