import { Form } from 'antd';
import map from 'lodash/fp/map';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import React from 'react';

const getPropsByRelated = (values, relatedValue, props, cb) => {
  if (isFunction(cb)) {
    const _props = cb(relatedValue, props, values);
    return isObject(_props) ? _props : props;
  }
  return props;
};

export const createRelatedField = (config = {}) => {
  const {
    related,
    relatedKey = 'related',
    widget: WidgetComponent
  } = config;

  // eslint-disable-next-line react/display-name
  return (args = {}) => {
    const { formItemProps, field, disabled } = args;
    const {
      propsByRelated,
      required,
      widgetProps = {}
    } = field;

    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          const values = getFieldsValue(true);
          const relatedValue = get(values, related);
          const baseProps = getPropsByRelated(
            values,
            relatedValue,
            formItemProps,
            propsByRelated
          );

          const _props = {
            required,
            disabled
          };

          const _widgetProps = {
            ..._props,
            ...widgetProps,
            ...(baseProps?.widgetProps || {}),
            [relatedKey]: relatedValue
          };

          return (
            <Form.Item {...baseProps} {..._props}>
              <WidgetComponent {..._widgetProps}/>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  };
};

export const mapRelatedFields = map(({ related, relatedKey, ...rest }) => related ? ({
  ...rest,
  render: createRelatedField({ related, relatedKey, widget: rest?.widget })
}) : rest);
