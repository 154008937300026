import {useMemo, useCallback} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {createGetOneHook} from '@/utils/createGetOneHook';
import AuditsApiService from '@/modules/audits/services/AuditsApiService';
import ReportsApiService from '@/services/ReportsApiService';
import {CACHE_KEY_LIST_AUDITS, CACHE_KEY_AUDITS, CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS} from '@/modules/audits/contants/audits';
import {fields} from '@/modules/audits/contants/audits.form';
import {createWizardFieldsHook} from '@/utils/createWizardFieldsHook';
import useAlerts from '@/hooks/useAlerts';
import moment from 'moment';

export const useGetOneAudits = createGetOneHook(AuditsApiService, 'id', CACHE_KEY_AUDITS);
export const useFields = createWizardFieldsHook('audits', fields);

export const useGetAllSections = (auditId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(auditId)};
  }, [auditId]);

  return useQuery([CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS, auditId], async () => {
    const {data} = await AuditsApiService.getAllSections({auditId});
    return data;
  }, queryConfig);
};

export const useReopen = () => {
  const queryClient = useQueryClient();
  const { putErrorCode } = useAlerts();

  const {mutateAsync: onReopen, isLoading} = useMutation(AuditsApiService.reOpen,
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_AUDITS);
        queryClient.invalidateQueries([CACHE_KEY_AUDITS, data?._id]);
        queryClient.invalidateQueries(data?._id);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });

  return {onReopen, isLoading};
};

export const useDownloadPDF = () => {
  const { putErrorCode } = useAlerts();
  const {mutateAsync: download, isLoading} = useMutation(ReportsApiService.downloadPDFAudit);

  const onDownload = useCallback(async (audit) => {
    try {
      await download({
        _id: audit?._id,
        pdfName: `${audit?.client?.name}-${moment().format('DD-MM-YYYY').toString()}.pdf`
      });
    } catch (e) {
      putErrorCode(e);
    }
  }, [download, putErrorCode]);
  return {onDownload, isLoading};
};
