import {useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import NomenclaturesCompaniesApiService from '@/modules/settings/services/NomenclaturesCompaniesApiService';
import {CACHE_KEY_NOMENCLATURES_COMPANIES} from '@/modules/settings/constants/nomenclaturesCompanies';
import {fields} from '@/modules/settings/constants/nomenclaturesCompanies.form';
import {useQuery} from 'react-query';

export const useGetOneNomenclaturesCompanies = createGetOneHook(NomenclaturesCompaniesApiService, 'id', CACHE_KEY_NOMENCLATURES_COMPANIES);
export const useFields = createFieldsHook('nomenclaturesCompanies', fields);

export const useGetOneCompany = (companyId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(companyId)};
  }, [companyId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_NOMENCLATURES_COMPANIES, companyId], async () => {
    const {data} = await NomenclaturesCompaniesApiService.getOneCompany({companyId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};
