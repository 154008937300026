import React, { memo, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Popover, Tag } from 'antd';
import classNames from 'classnames';
import style from './index.less';
import {Scrollbars} from 'react-custom-scrollbars';

const mapFunc = (item, key, renderField, conditionalColor, tag, primary, customClass, isOnPopover) => {
  const text = renderField ? get(item, renderField) : item;
  const color = conditionalColor ? conditionalColor(item) : undefined;
  if (tag)
    return text ? (<Tag
      color={color}
      key={key}
      style={{ minHeight: 30 }}
      className={classNames('flex items-center justify-start font-medium px-2 mb-1 bg-white truncate',
        { 'bg-primary text-white': primary }, { 'w-full': isOnPopover }, customClass)}>
      {text}
    </Tag>) : '';
  return <div key={key}
    className={classNames('mr-2 truncate',
      { 'text-primary': primary }, { 'w-full': isOnPopover }, customClass)}>{text}</div>;
};

const TagList = ({
  value, renderField, tag, limit, conditionalColor, primary, customClass, containerCustomClass,
  customMapFunc, notUsePopupContainer = false, disableHorizontalScrolling = true
}) => {
  const ref = useRef();
  const toShow = useMemo(() => limit ? (value ? [...value]?.splice(0, limit) : []) : value, [value, limit]);
  const restToShow = useMemo(() => limit ? (value ? [...value]?.splice(limit, value?.length || 0) : []) : [], [value, limit]);

  const getPopupContainer = useCallback(() => {
    return ref.current;
  }, []);

  if (!value) return '';

  const tags = toShow?.map(customMapFunc)?.map((item, key) => mapFunc(item, key, renderField, conditionalColor, tag, primary, customClass));
  const tagsOther = restToShow?.map(customMapFunc)?.map((item, key) => mapFunc(item, key, renderField, conditionalColor, tag, primary, customClass, true));

  return <div ref={ref} id={'tag-list-elements'}
    className={classNames('flex items-center', style.restPopoverClass, containerCustomClass)}>
    {tags}
    {!!restToShow.length &&
    <Popover
      className={'cursor-pointer'}
      overlayClassName="global-custom-popover"
      placement="bottomRight"
      trigger="hover"
      {...({getPopupContainer: notUsePopupContainer ? null : getPopupContainer})}
      content={<div className="margin-auto p-2 popover-content">
        <Scrollbars
          id="popover-content-scroll-bars"
          // This will activate auto hide
          autoHide={false}
          autoHeight
          renderTrackHorizontal={({style, ...props}) =>
            <div {...props} style={{...style, display: disableHorizontalScrolling ? 'none' : 'inherit' }}/>
          }
          style={{maxHeight: 320, width: 320, minHeight: '100%' }}
        >
          {tagsOther}
        </Scrollbars>
      </div>}>
      <Tag
        key={'more-tags'}
        className={classNames('font-medium px-2 py-1 bg-white mb-1', { 'bg-primary text-white': primary }, customClass)}
        closable={false}
      >
        +{restToShow.length}
      </Tag>
    </Popover>}
  </div>;
};

export default memo(TagList);

TagList.propTypes = {
  conditionalColor: PropTypes.func,
  value: PropTypes.array,
  primary: PropTypes.bool,
  tag: PropTypes.bool,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  renderField: PropTypes.string,
  containerCustomClass: PropTypes.string,
  customMapFunc: PropTypes.func,
  customClass: PropTypes.string,
  notUsePopupContainer: PropTypes.bool,
  disableHorizontalScrolling: PropTypes.bool,
};

TagList.defaultProps = {
  customMapFunc: (v) => v,
  value: [],
};
