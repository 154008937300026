export const staticRoles = [
  'ADMIN',
  'USER',
  'WORKER',
  'ROLE',
  'ORG',
  'FAQ',
  'TRACE',
  'NOTIFICATION',
  'PROFILE-WORKER',
  'CLIENTS',
  'TEMPLATES',
  'AUDIT-TEMPLATE',
  'CATEGORY',
  'CONTRACT',
  'ENTERPRISE',
  'BILLING-METHOD',
  'SERVICES',
  'ACTIVITY',
  'ESTABLISHMENT',
  'PAYMENT_TYPE',
  'SOCIAL_REASON',
  'SET',
  'AREA',
  'STRUCTURE',
  'SECURITY',
  'QUESTION',
  'SELECT_CHOICE',
  'TYPE_AREA',
  'SUPPORT_MESSAGES',
  'SUPER_ADMIN'
];
