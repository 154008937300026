import {EntityApiService, ApiClientService} from '@dofleini/security';

class ReportsApiService extends EntityApiService {
  downloadPDFAudit = async (params) => {
    ApiClientService.pushDefaultHeader('Accept', 'application/pdf');
    const response = await ApiClientService.get(this.getPath(`/audit/${params._id}`), { responseType: 'arraybuffer' });
    const type = response.headers['content-type'];
    // eslint-disable-next-line no-undef
    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${params.pdfName}.pdf`;
    link.click();
  };

}

export default new ReportsApiService('/ms-core/api/reports');
