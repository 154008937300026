/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card} from 'antd';
import ViewEditor from '@/components/Editor/viewEditor';
import Incidents from '../../Incidents';

const Objective = ({question, entity}) => {

  const data = useMemo(() => entity?.coverFields?.find(f => f?._id === question?._id), [entity?.coverFields, question?._id]);

  return (
    <Card
      className={styles.templateTextCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="mb-3">
        {data?.label || question?.name || question?.label}
      </div>
      <div className="custom-form-big">
        {!data?.value ? 'N/A' : <ViewEditor value={data?.value} />}
      </div>
      {question?.issuesAudit?.length > 0 && <Incidents data={question?.issuesAudit}/>}
    </Card>
  );
};

Objective.propTypes = {
  question: PropTypes.object,
  entity: PropTypes.object,
};

Objective.defaultProps = {
  question: {},
};

export default memo(Objective);
