import React, {memo} from 'react';
import {Button, Layout} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './index.less';
import UserOptions from '@/components/UserOptions';
import {application} from '@/settings';


const popupProps = {
  getPopupContainer: () => document.getElementById('app-top-header'),
  placement: 'bottomRight',
  align: {
    offset: [-2, -2]
  }
};

const ResponsiveHeader = ({className, toggleSider}) => {
  return (
    <>
      <Layout.Header id="app-top-header" className={classNames('fixed w-full flex-row flex', className, style.header)}>
        <div className={'flex-grow flex items-center'}>
          <Button type={'text'} size={'large'}
            className={classNames('text-white', style.menuButton)}
            onClick={toggleSider}>
            <MenuOutlined/>
          </Button>

          <h1 className={classNames(style.name)}>{application.name}</h1>
        </div>
        <div className={style.userOptions}>
          <UserOptions mode={'horizontal'}
            collapsed
            popupProps={popupProps}
            className={classNames('flex flex-row', style.userOptionToolBar)}/>
        </div>
      </Layout.Header>
    </>
  );

};

export default memo(ResponsiveHeader);

ResponsiveHeader.propTypes = {
  className: PropTypes.string,
  toggleSider: PropTypes.func
};
