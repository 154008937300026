import React, { memo, useMemo } from 'react';
import FormBuilder from 'antd-form-builder';
import PropTypes from 'prop-types';
import { useResponsive } from '@/contexts/ResponsiveContext';

export const EntityDetailContent = ({ data, fields, viewMode, form, columns }) => {
  const { isMobile } = useResponsive();

  const meta = useMemo(() => ({
    initialValues: data,
    columns: isMobile ? 1 : columns,
    formItemLayout: null, // Must set this for inline layout
    fields
  }), [columns, data, fields, isMobile]);

  return (
    <FormBuilder form={form} meta={meta} viewMode={viewMode}/>
  );

};

export default memo(EntityDetailContent);

EntityDetailContent.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.number,
  fields: PropTypes.array,
  form: PropTypes.object,
  viewMode: PropTypes.bool
};

EntityDetailContent.defaultProps = {
  columns: 2
};
