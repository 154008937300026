/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'antd';
import SectionTitle from '../SectionTitle';
import WrapperFieldType from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType';
import {useTranslation} from 'react-i18next';
import styles from './index.less';
import {useAuditData} from '@/modules/audits/hooks/useAuditData';
import HeaderCover from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/HeaderCover';

const { Panel } = Collapse;

const SectionBox = ({section, id, isCover, countData}) => {
  const {t} = useTranslation('templates');
  const [activeKey, setActiveKey] = useState(['1']);
  const {currentAudit} = useAuditData();

  if (isCover) {
    return (
      <div id={id} className="wrapper-section-panel">
        <Collapse defaultActiveKey={activeKey} bordered={false} onChange={setActiveKey} className={classNames(styles.wrapperPanel)}>
          <Panel className="wrapper-panel-audit" header={<SectionTitle status={currentAudit?.status} section={{title: 'Introduction'}} activeKey={activeKey} isCover={isCover} />} key="1" showArrow={false}>
            <HeaderCover countData={countData}/>
            {currentAudit?.coverFields?.length === 0
              ?
              <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
              : currentAudit?.coverFields?.map(item => {
                return <WrapperFieldType key={item?._id} type={item?.type} question={item} data={currentAudit} isCover section={section} />;
              })}
          </Panel>
        </Collapse>
      </div>
    );
  }

  return (<div id={id} className="wrapper-section-panel">
    <Collapse defaultActiveKey={activeKey} bordered={false} onChange={setActiveKey} className={classNames(styles.wrapperPanel)}>
      <Panel className="wrapper-panel-audit" header={<SectionTitle section={section} activeKey={activeKey} />} key="1" showArrow={false}>
        {section?.questions?.length === 0
          ? <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
          : section?.questions?.map(item => {
            return <WrapperFieldType key={item?._id} type={item?.type} question={item} sectionId={section?._id}/>;
          })}
      </Panel>
    </Collapse>
  </div>
  );
};

SectionBox.propTypes = {
  section: PropTypes.object,
  id: PropTypes.string,
  isCover: PropTypes.bool,
  countData: PropTypes.object,
};

export default memo(SectionBox);
