import compose from 'lodash/fp/compose';
import filterFP from 'lodash/fp/filter';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {mapRelatedFields} from '@/utils/createRelatedField';
import mapFieldItems from '@/utils/mapFieldItems';

export const createFieldsHook = (translation, fields) => {
  return (isViewMode, form, columns) => {
    const {t} = useTranslation(translation);

    return useMemo(
      () => compose(
        mapRelatedFields,
        mapFieldItems(t, null, columns),
        filterFP(({onlyView, onlyEdit}) => {
          if (onlyView) return isViewMode;
          if (onlyEdit) return !isViewMode;
          return true;
        }),
      )(fields), [isViewMode, t, columns]);
  };
};

