import React, { createContext, useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import PageHeader from '@/components/PageHeader';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useResponsive } from '@/contexts/ResponsiveContext';

const PageContentContext = createContext();

function PageContent ({ children, showBreadcrumbs = true }) {
  const [title, setTitle] = useState();
  const { isMobile } = useResponsive();
  const [breadcrumbCustomMap, setBreadcrumbCustomMap] = useState({});

  return (
    <PageContentContext.Provider value={{
      setBreadcrumbCustomMap,
      setTitle
    }}>
      <Layout>
        <Layout.Content className={'m-1 lg:m-5'}>
          {showBreadcrumbs && <PageHeader title={title} className={classNames('mb-3 lg:mb-5', {'truncate': isMobile})}
            customMap={breadcrumbCustomMap}/>}
          {children}
        </Layout.Content>
      </Layout>
    </PageContentContext.Provider>
  );
}

function usePageContent (options = {}) {
  const context = useContext(PageContentContext);
  if (context === undefined) {
    throw new Error('usePageContent must be used within a PageContentProvider');
  }
  const { title } = options;
  const {
    setTitle, setBreadcrumbCustomMap
  } = context;

  useEffect(() => {
    if (title)
      setTitle(title);
    return () => setTitle('');
  }, [setTitle, title]);

  return {
    setTitle,
    setBreadcrumbCustomMap
  };
}

PageContent.propTypes = {
  children: PropTypes.any,
  showBreadcrumbs: PropTypes.bool
};

export { PageContent, usePageContent };
