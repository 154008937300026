import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {useQuery} from 'react-query';

export const USER_PROFILE = '__user_profile__';
const getUser = () => {
  const user = localStorage.getItem(USER_PROFILE);
  if (user)
    try {
      return JSON.parse(user);
    } catch (e) {
      //ignoring
    }
};
export const useMe = () => {
  const path = `${authentication.path}/api/users/me`;


  const user = getUser();

  const {isLoading, error, data, refetch, isStale, failureCount} = useQuery('user-me', async () => {
    if (!ApiClientService.getToken()) return Promise.reject({authenticatedError: true});
    const {data} = await ApiClientService.get(path);
    localStorage.setItem(USER_PROFILE, JSON.stringify(data));
    return (data);

  }, {
    retry: 0, // Will retry failed requests 10 times before displaying an error
    refetchOnWindowFocus: false
  });


  const temUser = error || failureCount > 0 ? undefined : user;
  return {
    data: isLoading ? data || temUser : data,
    isLoading, error, refetch, isStale
  };
};

