/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {useTranslation} from 'react-i18next';
import { Card, Form, Input/*, Button, Divider*/ } from 'antd';
// import { EditOutlined, FlagOutlined, CheckSquareOutlined } from '@ant-design/icons';

const TemplateDetailAuditor = () => {
  const {t} = useTranslation('templates');

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <Form layout="vertical" className="custom-form-big">
        <Form.Item label={t('details.nameAuditor')} name="nameAuditor">
          <Input placeholder={t('details.nameAuditorPlaceholder')}/>
        </Form.Item>
      </Form>
      {/*<div className={'row-flex justify-between'}>*/}
      {/*  <Button type="text" icon={<EditOutlined />} >{t('details.newNote')}</Button>*/}
      {/*  <Divider className={styles.templateDetailCardDivider} type="vertical" />*/}
      {/*  <Button type="text" icon={<FlagOutlined />}>{t('details.incidence')}</Button>*/}
      {/*  <Divider className={styles.templateDetailCardDivider} type="vertical" />*/}
      {/*  <Button type="text" icon={<CheckSquareOutlined />}>{t('details.task')}</Button>*/}
      {/*</div>*/}
    </Card>
  );
};

TemplateDetailAuditor.propTypes = {
  entity: PropTypes.object,
};
TemplateDetailAuditor.defaultProps = {
  entity: {},
};

export default memo(TemplateDetailAuditor);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailAuditor = (value, entity) => {
  return (
    <TemplateDetailAuditor entity={entity}/>
  );
};
