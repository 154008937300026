/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/9/21
 */
import React, {createContext, useContext, useState} from 'react';

const RenderViewTypeContext = createContext({
  view: '', // flat | tree | directory,
  setView: val => val,
});

const useRenderViewType = () => {
  const context = useContext(RenderViewTypeContext);
  if (context === undefined) {
    throw new Error('useRenderViewType must be used within a RenderViewTypeProvider');
  }

  return context;
};

const RenderViewTypeProvider = ({ ...props }) => {
  const [view, setView] = useState('');

  return (
    <RenderViewTypeContext.Provider
      value={{view, setView}}
      {...props}
    />
  );
};

export { useRenderViewType, RenderViewTypeProvider };

