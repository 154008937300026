export const data = [
  {
    '_id' : '614c976b85c7ff32c823ca7a',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 2,
    'establishmentCount' : 2,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Meliá Hotels & Resorts Europa',
    'code' : 'STR-1',
    'description' : 'Descripcion 1',
    'cif' : '5854744',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Plaza',
    'address' : 'Linea e/ 10 y 12',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:04:11.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:04:11.816Z',
    'updatedAt' : '2021-09-23T15:04:11.816Z',
    'establishments': [],
    '__v' : 0
  },
  {
    '_id' : '614ca08930db97369f37a865',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Kunde LLC',
    'code' : 'ID-6036',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent' : '614c976b85c7ff32c823ca7a',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : false,
    'inactivityDate' : '2021-09-23T15:43:05.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:43:05.957Z',
    'updatedAt' : '2021-09-23T15:43:05.957Z',
    'establishments': [
      {
        'deleted': false,
        'deletedAt': null,
        'secureSpacePath': [
          '6148f40f6afa9a63cd46efbd'
        ],
        'subAreaCount': 0,
        'phones': [
          '78478547'
        ],
        'emails': [
          'email1@yopamil.com'
        ],
        '_id': '614ca65814864e3e481b78c8',
        'name': 'Greenfelder, Leannon and Schulist',
        'code': 'ID-6036',
        'structure': '614c976b85c7ff32c823ca7a',
        'description': '',
        'country': 'Cuba',
        'province': 'La Habana',
        'municipality': 'Cerro',
        'address': 'Calzada del Cerro',
        'zipCode': '10200',
        'socialReason': '614a2362e024f14bac424a96',
        'openMonth': 1,
        'closeMonth': 12,
        'owner': '6148f40f6afa9a63cd46efc3',
        'space': '6148f40f6afa9a63cd46efbd',
        'createdAt': '2021-09-23T16:07:52.806Z',
        'updatedAt': '2021-09-23T16:07:52.806Z',
        '__v': 0,
        'id': '614ca65814864e3e481b78c8'
      },
      {
        'deleted': false,
        'deletedAt': null,
        'secureSpacePath': [
          '6148f40f6afa9a63cd46efbd'
        ],
        'subAreaCount': 0,
        'phones': [
          '78478547'
        ],
        'emails': [
          'email1@yopamil.com'
        ],
        '_id': '614ca6a35b83c63e862ad31c',
        'name': 'DuBuque, Renner and Fisher',
        'code': 'ID-8794',
        'structure': '614c976b85c7ff32c823ca7a',
        'description': '',
        'country': 'Cuba',
        'province': 'La Habana',
        'municipality': 'Cerro',
        'address': 'Calzada del Cerro',
        'zipCode': '10200',
        'socialReason': '614a2362e024f14bac424a96',
        'openMonth': 1,
        'closeMonth': 12,
        'owner': '6148f40f6afa9a63cd46efc3',
        'space': '6148f40f6afa9a63cd46efbd',
        'createdAt': '2021-09-23T16:09:07.753Z',
        'updatedAt': '2021-09-23T16:09:07.753Z',
        '__v': 0,
        'id': '614ca6a35b83c63e862ad31c'
      }
    ],
    '__v' : 0
  },
  {
    '_id' : '614ca19a753cab3c2dd773be',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Deckow, Nolan and Berge',
    'code' : 'ID-8794',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent' : '614c976b85c7ff32c823ca7a',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:47:38.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:47:38.665Z',
    'updatedAt' : '2021-09-23T15:47:38.665Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e97105',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e97178',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : false,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e97179',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e971780',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : false,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e971787',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e971788',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e971789',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213c75e971786',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  },
  {
    '_id' : '614ca2165f3d213775e971786',
    type: 'structure',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Jones, Carter and Osinski',
    'code' : 'ID-6032',
    'description' : 'Descripcion 1',
    'cif' : 'asd',
    'icon' : '',
    'country' : 'Cuba',
    'province' : 'La Habana',
    'municipality' : 'Cerro',
    'address' : 'Calzada del Cerro',
    'zipCode' : '10200',
    'fax' : '58475874',
    'parent': null,
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Osmel Mojena'
    },
    'paymentType' : '614a33588ca74a544cf91194',
    'company' : 'Melia',
    'active' : true,
    'inactivityDate' : '2021-09-23T15:49:42.000Z',
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-23T15:49:42.939Z',
    'updatedAt' : '2021-09-23T15:49:42.939Z',
    '__v' : 0
  }
];
