/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Form, Input/*, Button, Divider*/ } from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/Audits/TemplateDetailsFooterActions';
import Editor from '@/components/Editor';

const TemplateDetailAuditor = ({question, isCover}) => {

  return (
    <Card
      className={styles.templateTextCard}
    >
      {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
      {/*  {question?.controlParameter ? '*' : null}*/}
      {/*</div>*/}
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <Form layout="vertical" className="custom-form-big">
        <Form.Item name="nameText">
          {question?.text?.answerType === 'PARAGRAPH' || question?.type === 'paragraph' ?  <Editor/> : <Input className="mb-3" onPressEnter={e => {
            e.preventDefault();
            e.stopPropagation();
          }}/>}
        </Form.Item>
      </Form>

      <TemplateDetailsFooterActions question={question} isCover={isCover} />
    </Card>
  );
};

TemplateDetailAuditor.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailAuditor.defaultProps = {
  question: {},
};

export default memo(TemplateDetailAuditor);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailAuditor = (value, entity) => {
  return (
    <TemplateDetailAuditor entity={entity}/>
  );
};
