import {useMemo} from 'react';
import GeoApiService from '@/modules/employees/services/GeoApiService';
import {useQuery} from 'react-query';

export const useGetOneCountry = (_id) => {

  const queryConfig = useMemo(() => {
    return {enabled: Boolean(_id)};
  }, [_id]);

  return useQuery(['geo-places-one-country', _id], async () => {
    const {data} = await GeoApiService.getOneCountry(_id);
    return data;
  }, queryConfig);
};

export const useGetOneState= (_id) => {

  const queryConfig = useMemo(() => {
    return {enabled: Boolean(_id)};
  }, [_id]);

  return useQuery(['geo-places-one-state', _id], async () => {
    const {data} = await GeoApiService.getOneState(_id);
    return data;
  }, queryConfig);
};

export const useGetOneCity= (_id) => {

  const queryConfig = useMemo(() => {
    return {enabled: Boolean(_id)};
  }, [_id]);

  return useQuery(['geo-places-one-city', _id], async () => {
    const {data} = await GeoApiService.getOneCity(_id);
    return data;
  }, queryConfig);
};
