/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesActivitiesApiService from '@/modules/settings/services/NomenclaturesActivitiesApiService';
import {useGetActivities} from '@/modules/settings/hooks/useNomenclaturesActivities';

const ClientSelectActivities = ({value, filters, onChange, ...props}) => {

  return (
    <SelectElementCmp
      queryKey={'companies'}
      filters={filters}
      service={NomenclaturesActivitiesApiService}
      includeValue={''}
      value={typeof value === 'object' ? value : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select custom-select-multiple"
      canAdd
      mode="multiple"
      {...props}
    />
  );
};

ClientSelectActivities.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  filters: PropTypes.object,
};

export default memo(ClientSelectActivities);

const ClientSelectActivitiesView = ({value}) => {

  const {activities} = useGetActivities(value);
  return (
    <>{activities?.map(v => v?.name)?.join(' / ')}</>
  );
};

ClientSelectActivitiesView.propTypes = {
  value: PropTypes.array,
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {Array} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderCustomActivities= (text) => {
  return (
    <ClientSelectActivitiesView value={text}/>
  );
};
