import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {handleUserResponse} from '@/contexts/SessionControlContext';

export function login(formData) {
  return ApiClientService.post(`${authentication.path}/api/signin`, formData).then(handleUserResponse);
}

export function register({username, password}) {
  return ApiClientService('register', {body: {username, password}}).then(handleUserResponse);
}

export function logout() {
  return new Promise((resolve) => {
    window.sessionStorage.removeItem(ApiClientService.ACCESS_TOKKEN_KEY);
    ApiClientService.setSpace('');
    window.localStorage.clear();
    window.localStorage.removeItem('__user_profile__');
    resolve();
  });
}
