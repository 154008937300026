/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Avatar, Collapse, Card} from 'antd';
import stylesCollapse from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/SectionBox/index.less';
import FlagLineIcon from 'remixicon-react/FlagLineIcon';
import CardTask from '../CardTask';
import CardEvidence from '../CardEvidence';

const {Panel} = Collapse;

const DetailsIssueHeader = ({data, idx}) => {
  const {t} = useTranslation('templates');
  const [activeKey, seteActiveKey] = useState(['1']);

  const isCollapsible = useMemo(() => data?.tasksAudit?.length > 0 || data?.evidences?.length > 0, [data?.evidences?.length, data?.tasksAudit?.length]);

  return (isCollapsible ?
    <Collapse
      activeKey={activeKey}
      bordered={true}
      key={data?._id}
      className={classNames(stylesCollapse.wrapperPanel, idx > 0 && 'mt-2')}
      onChange={seteActiveKey}
      showArrow={true}
    >
      <Panel
        header={
          <div className="flex flex-col">
            <div className="flex items-center min-h-6">
              <Avatar icon={<FlagLineIcon size={17} color="#707070" />} style={{ minWidth: '30px', minHeight: '30px' }} />
              <div className="ml-2 flex items-center justify-start flex-wrap">
                <div className="mr-2">{data?.name}</div>
                <div className="italic" style={{ color: '#C60022' }}>{data?.deductionPercentage ? `(-${data?.deductionPercentage}%)` : null}</div>
              </div>
            </div>
            <div className="italic mt-2">{data?.description}</div>
          </div>
        }
        key="1"
        showArrow={false}
      >
        <Card
          className={'mb-2 rounded'}
          bodyStyle={{padding: '6px', background: '#FAFAFA'}}
        >
          {data?.evidences?.length > 0 && <div className={'block'}>
            <div className="font-bold mt-2 mb-3">{t('details.evidences')}</div>
            {data?.evidences?.map((item, idx) => {
              return (<CardEvidence key={idx} data={item}/>);
            })}
          </div>}
        </Card>

        <Card
          className={'mb-2 rounded'}
          bodyStyle={{padding: '6px', background: '#FAFAFA'}}
        >
          {data?.tasksAudit?.length > 0 && <>
            <div className="font-bold mt-2 mb-3">{t('details.tasks')}</div>
            {data?.tasksAudit?.map((item, idx) => {
              return (<CardTask key={idx} idx={idx} data={item}/>);
            })}
          </>}
        </Card>
      </Panel>
    </Collapse> :
    <Card
      className={'mb-2'}
      style={{border: '1px solid #d9d9d9'}}
      bodyStyle={{padding: '12px 16px', backgroundColor: '#fafafa'}}
    >
      <div className="flex items-center min-h-6">
        <Avatar icon={<FlagLineIcon size={17} color="#707070" />} />
        <div className="ml-2">{data?.name}</div>
      </div>
    </Card>
  );
};

DetailsIssueHeader.propTypes = {
  data: PropTypes.object,
  idx: PropTypes.number
};

export default memo(DetailsIssueHeader);
