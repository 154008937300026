/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/9/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useRenderViewType} from '@/hooks/useRenderViewType';
import TableMenu from './components/TableMenu';
import ButtonGroup from '@/components/RenderViewGroupActions/components/ButtonGroup';

const RenderViewGroupAction = ({isByRoute = false, actions = [], hideActionTable = false}) => {

  const {view} = useRenderViewType();

  if (!view && !isByRoute) {
    return !hideActionTable && <TableMenu/>;
  }

  return (
    <div className="flex items-center justify-center">
      <ButtonGroup isByRoute={isByRoute} actions={actions} className="mr-3" />
      {!hideActionTable && <TableMenu/>}
    </div>
  );
};

RenderViewGroupAction.propTypes = {
  isByRoute: PropTypes.bool,
  actions: PropTypes.array,
  hideActionTable: PropTypes.bool,
};

export default memo(RenderViewGroupAction);
