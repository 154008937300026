/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 28/9/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Typography } from 'antd';
import {useTranslation} from 'react-i18next';
import DeleteBin5LineIcon from 'remixicon-react/DeleteBin5LineIcon';
import styles from './index.less';

const { Text } = Typography;

// const pattern = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

const DynamicEmails = ({required}) => {
  const {t} = useTranslation('common');

  return (
    <Form.List name="emails">
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <div key={field.key} className="mb-3 flex items-start justify-center">
              <Form.Item
                {...field}
                name={field.name}
                fieldKey={field.fieldKey}
                className="mb-0 w-full"
                rules={[{
                  required: !!required,
                  whitespace: true,
                  message: t('dynamicEmails.missingEmail'),
                }, {
                  required: !!required,
                  type: 'email',
                  message: t('dynamicEmails.invalidEmail'),
                }
                ]}
              >
                <Input style={{ width: '100%' }} className={styles.customInput} placeholder={t('dynamicEmails.placeholderEmail')} />
              </Form.Item>
              <div className={styles.wrapperRemove}>
                <DeleteBin5LineIcon className={`ml-2 ant-icon ${styles.removeIcon}`} onClick={() => remove(field.name)} />
              </div>
            </div>
          ))}
          <Text className={styles.actionBtn} onClick={() => add()}>{t('dynamicEmails.addEmail')}</Text>
        </>
      )}
    </Form.List>
  );
};

DynamicEmails.propTypes = {
  required: PropTypes.bool,
};

DynamicEmails.defaultProps = {
  required: true,
};

export default memo(DynamicEmails);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the DynamicEmails component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderEmails = (text, entity) => {
  return (
    <DynamicEmails entity={entity}/>
  );
};
