import React, {memo} from 'react';
import {Divider} from 'antd';
import PropTypes from 'prop-types';

const FooterNotification = ({t, markAsRead, clearAll}) => {

  return <div>
    <div className="w-full p-4 cursor-pointer hover:text-primary text-center" onClick={markAsRead}>
      {t('markAsRead')}
    </div>
    <Divider className="m-0"/>
    <div className="w-full p-4 cursor-pointer hover:text-primary text-center" onClick={clearAll}>
      {t('clearAll')}
    </div>
  </div>;
};

FooterNotification.propTypes = {
  t: PropTypes.func,
  markAsRead: PropTypes.func,
  clearAll: PropTypes.func,
};

export default memo(FooterNotification);