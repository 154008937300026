import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Select, Form} from 'antd';
import {useTranslation} from 'react-i18next';

const {Option} = Select;

const ServicesIvaGroup = ({ value, onChange }) => {
  const {t} = useTranslation('services');
  return (
    <Form.Item name="groupIva">
      <Select defaultValue={value || 'typeA'} onChange={onChange}>
        <Option value="typeA">{t('ivaGroup.typeA')}</Option>
        <Option value="typeB">{t('ivaGroup.typeB')}</Option>
        <Option value="typeC">{t('ivaGroup.typeC')}</Option>
      </Select>
    </Form.Item>
  );
};

ServicesIvaGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
ServicesIvaGroup.defaultProps = {
  entity: {},
};

export default memo(ServicesIvaGroup);

