import {renderClientUploadImage} from '@/modules/clients/components/ClientUploadImage';
import ClientCustomPhone, {renderCustomPhones} from '@/modules/clients/components/ClientCustomPhones';
import ClientCustomPaymentType, {renderCustomPaymentType} from '@/modules/clients/components/ClientCustomPaymentType';
import ClientSelectActivities, {renderCustomActivities} from '@/modules/clients/components/ClientSelectActivities';
import {renderCustomStatus} from '@/modules/clients/components/ClientStatus';
import ClientSelectCompany, {renderCustomCompany} from '@/modules/clients/components/ClientSelectCompany';
import {renderCountry} from '@/components/SelectCountry';
import {renderState} from '@/components/SelectStateCell';
import {renderCity} from '@/components/SelectCityCell';
import {renderDateView} from '@/modules/clients/components/DateView';
import React from 'react';
import i18n from 'i18next';

export const fields = [
  {
    key: 'image',
    label: 'form.imageStructureLabel',
    onlyEdit: true,
    render: renderClientUploadImage,
    entityType: 'structure',
  },
  {
    key: 'name',
    label: 'form.clientName',
    required: true, // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
    placeholder: 'form.placeholderName',
    colSpan: 1,
  },
  {
    key: 'cif',
    label: 'form.cif',
    placeholder: 'form.placeholderCif',
    colSpan: 1,
  },
  {
    key: 'country',
    label: 'form.country',
    renderView: renderCountry,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'province',
    label: 'form.provinceOrState',
    renderView: renderState,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'municipality',
    label: 'form.municipality',
    renderView: renderCity,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'address',
    label: 'form.address',
    placeholder: 'form.placeholderAddress',
    required: true
  },
  {
    key: 'zipCode',
    label: 'form.zipCode',
    placeholder: 'form.placeholderZipCode',
    colSpan: 1,
    required: true,
    type: 'number',
    className: 'custom-input-number'
  },
  {
    key: 'reportType',
    label: 'form.reportType.label',
    colSpan: 2,
    // eslint-disable-next-line react/display-name,react/prop-types
    renderView: (text) => {
      return <div>
        {i18n.t(`clients:form:reportType:${text}`) || 'N/A'}
      </div>;
    },
    onlyView: true,
  },
  {
    key: 'phones',
    widget: ClientCustomPhone,
    initialValue: [{type: 'mobile', phone: ''}, {type: 'home', phone: ''}],
    widgetProps: {
      required: false,
    },
    renderView: renderCustomPhones
  },
  {
    key: 'fax',
    label: 'form.fax',
    placeholder: 'form.placeholderFax',
    colSpan: 1,
  },
  {
    key: 'email',
    label: 'form.email',
    placeholder: 'form.placeholderEmail',
    colSpan: 1,
  },
  {
    key: 'billingEmail',
    label: 'form.billingEmail',
    placeholder: 'form.placeholderBillingEmail',
    colSpan: 1,
  },
  {
    key: 'auditReportEmail',
    label: 'form.auditEmail',
    placeholder: 'form.placeholderAuditEmail',
    colSpan: 1,
  },
  {
    key: 'analyticalReportEmail',
    label: 'form.analyticsEmail',
    placeholder: 'form.placeholderAnalyticsEmail',
    colSpan: 1,
  },
  {
    key: 'contactPerson',
    label: 'form.contactPerson',
    placeholder: 'form.placeholderContactPerson',
    colSpan: 1,
  },
  {
    key: 'paymentType',
    placeholder: 'form.placeholderPaymentType',
    // colSpan: 1,
    widget: ClientCustomPaymentType,
    renderView: renderCustomPaymentType
  },
  {
    key: 'activities',
    placeholder: 'form.placeholderActivities',
    label: 'form.activities',
    colSpan: 2,
    widget: ClientSelectActivities,
    renderView: renderCustomActivities
  },
  {
    key: 'company',
    placeholder: 'form.placeholderCompany',
    label: 'form.company',
    colSpan: 2,
    widget: ClientSelectCompany,
    renderView: renderCustomCompany
  },
  {
    key: 'active',
    label: 'form.status',
    colSpan: 2,
    onlyView: true,
    renderView: renderCustomStatus,
    className: 'client-status',
  },
  {
    key: 'inactivityDate',
    label: 'form.inactivityDate',
    renderView: renderDateView,
    onlyView: true,
    className: 'custom-date-picker',
  },
  {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    onlyView: true,
  },
];
