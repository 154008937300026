import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {Tooltip, Typography} from 'antd';
const { Paragraph } = Typography;

const TemplatesDescriptionCell = ({value}) => {

  return (
    <div className="flex items-center justify-start">
      <div className={'row-flex w-full'}>
        <Paragraph
          className={styles.detailTemplateParagraph}
          /* eslint-disable-next-line react/display-name */
          ellipsis={{tooltip: ()=>{
            return (
              <>
                <Tooltip placement="bottom">
                  {value}
                </Tooltip>
              </>
            );
          }}}
        >
          {value}
        </Paragraph>
      </div>
    </div>
  );
};

TemplatesDescriptionCell.propTypes = {
  value: PropTypes.string,
};
TemplatesDescriptionCell.defaultProps = {
  entity: {},
};

export default memo(TemplatesDescriptionCell);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the TemplatesDescriptionCell component in a table cell
 * @param {string} value this is the row instance
 * */
export const renderTemplatesDescription = (value) => {

  return (
    <TemplatesDescriptionCell value={value}/>
  );
};

