import {createEntityContainer} from '@/utils/createEntityContainer';
import React from 'react';
import UserSelectionAction from '@/modules/admin/components/UserSelectionAction';
import {getUserFullName} from '@/utils/userFullName';
import {
  CACHE_KEY_LIST_USER,
  columns,
  filters,
  ROUTE_PATH_USER,
  TRANSLATION_PREFIX_USER
} from '@/modules/admin/contants/users';
import UserApiService from '@/modules/admin/services/UserApiService';

export const {
  useList: useUserList,
  ContainerList: UserList,
  useTablePagination: useUserPagination
} = createEntityContainer(
  {
    module: CACHE_KEY_LIST_USER,
    service: UserApiService.search,
    route: ROUTE_PATH_USER,
    useCreatePath: true,
    translation: 'admin',
    translationPrefix: TRANSLATION_PREFIX_USER,
    filters,
    columns,
    breadcrumbName: getUserFullName,
    // eslint-disable-next-line react/display-name
    selectedActions: (itemsSelected) => <UserSelectionAction {...itemsSelected}/>
  }
);
