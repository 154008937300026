/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 9/12/21
 */
import React, {memo, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, List, Typography} from 'antd';
import {
  FlagOutlined
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import styles from './index.less';
import incidentStyles from '../WrapperManageTemplateSections/components/ManageTemplateSections/components/QuestionOptions/AddIncidentOrIncidentList/index.less';
import {Scrollbars} from 'react-custom-scrollbars';
import TemplateDetailsIssuesHeader from '@/modules/templates/components/Audits/TemplateDetailsIssuesHeader';
import {getKeyToTranslate} from '@/modules/templates/components/Audits/WrapperManageTemplateSections/components/ManageTemplateSections/components/QuestionOptions/NumberOptions/NumberFormatRules';
const { Text } = Typography;
const { TextArea } = Input;

const Item = ({name, icon}) => {

  return (
    <div className={classNames(styles.incidentItem, 'mb-3 flex items-center justify-start')}>
      {icon} <div className="ml-2">{name}</div>
    </div>
  );
};

Item.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.any,
};

const TemplateDetailsFooterActions = ({question, isCover, type}) => {
  const {t} = useTranslation('templates');

  const [addNote, setAddNote] = useState({
    visible: false,
    note: ''
  });

  const rule = useMemo(() => {
    if(type && type === 'number') {
      return {
        operation: question?.number?.operation || 'ruleNone',
        minValue: question?.number?.minValue || 0,
        maxValue: question?.number?.maxValue || 0
      };
    }
  }, [question?.number?.maxValue, question?.number?.minValue, question?.number?.operation, type]);

  const [showIncidents, setShowIncidents] = useState(false);
  // const [showImages, setShowImages] = useState(false);
  // const [showTasks, setShowTasks] = useState(false);
  // const [taskText, setTaskText] = useState('');
  // const [tasks, setTasks] = useState([]);
  // const [fileList, setFileList] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);

  const handleOnClickTask = useCallback((index) => {
    if(selectedTask.some(x => x === index)) setSelectedTask(selectedTask?.filter(x => x !== index));
    else setSelectedTask([...selectedTask, index]);
  }, [selectedTask]);

  const onAddIncidents = useCallback(() => {
    setShowIncidents(!showIncidents);
  }, [showIncidents]);

  // const onAddImages = useCallback(() => {
  //   setShowImages(!showImages);
  // }, [showImages]);

  /* const handleOnClick = useCallback((id) => {
    if(selected.some(x => x === id)) setSelected(selected?.filter(x => x !== id));
    else setSelected([...selected, id]);
  }, [selected]);*/

  /*  const onAddTasks = useCallback(() => {
    setShowTasks(!showTasks);
    setShowIncidents(false);
    setAddNote(prev => ({...prev, visible: false}));
  }, [showTasks]);*/

  /*  const onChangeTasks = useCallback((evt) => {
    setTaskText(evt.target.value);

    if ((evt.code === 'Enter' || evt.keyCode === 13) && evt.target.value !== '') {
      setTasks(prev => ([...prev, evt.target.value]));
    }
  }, []);*/

  if (isCover) {
    return null;
  }

  return (
    <div className="mt-3">
      {!addNote?.visible && addNote?.note && <div className="w-full flex items-center justify-center mb-3"><div style={{ width: '80%' }}>{addNote?.note}</div></div>}
      {addNote?.visible && <div className="w-full flex items-center justify-center mb-3">
        <div style={{ width: '80%' }}>
          <TextArea className="mb-3" placeholder={t('details.noteName')} value={addNote?.note} onChange={e => setAddNote(prev => ({...prev, note: e.target.value}))}/>
          <div className="flex">
            <Button disabled={!addNote?.note} onClick={() => {
              setAddNote(prev => ({...prev, visible: false}));
            }} type="primary" size="large" className="mr-4">{t('details.btnCreate')}</Button>
            <Button size="large" onClick={() => setAddNote(prev => ({...prev, visible: false}))}>{t('details.btnCancel')}</Button>
          </div>
        </div>
      </div>}

      {/*{*/}
      {/*  showImages && <div className="w-full flex items-center justify-center mb-3">*/}
      {/*    <PicturesWall translation={'templates'} fileList={fileList} setFileList={setFileList}/>*/}
      {/*  </div>*/}
      {/*}*/}

      {/*      {tasks?.length > 0 ?
        <div className="w-full flex items-center justify-center mb-3">
          <div style={{ width: '80%' }}>
            {
              tasks?.map((item, idx) => {
                return <Item key={idx} name={item} icon={<CheckSquareOutlined />} />;
              })
            }
          </div>
        </div>: null
      }*/}

      {
        showIncidents && <div>
          <div className="font-medium text-black mb-3">
            {t('tabs.incidents')}
          </div>
          <Scrollbars
            style={{ height: '350px' }}
            renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
            disableHorizontalScrolling
            autoHide
            autoHideDuration={200}
          >
            <List
              itemLayout="vertical"
              size="large"
              dataSource={question?.issues}
              className={classNames(incidentStyles.viewList)}
              renderItem={(item, i) => (
                <TemplateDetailsIssuesHeader i={i} item={item} handleOnClickTask={handleOnClickTask} selectedTask={selectedTask} />
              )}
            />
          </Scrollbars>
        </div>
      }

      {/*      {
        showTasks && <div className="w-full flex items-center justify-center mb-3">
          <div style={{ width: '80%' }}>
            <TextArea
              size="large"
              className="mb-3"
              placeholder={t('details.taskName')}
              value={taskText}
              onChange={onChangeTasks}
              onPressEnter={() => setTasks(prev => ([...prev, taskText]))}
            />
            <div className="flex">
              <Button disabled={!taskText} onClick={() => {
                setTasks(prev => ([...prev, taskText]));
                setTaskText('');
                setShowTasks(false);
              }} type="primary" size="large" className="mr-4">{t('details.btnCreate')}</Button>
              <Button size="large" onClick={onAddTasks}>{t('details.btnCancel')}</Button>
            </div>
          </div>
        </div>
      }*/}

      <div className="flex flex-row w-full items-center justify-between mt-2">
        {/*<Button type="text" icon={<EditOutlined />} onClick={() => {*/}
        {/*  setAddNote(prev => ({...prev, visible: !prev?.visible}));*/}
        {/*}}>{t('details.newNote')}</Button>*/}
        {/*<Divider type="vertical" />*/}
        {/*<Button type="text" icon={<FileImageOutlined />} onClick={onAddImages}>{t('details.images')}</Button>*/}
        {/*<Divider type="vertical" />*/}
        <Button type="text" icon={<FlagOutlined />} onClick={onAddIncidents} className={styles.btnAddIncident}>{t('details.incidence')}</Button>
        {/*<Button type="text" icon={<CheckSquareOutlined />} onClick={onAddTasks}>{t('details.task')}</Button>*/}
        {type === 'number' &&
        <div className={classNames(styles.numberRules, 'flex items-center justify-center')}>
          <div className='mr-2'>{t('numberFormatRules')}:</div>
          <div className="mr-2"><strong>{t(getKeyToTranslate(rule?.operation))}</strong></div>
          {rule?.operation === 'ruleNone'
            ? <Text
              style={{color: 'var(--primary-color)' }}
            >
              {rule?.minValue}
            </Text>
            : rule?.operation === 'between'
              ? <Text
                style={{color: 'var(--primary-color)' }}
              >
                {`${rule?.minValue || 0} - ${rule?.maxValue || 99999}`}
              </Text>
              : <Text
                style={{color: 'var(--primary-color)'}}
              >
                {rule?.minValue}
              </Text>}
        </div>
        }
      </div>
    </div>
  );
};

TemplateDetailsFooterActions.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  type: PropTypes.string,
};

export default memo(TemplateDetailsFooterActions);
