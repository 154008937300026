/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/12/21
 */
import React, {memo, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Menu, Input/*, Button*/, Spin, Empty} from 'antd';
import { /*PlusSquareOutlined,*/ LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import CategoryName from '@/modules/audits/components/AuditsSelectTemplateCell/components/CategoryTree/components/CategoryName';
import styles from './index.less';
import {useFilter} from '@/contexts/FilterContext';
import {OperatorFilter, TermFilter} from '@dofleini/query-builder';
import {useInfinite} from '@/utils/createInfiniteHook';
import NomenclaturesTemplatesCategoryApiService from '@/modules/settings/services/NomenclaturesTemplatesCategoryApiService';
import { useInfiniteScroll } from '@dofleini/use-scroll';
import {CACHE_KEY_LIST_NOMENCLATURES_TEMPLATES_CATEGORY} from '@/modules/settings/constants/nomenclaturesTemplatesCategory';
import { Scrollbars } from 'react-custom-scrollbars';

const {SubMenu} = Menu;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SERVER_PAGE = 3;

const CategoriesTreeData = ({setCurrentCategory}) => {

  const {t} = useTranslation('templates');
  const {setGlobalFilter} = useFilter();

  const [textSearch, setTextSearch] = useState('');
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState({_id: 'all'});

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isSuccess,
    onLoadCompleted
  } = useInfinite({
    key: [CACHE_KEY_LIST_NOMENCLATURES_TEMPLATES_CATEGORY, search],
    service: NomenclaturesTemplatesCategoryApiService.search,
    search,
    filters: null,
    queryConfig: { size: Math.max(25, SERVER_PAGE) },
  });

  useInfiniteScroll({
    fromWindow: false,
    hasNext: hasNextPage && !(isFetching || isLoading),
    onLoadMore: fetchNextPage
  });

  useEffect(() => {
    if (isSuccess)
      onLoadCompleted && onLoadCompleted(data);
  }, [data, isSuccess, onLoadCompleted]);

  const onScroll = useCallback((event) => {
    const target = event.target;
    if (!(isLoading && isFetching) && target.scrollTop + target.offsetHeight >= (target.scrollHeight - 100) && hasNextPage) {
      fetchNextPage();
    }
  }, [isLoading, isFetching, hasNextPage, fetchNextPage]);

  const handleClick = useCallback((item) => {
    setCurrentCategory(item?.key);
    const filter = new OperatorFilter({
      type: 'AND', filters: [
        new TermFilter({field: 'category', value: item?.key})
      ]
    });
    if (item?.key === 'all') setGlobalFilter && setGlobalFilter(null);
    else  setGlobalFilter && setGlobalFilter(filter);
    setSelected({_id: item?.key, ...item});
  }, [setCurrentCategory, setGlobalFilter]);

  const onTitleClick = useCallback((item) => {
    setCurrentCategory(item?._id);
    const filter = new OperatorFilter({
      type: 'AND', filters: [
        new TermFilter({field: 'category', value: item?._id})
      ]
    });
    setGlobalFilter(filter);
  }, [setCurrentCategory, setGlobalFilter]);

  const renderMenu = useCallback((record) => {
    if(!record?.children || record?.children?.length === 0)
      return <Menu.Item key={record?._id} className="flex items-center justify-start">
        <CategoryName isItem record={record} onChangeName={() => null} selected={selected} setSelected={setSelected} />
      </Menu.Item>;

    return (
      <>
        <SubMenu
          key={record?._id}
          style={selected?._id === record?._id ? {
            background: 'var(--primary-color)',
            color: 'white',
          } : {}}
          className={selected?._id === record?._id && 'selectedSubMenu'}
          title={<div className="w-full h-full flex items-center justify-center">
            <CategoryName
              selected={selected}
              setSelected={setSelected}
              record={record}
              onClick={onTitleClick}
            />
          </div>
          }
        >
          {record?.children?.map(el => renderMenu({...el, isChild: true}))}
        </SubMenu>
      </>

    );
  }, [onTitleClick, selected]);

  return (
    <div className={`w-full ${styles.wrapperTree}`}>
      <div className="categories-search w-full pr-3 pb-0 pt-4">
        <Input
          className={classNames('w-full', styles.searchInput)}
          value={textSearch}
          allowClear
          onChange={e => {
            e.preventDefault();
            setTextSearch(e.target.value);
            if (!e.target.value) setSearch('');
          }}
          placeholder={t('templates.categories.search')}
          onPressEnter={e => {
            e.preventDefault();
            setSearch(e.target.value);
          }}
        />
      </div>

      <div
        id="scrollableDiv"
        style={{
          minHeight: 100,
        }}
      >
        <Scrollbars style={{ height: '400px' }} onScroll={onScroll}>
          <Menu
            onClick={handleClick}
            className={classNames('w-full h-full', styles.styleMenu)}
            defaultSelectedKeys={[selected?._id]}
            selectedKeys={[selected?._id]}
            mode="inline"
          >
            <Menu.Item key="all" className="flex items-center justify-start">{t('templates.categories.all')}</Menu.Item>

            {isSuccess && data?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            {data?.map(el => renderMenu(el))}

            {isFetching && <div key="loading" className="flex items-center justify-center w-full mt-8">
              <span className="mr-4"><Spin size="small" indicator={antIcon}/></span>
              {t('templates.categories.loading')}
            </div>}
          </Menu>
        </Scrollbars>
      </div>
    </div>
  );
};

CategoriesTreeData.propTypes = {
  setCurrentCategory: PropTypes.func
};

export default memo(CategoriesTreeData);
