import {renderClientUploadImage} from '@/modules/clients/components/ClientUploadImage';
import ClientCustomPhone from '@/modules/clients/components/ClientCustomPhones';
import DynamicEmails from '@/components/DynamicEmails';
import ClientSelectSocialReason from '@/modules/clients/components/ClientSelectSocialReason';
import ClientCustomTaxes from '@/modules/clients/components/ClientCustomTaxes';
import {months} from '@/modules/clients/contants/months';
import {renderCountry} from '@/components/SelectCountry';
import {renderState} from '@/components/SelectStateCell';
import {renderCity} from '@/components/SelectCityCell';
import ClientEstablishmentZone, {
  renderClientEstablishmentZone
} from '@/modules/clients/components/ClientEstablishmentZone';
// import {days} from '@/modules/clients/contants/days';

export const fields = [
  {
    key: 'image',
    label: 'form.imageEstablishmentLabel',
    onlyEdit: true,
    render: renderClientUploadImage,
    entityType: 'establishment',
  },
  {
    key: 'name',
    label: 'form.clientName',
    required: true, // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
    placeholder: 'form.placeholderName',
    colSpan: 2,
  },
  {
    key: 'country',
    label: 'form.country',
    renderView: renderCountry,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'province',
    label: 'form.provinceOrState',
    renderView: renderState,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'municipality',
    label: 'form.municipality',
    renderView: renderCity,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'zone',
    label: 'form.zone',
    renderView: renderClientEstablishmentZone,
    onlyView: true,
    colSpan: 1,
  },
  {
    key: 'zone',
    label: 'form.zone',
    widget: ClientEstablishmentZone,
    onlyEdit: true,
    required: true,
    colSpan: 1,
  },
  {
    key: 'address',
    label: 'form.address',
    placeholder: 'form.placeholderAddress',
  },
  {
    key: 'zipCode',
    label: 'form.zipCode',
    placeholder: 'form.placeholderZipCode',
    colSpan: 1,
  },
  {
    key: 'phones',
    widget: ClientCustomPhone,
    initialValue: [{type: 'mobile', phone: ''}, {type: 'home', phone: ''}],
    widgetProps: {
      required: false,
    }
  },
  {
    key: 'emails',
    widget: DynamicEmails,
    initialValue: ['', ''],
    widgetProps: {
      required: false,
    }
  },
  {
    key: 'socialReason',
    placeholder: 'form.placeholderSocialReason',
    label: 'form.socialReason',
    colSpan: 2,
    required: true,
    widget: ClientSelectSocialReason
  },
  {
    key: 'contactPerson',
    label: 'form.contactPerson',
    placeholder: 'form.placeholderContactPerson',
    colSpan: 1,
  },
  {
    key: 'taxesLabel',
    onlyEdit: true,
    widget: ClientCustomTaxes
  },
  {
    key: 'openMonth',
    label: 'form.openMonth',
    placeholder: 'form.placeholderOpenMonth',
    onlyEdit: true,
    className: 'custom-select',
    colSpan: 1,
    widget: 'select', options: months
  },
  {
    key: 'closeMonth',
    label: 'form.closeMonth',
    placeholder: 'form.placeholderCloseMonth',
    onlyEdit: true,
    className: 'custom-select',
    colSpan: 1,
    widget: 'select', options: months
  },
  {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    onlyView: true,
  },
  {
    key: 'type',
    onlyEdit: true,
    initialValue: 'ESTABLISHMENT',
    className: 'hidden-field',
    widgetProps: {
      hidden: true
    }
  }
];
