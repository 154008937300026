/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/10/21
 */
import React, {createContext, useContext, useEffect} from 'react';
import {useLocalStorage} from '@/hooks/useLocalStorage';

const CurrentClientContext = createContext({
  currentClient: {},
  setCurrentClient: val => val,
  currentParent: {},
  setCurrentParent: val => val,
});

const useCurrentClient = () => {
  const context = useContext(CurrentClientContext);
  if (context === undefined) {
    throw new Error('useCurrentClient must be used within a CurrentClientProvider');
  }

  return context;
};

const CurrentClientProvider = ({ ...props }) => {

  const [currentClient, setCurrentClient] = useLocalStorage('current-client', {
    name: '',
    code: '',
    _id: '',
    type: '',
    billingEmail: ''
  });
  const [currentParent, setCurrentParent] = useLocalStorage('current-parent-client', {
    name: '',
    code: '',
    _id: '',
    type: '',
    billingEmail: ''
  });

  useEffect(() => {
    if (currentClient?.type?.toLowerCase() === 'structure' || currentClient?.type?.toLowerCase() === 'establishment' || currentClient?.type?.toLowerCase() === 'area') {
      setCurrentParent(currentClient);
    }
  }, [currentClient, setCurrentParent]);

  return (
    <CurrentClientContext.Provider
      value={{
        currentClient,
        setCurrentClient,
        currentParent,
        setCurrentParent
      }}
      {...props}
    />
  );
};

export { useCurrentClient, CurrentClientProvider };
