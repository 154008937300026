import {EntityApiService, ApiClientService} from '@dofleini/security';

class ClientsApiService extends EntityApiService {

    getOneClient = ({clientId}) => ApiClientService.get(this.getPath(`/${clientId}`));

    delete = ({_id}) => ApiClientService.delete(this.getPath(`/${_id}?cascadeDelete=true`));

    setEstablishmentId = (currentId) => {
      localStorage.setItem('establishment-current-id', currentId);
    }

    getEstablishmentId = () => {
      return localStorage.getItem('establishment-current-id');
    }

    duplicate = (params) => {
      return ApiClientService.post(this.getPath('/duplicate'), params);
    };
}

export default new ClientsApiService('/ms-core/api/establishments');
