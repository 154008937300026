/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesCompaniesApiService from '@/modules/settings/services/NomenclaturesCompaniesApiService';
import {useGetOneCompany} from '@/modules/settings/hooks/useNomenclaturesCompanies';

const ClientSelectCompany = ({value, filters, onChange, isViewMode, ...props}) => {
  const {data} = useGetOneCompany(value);

  if (isViewMode) {
    return (
      <>
        {data?.name || 'N/A'}
      </>
    );
  }

  return (
    <SelectElementCmp
      queryKey={'activities'}
      filters={filters}
      service={NomenclaturesCompaniesApiService}
      includeValue={''}
      value={typeof value === 'object' ? value?.name : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      canAdd
      {...props}
    />
  );
};

ClientSelectCompany.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  isViewMode: PropTypes.func,
};

export default memo(ClientSelectCompany);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {object} entity this is entity type
 * */
export const renderCustomCompany = (text, entity) => {
  return (
    <ClientSelectCompany value={text} entity={entity} isViewMode={true}/>
  );
};
