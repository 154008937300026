import {EntityApiService, ApiClientService} from '@dofleini/security';

class UsersApiService extends EntityApiService {

    updateStatus = (params) => {
      return ApiClientService.post(this.getPath('/status'), params);
    };
    passwordReset = (params) => {
      return ApiClientService.post(this.getPath('/password-reset'), params);
    };

    listActive = () => {
      return ApiClientService.post(this.getPath('/search'), {
        type: 'TERM',
        field: 'status',
        value: 'ACTIVE',
      });
    };

    getOne = (_id) => {
      return ApiClientService.get(this.getPath(`/${_id}`));
    };

}

export default new UsersApiService('/ms-core/api/users');
