/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import DetailAuditor from './components/Auditor';
import DetailsAuditionDate from './components/AuditionDate';
import DetailsAuditDateRange from './components/AuditDateRange';
import DetailsLocation from './components/Location';
import DetailsNumber from './components/Number';
import DetailsSelect from './components/Select';
import DetailsText from './components/Text';
import DetailsInSituMeasure from './components/InSituMeasure';
import Evolution from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/components/Evolution';
import Summary from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/components/Summary';
import Objective from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/components/Objetive';
import RichText from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/components/RichText';

const WrapperFieldType = ({type, data, question, sectionId, isCover, ...props}) => {
  const render = useMemo(() => {
    switch (type) {
      case 'number':
        return <DetailsNumber question={question} {...props} entity={data}/>;
      case 'select':
        return <DetailsSelect question={question} {...props} entity={data}/>;
      case 'readingRegistry':
        return <DetailsInSituMeasure question={question} {...props} entity={data}/>;
      case 'audit_date':
        return <DetailsAuditDateRange sectionId={sectionId} question={question} isCover={isCover} entity={data}/>;
      case 'date':
        return <DetailsAuditionDate sectionId={sectionId} question={question} isCover={isCover} entity={data}/>;
      case 'location':
        return <DetailsLocation sectionId={sectionId} question={question} isCover={isCover} entity={data} />;
      case 'auditor':
        return <DetailAuditor sectionId={sectionId} question={question} isCover={isCover} entity={data}/>;
      case 'evolution':
        return <Evolution sectionId={sectionId} question={question} isCover={isCover} {...props} entity={data}/>;
      case 'summary':
        return <Summary sectionId={sectionId} question={question} isCover={isCover} {...props} entity={data}/>;
      case 'objective':
        return <Objective sectionId={sectionId} question={question} isCover={isCover} {...props} entity={data}/>;
      case 'paragraph':
        return <RichText question={question} {...props} entity={data} />;
      default:
        return <DetailsText question={question} {...props} entity={data} isCover={isCover}/>;
    }
  }, [data, isCover, props, question, sectionId, type]);

  return (
    <div className="py-2">{render}</div>
  );
};

WrapperFieldType.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  question: PropTypes.object,
  isCover: PropTypes.bool,
  sectionId: PropTypes.string,
};

export default memo(WrapperFieldType);
