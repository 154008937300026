import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesServicesCategoryApiService from '@/modules/settings/services/NomenclaturesServicesCategoryApiService';
import {CACHE_KEY_LIST_NOMENCLATURES_SERVICES_CATEGORY} from '@/modules/settings/constants/nomenclaturesServicesCategory';

const ServicesCategories = ({ value, onChange, filters, ...props }) => {

  return (
    <SelectElementCmp
      {...props}
      queryKey={CACHE_KEY_LIST_NOMENCLATURES_SERVICES_CATEGORY}
      filters={filters}
      service={NomenclaturesServicesCategoryApiService}
      customMethod={'/ms-core/api/categories/search-categories-subcategories'}
      additionalParams={{type: 'ALL'}}
      includeValue={''}
      value={typeof value === 'object' ? value?._id : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      canAdd
      aditionalData={{type: 'ALL', description: ''}}
      translation="services"
    />
  );
};

ServicesCategories.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
};
ServicesCategories.defaultProps = {
  entity: {},
};

export default memo(ServicesCategories);

