/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Form, Input } from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/Audits/TemplateDetailsFooterActions';

const TemplateDetailNumber = ({question, isCover, type}) => {

  return (
    <Card
      className={styles.templateNumberCard}
    >
      {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
      {/*  {question?.controlParameter ? '*' : null}*/}
      {/*</div>*/}
      <div className="mb-3">
        {question?.name}
      </div>
      <Form layout="vertical" className="custom-form-big mb-3">
        <Form.Item name="number">
          <Input className="mb-3" onPressEnter={e => {
            e.preventDefault();
            e.stopPropagation();
          }}/>
        </Form.Item>
      </Form>

      <TemplateDetailsFooterActions question={question} isCover={isCover} type={type}/>
    </Card>
  );
};

TemplateDetailNumber.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
  type: PropTypes.string,
};
TemplateDetailNumber.defaultProps = {
  question: {},
};

export default memo(TemplateDetailNumber);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailNumber = (value, entity) => {
  return (
    <TemplateDetailNumber entity={entity}/>
  );
};
