/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 7/1/22
 */
import React, {memo, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import styles from './../../index.less';
import Bookmarks from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/Bookmarks';
import StackFillIcon from 'remixicon-react/StackFillIcon';
import ResponseList from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/ResponseList';
import {useAuditData} from '@/modules/audits/hooks/useAuditData';
import ContentSkeleton from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/ContentSkeleton';

const ManageAuditContent = ({isLoading}) => {

  const {t} = useTranslation('templates');
  const {dataIntro, dataSections} = useAuditData();

  const [visible, setVisible] = useState(false);
  const [activeSection, setActiveSection] = useState(dataIntro?.[0]);

  const unansweredQuestions = useMemo(() => {
    let array = [];
    dataSections?.forEach((section) => {
      section?.questions?.forEach((question) => {
        if(!question?.answer){
          array.push({_id: question?._id, title: question?.name});
        }
      });
    });
    return array;
  },[dataSections]);

  const failedQuestions = useMemo(() => {
    let array = [];
    dataSections?.forEach((section) => {
      section?.questions?.forEach((question) => {
        if(question?.type === 'select' && question?.select?.options){
          const failedOption = question?.select?.options?.filter((el) => el?.failed);
          let value = question?.answer?.templateAnswer?.select;
          if(!Array.isArray(value)){
            value = [value];
          }
          if(failedOption?.length && value?.some(el => failedOption?.some(x => x?._id === el))) {
            array.push({_id: question?._id, title: question?.name});
          }
        }
      });
    });
    return array;
  },[dataSections]);

  const showDrawer = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const closeDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  if (isLoading) return <ContentSkeleton />;

  return (
    <div className={classNames(styles.siteDrawerRenderInCurrentWrapper,'flex flex-row w-full')}>
      <div className={classNames(styles.wrapperSidebarBookMarkDrawer, 'w-full h-full')}>
        <div className="sidebar-drawer">
          <div className={styles.bookMark}>
            <div className={styles.bookMarkBar} onClick={showDrawer}>
              {t('audits.navigation')}
              <StackFillIcon style={{transform: 'rotate(180deg)', margin: '10px 0 0 0', padding: '2px'}} />
            </div>
          </div>
          <div className={classNames(styles.sidebarBookMarkDrawer, visible ? styles.animateSectionsDrawer : styles.hiddenSectionsDrawer)} id="sidebarBookMarkDrawer">
            <Bookmarks
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              data={dataIntro?.concat(dataSections)}
              dataCover={dataIntro}
              unansweredQuestions={unansweredQuestions}
              failedQuestions={failedQuestions}
            />
          </div>
        </div>

        <ResponseList
          setVisible={setVisible}
          closeDrawer={closeDrawer}
        />

      </div>
    </div>
  );
};

ManageAuditContent.propTypes = {
  currentAudit: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default memo(ManageAuditContent);

