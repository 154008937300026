/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from '../TemplateDetailsAuditor/index.less';
import { Card, Form, DatePicker/*, Button, Divider*/ } from 'antd';
// import { EditOutlined, FlagOutlined, CheckSquareOutlined } from '@ant-design/icons';
import moment from 'moment';
import TemplateDetailsFooterActions from '@/modules/templates/components/Audits/TemplateDetailsFooterActions';

const { RangePicker } = DatePicker;

const TemplateDetailsAuditDateRange = ({question, isCover}) => {

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <Form layout="vertical" className="custom-form-big">
        <Form.Item name="date">
          <RangePicker defaultValue={isCover ?  moment() : null} />
        </Form.Item>
      </Form>

      <TemplateDetailsFooterActions question={question} isCover={isCover} />
    </Card>
  );
};

TemplateDetailsAuditDateRange.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailsAuditDateRange.defaultProps = {
  question: {},
};

export default memo(TemplateDetailsAuditDateRange);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsAuditDateRange = (value, entity) => {
  return (
    <TemplateDetailsAuditDateRange entity={entity}/>
  );
};
