import {useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import TemplatesApiService from '@/modules/templates/services/audits/TemplatesApiService';
import {
  CACHE_KEY_TEMPLATES,
  CACHE_KEY_LIST_TEMPLATES,
  TRANSLATION_PREFIX_TEMPLATES
} from '@/modules/templates/contants/audits/templates';
import {fields} from '@/modules/templates/contants/audits/templates.form';
import useAlerts from '@/hooks/useAlerts';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import {message} from 'antd';
import {useTranslation} from 'react-i18next';
import {OperatorFilter, TermFilter} from '@dofleini/query-builder';
import {useMe} from '@/modules/authentication/hooks/useUsers';

export const useGetOneTemplates = createGetOneHook(TemplatesApiService, 'id', CACHE_KEY_TEMPLATES);
export const useFields = createFieldsHook('templates', fields);

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: update, isLoading: isUpdating} = useMutation(TemplatesApiService.update,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_TEMPLATES);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
  return {update, isUpdating};
};

export const useDuplicateTemplate = () => {
  const {t} = useTranslation(TRANSLATION_PREFIX_TEMPLATES);
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: duplicate, isLoading: isDuplicating} = useMutation(TemplatesApiService.duplicate,
    {
      onSuccess: () => {
        message.success(t('duplicateSuccess'));
        queryClient.invalidateQueries(CACHE_KEY_LIST_TEMPLATES);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
  return {duplicate, isDuplicating};
};

export const useGetOneTemplateForAudit = (_id) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(_id)};
  }, [_id]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_TEMPLATES, _id], async () => {
    if (typeof _id !== 'string') return _id;

    const {data} = await TemplatesApiService.getOneTemplate(_id);
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};

export const useGeRecentTemplates = () => {
  const {user} = useMe();

  const filters = new OperatorFilter({
    type: 'AND', filters: [
      new TermFilter({field: 'space', value: user?.space}),
    ]
  }).toQuery();

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_TEMPLATES, filters, 'recent-templates'], async () => {
    const {data} = await TemplatesApiService.search({
      filters,
      search: '',
      sort: {createdAt: -1},
      size: 10
    });
    return data;
  }, {});
  return {isLoading, isError, error, data};
};

export const useReorderQuestions = () => {
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: reorder, isLoading} = useMutation(TemplatesApiService.reorder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CACHE_KEY_LIST_TEMPLATES);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
  return {reorder, isLoading};
};
