/**
 * @author Ricardo Comendador E.
 * @since v0.0.1
 * @date 04/11/2021
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
moment.locale('es-mx');

const TemplateDateView = ({date}) => {
  const formatDate = date ? moment(date).format('LL') : 'N/A';

  return (
    <div className='flex justify-start items-center'>
      <div>{formatDate}</div>
    </div>
  );
};

TemplateDateView.propTypes = {
  date: PropTypes.dateTime
};

TemplateDateView.defaultProps = {
  date: null
};

export default memo(TemplateDateView);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Date component in a table cell
 * @param {string} date this is value of de column
 * */
export const renderDateView = ( date) => {
  return (
    <TemplateDateView date={date}/>
  );
};
