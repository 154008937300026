/* eslint-disable jam3/no-sanitizer-with-danger */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ViewEditor = ({ value }) => {
  return value ? (<span dangerouslySetInnerHTML={{ __html: value }}/>) : '';
};

export default memo(ViewEditor);

ViewEditor.propTypes = {
  value: PropTypes.any,
};

export const renderViewEditor = ({ value, label }) => {
  return (<div className="flex-col">
    {label && <div className="font-bold text-base">{label}:</div>}
    <div dangerouslySetInnerHTML={{ __html: value }}/>
  </div>);
};

export const renderHTMLCellViewEditor = (value) => {
  return (<span dangerouslySetInnerHTML={{ __html: value }}/>);
};
