import React, {memo, Suspense} from 'react';
import {RouteLoader} from '@dofleini/security';
import PageLoader from '@/components/PageLoader';
import routes from './routes';

const MainRouter = () => {
  return (
    <Suspense fallback={<PageLoader/>}>
      <RouteLoader routes={routes} notfoundRedirect={'/home'}/>
    </Suspense>
  );
};

export default memo(MainRouter);
