import {useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import ServicesApiService from '@/modules/settings/services/ServicesApiService';
import {CACHE_KEY_SERVICES} from '@/modules/settings/constants/services';
import {fields} from '@/modules/settings/constants/services.form';
import {useQuery} from 'react-query';

export const useGetOneServices = createGetOneHook(ServicesApiService, 'id', CACHE_KEY_SERVICES);
export const useFields = createFieldsHook('services', fields);

export const useGetOneServiceForAudit = (serviceId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(serviceId)};
  }, [serviceId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_SERVICES, serviceId], async () => {
    if (typeof serviceId !== 'string') return serviceId;

    const {data} = await ServicesApiService.getOneService({serviceId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};
