import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

const ClientFilterContext = createContext({
  currentClient: null,
  setCurrentClient: val => val,
  currentEstablishment: null,
  setCurrentEstablishment: val => val,
  currentArea: null,
  setCurrentArea: val => val,
});

const useClientFilter = () => {
  const context = useContext(ClientFilterContext);
  if (context === undefined) {
    throw new Error('useClientFilter must be used within a AuditDataProvider');
  }

  return context;
};

const ClientFilterProvider = ({ ...props }) => {

  const [currentClient, setCurrentClient] = useState({});
  const [currentEstablishment, setCurrentEstablishment] = useState({});
  const [currentArea, setCurrentArea] = useState({});

  return (
    <ClientFilterContext.Provider
      value={{
        currentClient ,
        setCurrentClient,
        currentEstablishment,
        setCurrentEstablishment,
        currentArea,
        setCurrentArea,
      }}
      {...props}
    />
  );
};

ClientFilterProvider.propTypes = {
  defaultFilter: PropTypes.object,
};

export { useClientFilter, ClientFilterProvider };

