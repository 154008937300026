import React, {memo, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import GeoApiService from '@/services/GeoApiService';
import {useGetOneCountry} from '@/hooks/useGeoPlaces';

const SelectCountry = ({ value, onChange, clearOnChange, filters, setCountryId }) => {

  const handleChange = useCallback((val, opt) => {
    clearOnChange && clearOnChange();
    setCountryId && setCountryId(val);
    onChange(val, opt);
  }, [clearOnChange, onChange, setCountryId]);

  const getValue = useMemo(() => value?._id || value, [value]);

  return (
    <SelectElementCmp
      queryKey={'geo-places-countries'}
      filters={filters}
      service={GeoApiService}
      value={getValue}
      onChange={handleChange}
      sort={{ name: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      additionalParams={{sort: {name: 1}}}
    />
  );
};

SelectCountry.propTypes = {
  value: PropTypes.string || PropTypes.object,
  onChange: PropTypes.func,
  setCountryId: PropTypes.func,
  filters: PropTypes.object,
  clearOnChange: PropTypes.func
};
SelectCountry.defaultProps = {
  entity: {},
};

export default memo(SelectCountry);

const CountryView = ({value}) => {

  const {data} = useGetOneCountry(value);

  return (
    <div>
      {data?.name}
    </div>
  );
};

CountryView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderCountry = (text) => {
  return (
    <CountryView value={text}/>
  );
};
