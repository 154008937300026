import {PasswordRuler} from '@dofleini/validator';
import {authentication} from '@/settings';
import {renderAvatarSection} from '@/modules/admin/components/UserDetailContent/AvatarSection';
import renderAvatarViewWidget from '@/components/UserAvatar/renderAvatarViewWidget';
import createFormFieldset from '@/utils/createFormFieldset';
import {renderUserStatus} from '@/modules/admin/components/UserStatus';
import {renderUserRoles} from '@/modules/admin/components/UserRoles';
import {renderFieldPassword} from '@/modules/admin/components/FieldPassword';
import {useSearchRol} from '@/modules/admin/hooks/useSearchRol';
import createClearRenderView from '@/utils/createClearRenderView';
import DynamicPhones, {renderPhones} from '@/components/DynamicPhones';
import {NameRuler} from '@dofleini/validator';
import {createSearchFieldRemoteRoles} from '@/utils/createSearchFieldRemoteRoles';

export const baseFields = [
  {
    key: 'avatar',
    colSpan: 2,
    onlyView: true,
    renderView: renderAvatarViewWidget
  },
  {
    key: 'avatar',
    colSpan: 2,
    // eslint-disable-next-line react/display-name
    render: renderAvatarSection,
    onlyEdit: true,
    label: 'user.form.avatar.placeholder'
  },
  {
    key: 'firstName',
    colSpan: 1,
    label: 'user.form.name.placeholder',
    placeholder: 'form.placeholderName',
    onlyEdit: true,
    required: true,
    rules: [ NameRuler ]
  },
  {
    key: 'lastName',
    colSpan: 1,
    label: 'user.form.lastName.placeholder',
    placeholder: 'form.placeholderLastName',
    onlyEdit: true,
    rules: [ NameRuler ]
  },
  {
    key: 'occupation',
    colSpan: 1,
    label: 'user.form.occupation.placeholder',
    placeholder: 'form.placeholderOccupation',
    onlyEdit: true,
    rules: [ NameRuler ]
  },
  {
    key: 'roles',
    colSpan: 1,
    label: 'user.form.roles',
    placeholder: 'form.placeholderSelectRoles',
    widget: createSearchFieldRemoteRoles({
      usePagination: useSearchRol,
      mode: 'multiple',
      fullEntity: true,
      translation: 'users'
    }),
    initialValue: [],
    onlyEdit: true,
    required: true,
  },
  {
    key: 'roles',
    colSpan: 1,
    label: 'user.form.roles',
    onlyView: true,
    className: 'custom-user-role-tag-details',
    renderView: renderUserRoles
  },
  {
    key: 'phones',
    label: 'phones',
    widget: DynamicPhones,
    renderView: renderPhones
  },
  {
    key: 'email',
    colSpan: 2,
    label: 'user.form.email.placeholder',
    placeholder: 'form.placeholderEmail',
    renderView: createClearRenderView(),
    onlyEdit: true,
    rules: [{required: true}, {type: 'email'}],
  }
];

const fields = [
  ...baseFields,
  {
    key: 'occupation',
    colSpan: 1,
    label: 'user.form.occupation.placeholder',
    onlyView: true
  },
  {
    key: 'security',
    colSpan: 2,
    render: createFormFieldset({title: 'user.form.security', translation: 'admin'}),
  },
  // {
  //   key: 'status',
  //   colSpan: 2,
  //   label: 'user.form.status',
  //   onlyEdit: true,
  //   widget: renderStatusSelector,
  // },
  {
    key: 'status',
    colSpan: 2,
    label: 'user.form.status',
    className: 'custom-user-status-tag-details',
    onlyView: true,
    viewWidget: ({value}) => renderUserStatus(value, null),
  },
  {
    key: 'createdAt',
    label: 'registerAt',
    type: 'date',
    onlyView: true
  },
  {
    key: 'password',
    label: 'setNewPassword',
    colSpan: 2,
    onlyEdit: true,
    widget: renderFieldPassword,
    rules: [PasswordRuler]
  },
  {
    key: 'verified',
    onlyEdit: true,
    className: 'hidden-field',
    widgetProps: {
      hidden: true
    }
  }
];

if (authentication.showAddress) {
  fields.push(
    {
      key: 'address',
      colSpan: 2,
      label: 'USER.FORM.ADDRESS.PLACEHOLDER'
    }
  );
}

export {fields};
