/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/9/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Empty} from 'antd';
import {useResponsive} from '@/contexts/ResponsiveContext';
import {getloadingArray} from '@/utils/loadingData';
import DirectoryLoading from '@/modules/clients/components/DirectoryLoading';

const List = ({
  dataSource,
  showLatest = false,
  isSubstructure = false,
  extraActions = [],
  renderItem = () => null,
  renderActionItem = () => null,
  onlyOneAction = false,
  isLoadingOrPending,
}) => {

  const {isMobile} = useResponsive();

  if (isLoadingOrPending) {
    return (
      <div style={{ minHeight: showLatest ? '150px' : '' }} className="flex items-start justify-center">
        <Row gutter={[16, 16]} className="w-full">
          {getloadingArray(10)?.map((item, idx) => {
            return (
              <Col key={idx} span={isMobile ? 24 : 6}>
                <DirectoryLoading />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }

  if (dataSource?.length === 0) {
    return (
      <>
        <Row gutter={[16, 16]}>
          {extraActions?.length > 0 && !showLatest
            ? extraActions?.map((item, idx) => {
              return (
                <Col key={idx} span={isSubstructure ? isMobile ? 24 : onlyOneAction ? 6 : 6 : isMobile ? 24 : onlyOneAction ? 6 : 6}>
                  {renderActionItem(item)}
                </Col>
              );
            })
            : null
          }
        </Row>
        <Row gutter={[16, 16]} style={{ minHeight: showLatest ? '150px' : 'calc(100vh - 465px)' }} className="flex w-full items-center justify-center">
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div style={{ minHeight: showLatest ? '150px' : '' }} className="flex items-start justify-center">
      <Row gutter={[16, 16]} className="w-full">
        {extraActions?.length > 0 && !showLatest
          ? extraActions?.map((item, idx) => {
            return (
              <Col key={idx} span={isSubstructure ? isMobile ? 24 : onlyOneAction ? 6 : 6 : isMobile ? 24 : onlyOneAction ? 6 : 6}>
                {renderActionItem(item)}
              </Col>
            );
          })
          : null
        }
        {dataSource?.map((item) => {
          return (
            <Col key={item?._id} span={isSubstructure ? isMobile ? 24 : 6 : isMobile ? 24 : 6}>
              {renderItem(item)}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

List.propTypes = {
  dataSource: PropTypes.array,
  showLatest: PropTypes.bool,
  isSubstructure: PropTypes.bool,
  extraActions: PropTypes.array,
  renderItem: PropTypes.func,
  renderActionItem: PropTypes.func,
  onlyOneAction: PropTypes.bool,
  isLoadingOrPending: PropTypes.bool,
};

export default memo(List);
