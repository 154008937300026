/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/12/21
 */
import React, {memo} from 'react';
// import PropTypes from 'prop-types';
import styles from './index.less';
import CategoriesTreeData from '@/modules/audits/components/AuditsSelectTemplateCell/components/CategoryTree/components/CategoriesTreeData';

const CategoryTree = ({...props}) => {

  return (
    <div className={styles.templatesSidebar}>
      <div className={styles.content}>
        <CategoriesTreeData {...props} />
      </div>
    </div>
  );
};

CategoryTree.propTypes = {};

export default memo(CategoryTree);
