import defaultsDeep from 'lodash/defaultsDeep';
import trimStart from 'lodash/trimStart';

import DatePreview from '@/components/DatePreview';
import Editor from '@/components/Editor';
import ViewEditor from '@/components/Editor/viewEditor';
import DatePickerCmp from '@/utils/datePickerFiel';

const defaultFieldConfig = {
  normalize: value => {
    switch (typeof value) {
      case 'string':
        return trimStart(value);
      default:
        return value;
    }
  }
};

const mapFields = (t, fields, columns) => fields.map(
  (field) => {
    const fieldItem = {
      label: field.label && t(field.label),
      tooltip: (field.tooltip && typeof field.tooltip === 'string') ? t(field.tooltip) : field.tooltip,
      extra: (field.extra && typeof field.extra === 'string') ? t(field.extra) : field.extra,
      help: (field.help && typeof field.help === 'string') ? t(field.help) : field.help,
      placeholder: field.placeholder && t(field.placeholder),
      colSpan: field.colSpan || columns || 2,
    };

    if (field?.type === 'date') {
      fieldItem.widget = DatePickerCmp;
      fieldItem.viewWidget = DatePreview;
    }
    if (field?.type === 'html') {
      fieldItem.widget = Editor;
      fieldItem.viewWidget = ViewEditor;
    }

    return defaultsDeep(fieldItem, field, defaultFieldConfig);
  }
);

export default (t, fields, columns) => {
  if (fields)
    return mapFields(t, fields, columns);
  return fields => mapFields(t, fields, columns);
};
