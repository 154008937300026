import { useMemo } from 'react';
import { useUser } from '@dofleini/security';
import { useTranslation } from 'react-i18next';
import { navigation as defaultNavigationConfig } from '@/settings';
import {hasPermissions} from '@/utils/utils';

/**
 * Process a navigation
 *   1. translate the title
 *   1. Filter and process each navigation child inside the menu field
 * @param {function} t translation function
 * @param {function} hasPermission function to check permission
 * @param {Object} navigation object
 * @param {String} path parent path
 * @param {Boolean} isParentMenu if the element is a parent menu
 * @param {Array} userPermissions array collection
 * @return {Object} navigation parsed
 * */
function processNavigation(t, hasPermission, {title, menu, link, ...rest}, path, isParentMenu, userPermissions) {
  const currentPath = (path || '') + (rest.path || '');
  const currentLink = link ? currentPath + link : link;
  menu = menu ? processMenu(t, hasPermission, menu, currentPath, isParentMenu, userPermissions) : [];

  title = t(title || currentLink);

  return {
    title,
    menu: menu.length ? menu : undefined, //not have children
    link: currentLink,
    ...rest
  };
}

/**
 * Process a menu list
 *   1. filter by permissions
 *   1. process each child navigation
 * @param {function} t translation function
 * @param {function} hasPermission function to check permission
 * @param {Array} menus array of navigations
 * @param {String} path parent path
 * @param {Boolean} isParentMenu if the element is a parent menu
 * @return {Object} menu parsed
 * @param {Array} userPermissions array collection
 * @return {Array} userPermissions array collection
 * */
function processMenu(t, hasPermission, menus = [], path, isParentMenu, userPermissions) {
  const filterMapped = menus.map(navigation => processNavigation(t, hasPermission, navigation, path, isParentMenu, userPermissions));

  return filterMapped.filter((menu) => {
    if (!menu) return false;

    const allow = menu.permissions && hasPermission(userPermissions, menu.permissions);
    return !menu.permissions || allow;
  });
}


/**
 * Process a navigation
 * @param {function} t :translation function
 * @param {function} hasPermission function to check permission
 * @param {Object | Array} navigation navigation object
 * @param {Array} userPermissions array collection
 * @return {Object | Array} navigations parsed
 * */
function processAllNavigation(t, hasPermission, navigation, userPermissions) {

  if (Array.isArray(navigation))
    return processMenu(t, hasPermission, navigation, null, true, userPermissions);
  return {
    menu: processMenu(t, hasPermission, navigation.menu, null, true, userPermissions)
  };
}

const useNavigation = (navigation = defaultNavigationConfig) => {
  const {user} = useUser();
  const {t} = useTranslation('navigation');

  return useMemo(() => {
    return processAllNavigation(t, hasPermissions, navigation, user?.permissions);
  }, [navigation, user?.permissions, t]);
};

export default useNavigation;
