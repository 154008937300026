/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useEffect, useState, useRef, useContext, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card, Table, Input, Form, Typography } from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/Audits/TemplateDetailsFooterActions';
import {useTranslation} from 'react-i18next';

const { Text } = Typography;

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 999999;

const EditableContext = React.createContext(null);

const EditableRow = ({...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props}/>
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record?.[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input type="number" ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

EditableCell.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.any,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
};

const scroll = { x: 500, y: 250 };

const getKeyToTranslate = (key) => {
  switch (key) {
    case 'noFormat': return 'ruleNone';
    case 'greaterThan': return 'ruleGreaterThan';
    case 'lessThan': return 'ruleLessThan';
    case 'equal': return 'ruleEqual';
    default: return 'ruleBetween';
  }
};

const getMeasure = (value) => {
  switch (value) {
    case 'celsius': return '°C';
    case 'fahrenheit': return '°F';
    case 'others': return 'others';
    case 'chlorine': return 'chlorine';
    case 'co2': return 'co2';
    case 'atp': return 'atp';
    case 'mm': return 'mm';
    case 'cm': return 'cm';
    case 'm': return 'm';
    case 'm2': return 'm2';
    case 'm3': return 'm3';
    case 'ph': return 'Ph';
    case 'ppm': return 'ppm';
      // case 'kelvin': return 'Kelvin';
      // case 'rankine': return 'Rankine';
      // case 'réaumur': return 'Réaumur';
      // case 'rømer': return 'Rømer';
      // case 'delisle': return 'Delisle';
    default: return 'none';
  }
};

const TemplateDetailsInSituMeasure = ({question}) => {

  const {t} = useTranslation('templates');

  const cols = useMemo(() =>
    question?.readingRegistry?.options?.map(item =>
      ({...item, title: item?.label, fixed: item?.key === 'place' ? 'left' : null, editable: item?.key !== 'place', dataIndex: item?.key}))
        || [],[question?.readingRegistry?.options]);

  const getAllColKeys = useMemo(() => {
    const keys = cols?.map(item => {
      const key = item?.key;
      return {[key]: '-', dataIndex: key};
    });
    // Merge all array objects into single object
    return Object.assign({}, ...keys);
  }, [cols]);

  const [dataSource, setDataSource] = useState(() => question?.readingRegistry?.placesMeasure?.map((item) => {
    return {...getAllColKeys, ...item,};
  }) || [], [getAllColKeys, question?.readingRegistry?.placesMeasure]);

  const handleSave = useCallback((row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  }, [dataSource]);

  const columns = useMemo(() => cols.map((col) => {
    if (col.key === 'place') {
      return {
        ...col,
        dataIndex: col.key,
        fixed: 'left',
        width: 200,
      };
    }

    return {
      ...col,
      width: 160,
      dataIndex: col.key,
      editable: true,
      title: <div className="w-full">
        <div className="w-full">{col.title}</div>

        <div className="w-full flex items-start justify-center flex-col" style={{color: 'var(--primary-color)'}}>
          <div>
            {t(getMeasure(col?.measure)) || 'N/A'}
          </div>

          <div className="w-full flex items-center justify-start">
            <div className="mr-2">{t(getKeyToTranslate(col?.operation))}</div>
            {col?.operation === 'noFormat'
              ? col?.minValue
              : col?.operation === 'between'
                ? <Text
                  style={{ width: 100, color: 'var(--primary-color)' }}
                  ellipsis={{ tooltip: `${col?.minValue} - ${col?.maxValue}` }}
                >
                  {`(${col?.minValue || DEFAULT_MIN} - ${col?.maxValue || DEFAULT_MAX})`}
                </Text>
                : `(${col?.minValue || 0})`}
          </div>
        </div>
      </div>,
      onCell: (record) => {
        return ({
          record,
          editable: true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave
        });
      },
    };
  }), [cols, handleSave, t]);

  return (
    <Card
      className={styles.templateTextCard}
    >
      {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
      {/*  {question?.controlParameter ? '*' : null}*/}
      {/*</div>*/}
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <Form layout="vertical">
        <Form.Item name="nameInSituMeasure">
          <Table
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            pagination={false}
            rowClassName={() => 'editable-row'}
            className={styles.inSituMeasureTable}
            dataSource={dataSource}
            columns={columns}
            scroll={scroll}
          />
        </Form.Item>
      </Form>

      <TemplateDetailsFooterActions question={question} />
    </Card>
  );
};

TemplateDetailsInSituMeasure.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailsInSituMeasure.defaultProps = {
  question: {},
};

export default memo(TemplateDetailsInSituMeasure);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailsInSituMeasure = (value, entity) => {
  return (
    <TemplateDetailsInSituMeasure entity={entity}/>
  );
};
