import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import createSearchService from '@/utils/createSearchService';

const SERVER_PAGE = 10;
export const useInfinite = ({
  key,
  service,
  search,
  filters,
  sort,
  queryConfig,
  additionalParams,
  onLoadCompleted,
  beforeJoin
}) => {
  const queryClient = useQueryClient();
  const asyncFn = createSearchService(service);
  const requestSize = useMemo(() => queryConfig?.size || SERVER_PAGE, [queryConfig?.size]);

  const fetchData = useCallback(async ({ pageParam = 0 }) => {
    const page = pageParam || 0;
    const { data, headers } = await asyncFn({
      size: requestSize,
      page,
      search,
      sort,
      filters, ...additionalParams
    });
    //
    onLoadCompleted && onLoadCompleted(data || []);
    //
    return { data, nextPage: page + 1, total: Number(headers['x-total-count'] || 0) };
    //
  }, [additionalParams, asyncFn, filters, onLoadCompleted, requestSize, search, sort]);

  const queryKey = useMemo(() => Array.isArray(key) ? key.concat([0]) : [key, 0], [key]);

  const {
    status,
    isLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error,
    isFetching,
    isError,
    ...rest
  } = useInfiniteQuery(
    queryKey,
    fetchData,
    {
      getNextPageParam: ({ data, nextPage }) => data.length === requestSize ? nextPage : false,
      ...queryConfig
    },
  );
  const jointData = useMemo( () => {
    let joint = [];
    data?.pages?.forEach(({ data }) => {
      joint = joint.concat(data);
    });
    beforeJoin && beforeJoin(data?.pages);
    return uniqBy(joint, 'id');
  }, [beforeJoin, data?.pages]);

  const totalElement = useMemo(() => {
    const amount = data?.pages?.reduce((value, element) => {
      return Math.max(element.total, Number(value));
    }, 0);
    return amount || 0;
  }, [data?.pages]);

  useEffect(() => {
    if (hasNextPage) {
      queryClient.prefetchQuery([key], fetchData);
    }
  }, [fetchData, hasNextPage, key, queryClient]);

  return {
    status,
    data: jointData,
    total: totalElement,
    count: jointData.length,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error,
    isFetching,
    isLoading,
    isError,
    ...rest
  };
};
