import {ApiClientService} from '@dofleini/security';

export default (service) => {
  switch (typeof service) {
    case 'function':
      return service;
    case 'string':
      return params => ApiClientService.post(service, params);
    default:
      throw new TypeError('Invalid type of service');
  }
};
