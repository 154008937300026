/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card} from 'antd';
import TemplateDetailsFooterActions from '@/modules/templates/components/Audits/TemplateDetailsFooterActions';
import {useTranslation} from 'react-i18next';

const TemplateDetailSelect = ({question, isCover}) => {

  const {t} = useTranslation('templates');

  const [selected, setSelected] = useState({
    id: '',
    score: 0
  });
  const [multiSelected, setMultiSelected] = useState([]);

  const handleSelect = useCallback((item) => {
    if (question?.select?.multiselect) {
      if (multiSelected?.some(i => i?.id === item?.id)) {
        const removeItem = multiSelected?.filter(i => i?.id !== item?.id);
        setMultiSelected(removeItem);
      } else setMultiSelected(prev => ([...prev, item]));
    } else setSelected(item);
  }, [multiSelected, question?.select?.multiselect]);

  const getSelectedColor = useCallback((item) => {
    if (question?.select?.multiselect) {
      return multiSelected?.some(i => i?.id === item?.id) ? item?.color : 'rgb(243, 246, 251)';
    }
    return selected?.id === item?._id ? item?.color: 'rgb(243, 246, 251)';
  }, [multiSelected, question?.select?.multiselect, selected?.id]);

  // const getTotal = useMemo(() => {
  //   return question?.select?.options?.reduce((sum, value) => (typeof value.score == 'number' ? sum + value.score : sum), 0);
  // }, [question?.select]);

  // const getPercent= useMemo(() => {
  //   return (selected?.score * 100 / getTotal);
  // }, [getTotal, selected?.score]);

  return (
    <Card
      className={styles.templateSelectCard}
    >
      <div className="flex items-center justify-between">
        {/*<div style={{color: 'rgb(235, 0, 39)'}}>*/}
        {/*  {question?.controlParameter ? '*' : null}*/}
        {/*</div>*/}

        <div className="mb-3">
          {question?.name}
        </div>

        {/*{(question?.select?.scoring && selected?.label?.toLowerCase() !== 'n/a') ? <div>*/}
        {/*  {selected?.score + '/' + getTotal} ({Number(getPercent?.toFixed(2))}%)*/}
        {/*</div> : <div style={{ opacity: 0 }}>0/0</div>}*/}
      </div>

      <div className="flex items-center justify-center flex-col">
        {question?.select?.options?.map(item => {
          return <div
            key={item?._id}
            onClick={() => handleSelect(item)}
            className={styles.responseOption}
            style={{ backgroundColor: getSelectedColor(item) }}
          >{t(item?.label)}</div>;
        })}
      </div>

      <TemplateDetailsFooterActions question={question} isCover={isCover} />
    </Card>
  );
};

TemplateDetailSelect.propTypes = {
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailSelect.defaultProps = {
  question: {},
};

export default memo(TemplateDetailSelect);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Detail Audit Card component in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailSelect = (value, entity) => {
  return (
    <TemplateDetailSelect entity={entity}/>
  );
};
