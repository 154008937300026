export const CACHE_KEY_LIST_SECTIONS= 'sections';
export const CACHE_KEY_SECTIONS= 'section-one';
export const ROUTE_PATH_SECTIONS= '/templates/sections';
export const TRANSLATION_PREFIX_SECTIONS= 'templates';

export const TYPE_SECTION = {
  DATA: 'DATA',
  QUESTION: 'QUESTION',
};

