import React, {memo, useCallback, useMemo} from 'react';
import {Select} from 'antd';
import findMatch from '@/utils/findMatch';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import mapFP from 'lodash/fp/map';
import uniqByFP from 'lodash/fp/uniqBy';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import compose from 'lodash/fp/compose';
import filterFP from 'lodash/fp/filter';
import getFP from 'lodash/fp/get';
import compact from 'lodash/compact';
import useSearchValue from '@/hooks/useSearchValue';
import {createMapEntityToOptions} from '@/utils/createMapEntityToOptions';
import {useTranslation} from 'react-i18next';

const defaultGetValue = (value, keyValue) => isObject(value) ? value[keyValue] : value;

export const createSearchFieldRemote = (
  {
    keyValue = '_id',
    keyLabel = 'name',
    usePagination,
    getValue = defaultGetValue,
    mapResponse = v => v,
    fullEntity = false,
    customMapFunc = v => v,
    translation,
    filters = null,
    ...rest
  }
) => {

  const FieldSearchRemote = ({value, onChange, onChangeRef, ...props}) => {
    const [searchValue, setSearchValue] = useSearchValue('');

    const {t} = useTranslation(translation || 'common');

    const _value = useMemo(() => {
      if (value) {
        return isArray(value)
          ? compose(
            mapFP(v => getValue(v, keyValue)),
            uniqByFP(keyValue),
          )(value)
          : getValue(value, keyValue);
      }
    }, [value]);

    const {data: res = [], isLoading} = usePagination(searchValue, filters, {size: 250});

    const mappedRes = useMemo(() => {
      return res?.map(item => {
        if (item?.name === 'USER' || item?.name === 'SUPER_ADMIN')
          return {...item, name: t(item?.name)};
        return item;
      });
    }, [res, t]);

    const data = useMemo(() => mapResponse(mappedRes), [mappedRes]);

    const options = useMemo(() => {
      return compose(
        uniqByFP('value'),
        filterFP(getFP('value')),
        compact,
        mapFP(customMapFunc),
        mapFP(createMapEntityToOptions({keyValue, keyLabel})),
        d => {
          if (isArray(value)) {
            return [...value, ...d];
          } else if (isObject(value)) {
            return [value, ...d];
          } else {
            return d;
          }
        },
      )(data);
    }, [data, value]);

    const handleChange = useCallback((_value) => {
      let fullItem;
      if (isArray(value)) {
        fullItem = map(_value, v => find(data, {[keyValue]: v}));
      } else {
        fullItem = find(data, {[keyValue]: _value});
      }
      onChange && onChange(fullEntity ? fullItem : _value);
      onChangeRef && onChangeRef(fullItem);
    }, [value, onChange, onChangeRef, data]);

    const handleFilter = useCallback((input, option) => {
      return findMatch(input, option?.label);
    }, []);

    return (
      <Select
        value={_value}
        onChange={handleChange}
        className="w-full"
        loading={isLoading}
        options={options}
        optionFilterProp={keyLabel}
        showSearch
        onSearch={setSearchValue}
        filterOption={handleFilter}
        {...rest}
        {...props}
      />
    );
  };

  FieldSearchRemote.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    onChangeRef: PropTypes.func,
  };

  FieldSearchRemote.defaultProps = {};

  return memo(FieldSearchRemote);
};
