/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {useTranslation} from 'react-i18next';

const SectionTitle = ({section}) => {
  const {t} = useTranslation('templates');

  const sectionTitle = useMemo(() => {
    if (section?.title === 'Introduction') {
      return t(section?.title);
    }
    return section?.title;
  }, [section?.title, t]);

  return (
    <div className={styles.sectionHeader}>
      <div className={styles.title}>{sectionTitle}</div>
    </div>
  );
};

SectionTitle.propTypes = {
  section: PropTypes.object
};

export default memo(SectionTitle);
