import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Empty, Select, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useInfinite } from '@/utils/createInfiniteHook';
import useCrud from '@/hooks/useCrud';
import { useTranslation } from 'react-i18next';
import createSearchService from '@/utils/createSearchService';
import map from 'lodash/map';
import { TermFilter } from '@dofleini/query-builder';
import uniqBy from 'lodash/uniqBy';
// import isEqual from 'lodash/isEqual';
// import intersectionWith from 'lodash/intersectionWith';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;
const SelectElementComponent = ({
  queryKey,
  service,
  value,
  onChange,
  getSelectedEntity,
  ignoreValue,
  nameToShow,
  customMethod,
  filters,
  afterLoadData,
  additionalParams,
  extraOptionData, // handle the extra information in the option for the select
  translation,
  canAdd,
  identifyValue,
  aditionalData,
  mode,
  customClick = false,
  customClickField = null,
  style,
  allOption,
  ...props
},) => {
  const { t } = useTranslation(translation || 'common');
  const name = nameToShow ? nameToShow : 'name';
  const _id = identifyValue ? identifyValue : '_id';
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [lastOptions, setLastOptions] = useState([]);
  const [data, setData] = useState([]);
  const { create } = useCrud(service, queryKey);
  const inputRef = useRef();

  const onSearch = useCallback((val) => setSearch(val), []);

  const searchService = useMemo(() => createSearchService(customMethod || service.search), [customMethod, service.search]);

  const beforeJoin = useCallback(async (pages) => {
    let data = null;
    let joint = [];

    pages?.forEach(({ data }) => (joint = joint.concat(data))); // Iterate all pages

    if (value && joint?.length !== 0) {
      let include = Array.isArray(value) ? value.filter(e => !joint.some(item => item[_id] === e[_id] || item[_id] === e)) :
        [value].filter(e => !joint.some(item => item[_id] === e[_id] || item[_id] === e));

      if (include?.length) {
        // Just to exclude custom option all from filter
        if (allOption?.excludeValueFromFilters) include = include.filter(e => e !== allOption?.value);

        const customFilters = value?.includes(allOption?.value) ? filters : {
          'type': 'OR',
          'filters': map(include, value => new TermFilter({ field: _id, value: value[_id] || value }).toQuery())
        };

        setLoading(true);
        const { data: selectedValues } = await searchService({
          size: include?.length !== 0 ? include?.length : 1,
          search: '',
          filters: customFilters
        });
        setLoading(false);

        const notFoundValues = include.filter(e => !selectedValues.some(item => item[_id] === e[_id] || item[_id] === e));
        if (notFoundValues?.length) {
          if (Array.isArray(value)) {
            const newValue = value.filter(e => !notFoundValues.some(item => item[_id] === e[_id] || item[_id] === e));
            onChange(newValue);
            return;
          } else {
            onChange(null);
            return;
          }
        }
        data = selectedValues?.concat(joint);
        if (!value?.includes(allOption?.value)) {
          // onChange(data?.map(e => e?.[_id])); // commented at the moment, because it generates re-renders
          const newData = data ? uniqBy(data, _id) : joint?.filter(x => x?._id !== _id);
          setData(newData); // alternative to avoid re-renders
          return;
        }
      }
    }
    setData(data ? uniqBy(data, _id) : joint?.filter(x => x?._id !== _id));
  }, [_id, allOption?.excludeValueFromFilters, allOption?.value, filters, onChange, searchService, value]);

  let { isLoading, isFetching, fetchNextPage, hasNextPage } = useInfinite({
    key: [queryKey, filters, search],
    search,
    filters,
    service: customMethod || service?.search,
    additionalParams,
    beforeJoin,
    queryConfig:{
      size: 50
    }
  });

  useEffect(() => {
    if (afterLoadData)
      afterLoadData(data);
    return data;
  }, [afterLoadData, data]);

  const onScroll = useCallback((event) => {
    const target = event.target;
    if (!(isLoading && isFetching) && target.scrollTop + target.offsetHeight >= (target.scrollHeight - 50) && hasNextPage) {
      fetchNextPage();
    }
  }, [isLoading, isFetching, hasNextPage, fetchNextPage]);

  const options = useMemo(() => {
    let elements = data || null;
    if (elements && !isLoading) {
      if (ignoreValue) {
        if (typeof ignoreValue === 'string') {
          elements = elements.filter(elem => elem[_id] !== ignoreValue);
        } else if (Array.isArray(ignoreValue)) {
          elements = data.filter(elem => !ignoreValue.some(item => item === elem[_id]));
        }
      }
      const opts = elements.map(item => ({
        value: item?._id,
        _id: item?._id,
        id: item?._id,
        title: item[name],
        key: item?._id,
        label: item[name],
        name: item[name]
      }));
      setLastOptions(opts);
      return opts;
    }
    setLastOptions(lastOptions => lastOptions);
    return [];
  }, [data, isLoading, ignoreValue, _id, name]);

  const handlerBuildOptions = useCallback((item) => {
    const ExtraOptionData = extraOptionData;

    if (isLoading) return null;

    return <Option key={item.value} value={item.value} title={item.title}>
      <div>
        <Tooltip title={item.label || item.title}>
          {isLoading ? '' : item.title}
        </Tooltip>
        {extraOptionData && <ExtraOptionData data={item}/>}
      </div>
    </Option>;
  }, [extraOptionData, isLoading]);

  const customOnChange = useCallback((value, option) => {
    if (customClick) {
      const selected = data.filter(item => value?.includes(item[_id]));
      if (customClickField) {
        onChange && onChange(selected?.[0]?.[customClickField] || selected?.[0]);
      } else {
        onChange && onChange(selected?.[0]);
      }
      return;
    }
    if (mode === 'multiple') {
      // On click to all option
      if (allOption?.show && value?.includes(allOption?.value)) {
        if (value?.[value?.length - 1] === allOption?.value) {
          onChange && onChange([allOption?.value]);
          return;
        }

        onChange && onChange(value?.filter(x => x !== allOption?.value), option);
        return;
      }

      const selected = data.filter(item => value?.includes(item[_id]));
      getSelectedEntity && getSelectedEntity(selected);
      onChange && onChange(selected?.map(item => item?._id), option);
    } else {
      const selected = data.filter(item => item[_id] === value)[0];
      getSelectedEntity && getSelectedEntity(selected);
      onChange && onChange(value, {...selected, ...option});
    }
  }, [_id, allOption?.show, allOption?.value, customClick, customClickField, data, getSelectedEntity, mode, onChange]);

  const onCreate = useCallback(async (value) => {
    try {
      const { data } = await create({ [name]: value, ...aditionalData });
      setSearch('');
      inputRef.current?.blur();
      customOnChange(data?.id, data);
    } catch (e) {
      console.log(e);
    }
  }, [create, aditionalData, customOnChange, name]);

  return (
    <Select
      ref={inputRef}
      optionFilterProp={'title'}
      showSearch
      filterOption={false}
      allowClear={true}
      mode={mode}
      notFoundContent={<>
        {canAdd && search ? <div className="cursor-pointer" onClick={() => onCreate(search)}>
          <span className="mr-1">{t('add')}</span>
          <span className="text-primary font-bold">{search}</span>
        </div> :
          ((isLoading || isFetching) ?
            <div className="flex items-center justify-center"><Spin size="small" indicator={antIcon}/></div> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)}
      </>}
      {...props}
      onSearch={debounce(onSearch, 250)}
      loading={isLoading || isFetching}
      value={isLoading || loading ? '' : value}
      onChange={customOnChange}
      onClear={() => onChange && onChange(null)}
      onPopupScroll={onScroll}
      style={style ? style : { minWidth: '250px' }}
    >
      {!isFetching ? allOption?.show ? (
        <>
          <Option key={allOption.value} value={allOption.value} title={allOption.label} onChange={() => onChange([allOption.value])}>
            <div>
              <Tooltip title={allOption.label || allOption.label}>
                {isLoading ? '' : allOption.label}
              </Tooltip>
            </div>
          </Option>
          {options.map(handlerBuildOptions)}
        </>
      )  : options.map(handlerBuildOptions) : [
        ...lastOptions.map(handlerBuildOptions),
        <Option key="loading" value="loading-item" disabled>
          <div key="loading" className="flex items-center justify-center w-full">
            <span className="mr-4"><Spin size="small" indicator={antIcon}/></span>
          Loading...
          </div>
        </Option>
      ]}
    </Select>
  );

};

export default memo(SelectElementComponent);

SelectElementComponent.propTypes = {
  additionalParams: PropTypes.object,
  customMethod: PropTypes.func,
  filters: PropTypes.object,
  ignoreValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  includeValue: PropTypes.any,
  mode: PropTypes.string,
  nameToShow: PropTypes.string,
  canAdd: PropTypes.bool,
  translation: PropTypes.string,
  getSelectedEntity: PropTypes.func,
  onChange: PropTypes.func,
  afterLoadData: PropTypes.func,
  queryKey: PropTypes.string,
  identifyValue: PropTypes.string,
  aditionalData: PropTypes.object,
  extraOptionData: PropTypes.any,
  service: PropTypes.object,
  value: PropTypes.any,
  customClick: PropTypes.bool,
  customClickField: PropTypes.string,
  style: PropTypes.object,
  allOption: PropTypes.object,
};

SelectElementComponent.defaultProps = {
  filters: {},
  aditionalData: {},
};
