/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 7/1/22
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import styles from '../../index.less';
import {useAuditData} from '@/modules/audits/hooks/useAuditData';
import SectionBox from '../SectionBox';

const ResponseList = ({closeDrawer, onScroll = () => null}) => {
  const {dataIntro, dataSections} = useAuditData();

  const countData = useMemo(() => {
    let countIncidences = 0;
    let countTasks = 0;
    dataSections?.forEach((section) => {
      section?.questions?.forEach((question) => {
        question?.issuesAudit?.forEach((issueAudit) => {
          countIncidences++;
          issueAudit?.tasksAudit?.forEach((task) => {
            task && countTasks++;
          });
        });
      });
    });
    return {countIncidences, countTasks};
  },[dataSections]);

  return (
    <div className="md:px-16 w-full h-full">
      <div className={classNames('md:px-48 md:ml-10', styles.sectionsDrawerContent)} onClick={closeDrawer}>
        <Scrollbars
          style={{ height: 'calc(100vh - 145px)' }}
          onScroll={onScroll}
          renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
          disableHorizontalScrolling
        >
          <div className="mr-4">
            {
              <SectionBox
                countData={countData}
                key={'cover-fields}'}
                section={{}}
                id={'section-view-cover-fields'}
                isCover
              />
            }
            {dataIntro?.concat(dataSections)?.map((item) => <SectionBox isCover={item?.type === 'DATA'} key={item?._id} section={item} id={'section-view-'+item?._id}/>)}

          </div>

          <div id="just-to-go-the-bottom-page" />

        </Scrollbars>
      </div>
    </div>
  );
};

ResponseList.propTypes = {
  closeDrawer: PropTypes.func,
  onScroll: PropTypes.func,
};

export default memo(ResponseList);
