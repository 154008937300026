/**
 * @author Ricardo Comendador Escalona
 * @version v0.0.1
 * @date  15/11/2021
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './../Auditor/index.less';
import { Card } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useAuditReportInternationalization} from '@/contexts/AuditReportInternationalizationContext';

const AuditDateRange = ({entity, question}) => {
  const {t} = useTranslation('templates');
  const data = useMemo(() => entity?.coverFields?.find(f => f?._id === question?._id), [entity?.coverFields, question?._id]);
  const {locale} = useAuditReportInternationalization();

  const auditDate = useMemo(() => {
    if (moment(entity?.date?.[0]) === moment(entity?.date?.[1]))
      return moment(entity?.date?.[1])?.locale(locale || 'es').format('ll');
    return `${moment(entity?.date?.[0])?.locale(locale || 'es').format('ll')} - ${moment(entity?.date?.[1])?.locale(locale || 'es').format('ll')}`;
  }, [entity?.date, locale]);

  return (
    <Card
      className={styles.templateAuditorCard}
    >
      <div className="mb-3">
        {t(data?.label) || question?.name || question?.label}
      </div>
      <div className="flex items-center justify-start flex-wrap">
        <div className={'mr-6'}>
          <CalendarOutlined />
          <span className={'ml-2'}>{auditDate}</span>
        </div>
      </div>
    </Card>
  );
};

AuditDateRange.propTypes = {
  question: PropTypes.object,
  entity: PropTypes.object,
  isCover: PropTypes.bool,
};
AuditDateRange.defaultProps = {
  entity: {},
};

export default memo(AuditDateRange);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailAuditDateRange = (value, entity) => {
  return (
    <AuditDateRange entity={entity}/>
  );
};
