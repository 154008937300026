import {EntityApiService, ApiClientService} from '@dofleini/security';

class GeoApiService extends EntityApiService {

    search = (params) => {
      return ApiClientService.post(this.getPath('/countries/search'), params);
    };

    getStates = (countryId, params) => {
      return ApiClientService.post(this.getPath(`/states/${countryId}/search`), params);
    };

    getCities = (stateId, params) => {
      return ApiClientService.post(this.getPath(`/cities/${stateId}/search`), params);
    };

    getOneCountry = (countryId) => {
      return ApiClientService.get(this.getPath(`/countries/${countryId}`));
    };

    getOneState = (stateId) => {
      return ApiClientService.get(this.getPath(`/states/${stateId}`));
    };

    getOneCity = (cityId) => {
      return ApiClientService.get(this.getPath(`/cities/${cityId}`));
    };
}

export default new GeoApiService('/ms-core/api');
