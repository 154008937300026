/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 10/1/22
 */
import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

// const disabledDate = (current) => current && current < moment().endOf('day'); // Can not select days before today and today

const AuditsStartDate = ({value, onChange}) => {

  const handleChange = useCallback((date) => {
    if (!date) {
      onChange(date);
    } else {
      let newDate;
      if (moment(date) > moment()) {
        newDate = moment(date).set('date', 1);
      } else {
        newDate = moment(date).set('date',  parseInt(moment().format('DD')));
      }
      onChange(newDate);
    }
  }, [onChange]);

  return (
    <DatePicker
      value={value ? moment(value) : null}
      onChange={handleChange}
      picker="month"
      format="MM/YYYY"
      style={{ width: '100%' }}
      // disabledDate={disabledDate}
    />
  );
};

AuditsStartDate.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(AuditsStartDate);
