/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 20/12/21
 */
import React, {memo, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import {useGetOneEstablishment} from '@/modules/clients/hooks/useClientsEstablishment';
import ClientsEstablishmentApiService from '@/modules/clients/services/ClientsEstablishmentApiService';
import {useClientFilter} from '@/hooks/useClientFilter';
import {FilterFactory, TermFilter} from '@dofleini/query-builder';

const clientId = (value) => {
  return (typeof value === 'object') ? value?._id : value;
};

const AuditsSelectEstablishment = ({value, filters, onChange, isViewMode, ...props}) => {
  const {data} = useGetOneEstablishment(clientId(value));
  const { currentClient, setCurrentEstablishment } = useClientFilter();

  useEffect(() => {
    if(value)
      setCurrentEstablishment(clientId(value));
  }, [setCurrentEstablishment, value]);

  const newFilter = useMemo(() => {
    return FilterFactory.add(filters, new TermFilter({field: 'secureSpacePath', value: {$in: currentClient?.space}}))?.toQuery();
  }, [currentClient, filters]);

  if (isViewMode) {
    return (
      <>
        {data?.name || 'N/A'}
      </>
    );
  }

  return (
    <SelectElementCmp
      key={value}
      queryKey={'establishment'}
      filters={newFilter}
      service={ClientsEstablishmentApiService}
      includeValue={''}
      value={typeof value === 'object' ? value?.name : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      identifyValue={value?._id}
      canAdd
      {...props}
    />
  );
};

AuditsSelectEstablishment.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  isViewMode: PropTypes.func,
};

export default memo(AuditsSelectEstablishment);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {object} entity this is entity type
 * */
export const renderEstablishment = (text, entity) => {
  return (
    <AuditsSelectEstablishment value={text} entity={entity} isViewMode={true}/>
  );
};

