/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '@/modules/templates/components/Audits/TemplateSectionsDrawer/components/SectionTitle';
import WrapperFieldType from '@/modules/templates/components/Audits/TemplateSectionsDrawer/components/WrapperFieldType';
import {useTranslation} from 'react-i18next';

const SectionBox = ({section, id}) => {
  const {t} = useTranslation('templates');

  return (
    <div id={id}>
      <SectionTitle section={section} />
      {section?.metadata?.coverFields?.length === 0
        ? <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
        : section?.metadata?.coverFields?.map(item => {
          return <WrapperFieldType key={item?._id} type={item?.type} question={item} isCover />;
        })}
      {section?.questions?.length === 0 && !section?.metadata
        ? <div className="w-full flex items-center justify-center min-h-8">{t('noQuestionsForThisSection')}</div>
        : section?.questions?.map(item => {
          return <WrapperFieldType key={item?._id} type={item?.type} question={item} sectionId={section?._id}/>;
        })}
    </div>
  );
};

SectionBox.propTypes = {
  section: PropTypes.object,
  id: PropTypes.string,
};

export default memo(SectionBox);
