/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import SocialReasonApiService from '@/modules/settings/services/SocialReasonApiService';

const ClientSocialReason = ({value, filters, onChange, ...props}) => {
  return (
    <SelectElementCmp
      queryKey={'social-reasons'}
      filters={filters}
      service={SocialReasonApiService}
      includeValue={''}
      value={typeof value === 'object' ? value?.name : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      canAdd
      {...props}
    />
  );
};

ClientSocialReason.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
};

export default memo(ClientSocialReason);
