import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';
import mapFP from 'lodash/fp/map';
import uniqByFP from 'lodash/fp/uniqBy';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import TagList from '@/components/TagList';
import { useTranslation } from 'react-i18next';
import {staticRoles} from '@/modules/users/contants/static.roles';

const UserRoles = ({value}) => {
  const { t } = useTranslation('rolesAndPermissions');

  const roles = useMemo(() => compose(
    compact,
    mapFP((item) => ({ name: staticRoles?.includes(item?.name) ? t(`${item?.name}.title`) : item?.name, superAdmin: item?.superAdmin })),
    uniqByFP('_id'),
  )(value), [t, value]);

  return (
    <div className={'flex flex-row items-center'}>
      <TagList
        value={roles}
        renderField={'name'}
        limit={5}
        tag
        conditionalColor={(item) => item?.superAdmin ? 'processing' : undefined}/>
    </div>
  );
};

UserRoles.propTypes = {
  value: PropTypes.array
};

UserRoles.defaultProps = {
  value: []
};

export default memo(UserRoles);

export const renderUserRoles = (roles, form, initialValues) => {
  const value = isEmpty(roles) ? initialValues?.roles : roles;
  return <UserRoles value={value}/>;
};
