import ServicesCategories from '@/modules/settings/components/ServicesSelectCategoriesCell';
import ServicesIvaGroup from '@/modules/settings/components/ServicesIvaGroupCell';

export const fields = [
  {
    key: 'name',
    label: 'name',
    required: true, // this adds an entry to rules: [{ required: true, message: 'Username is required' }],
    placeholder: 'form.placeholderName',
  },
  {
    key: 'category',
    label: 'category',
    placeholder: 'form.placeholderSelectCategory',
    widget: ServicesCategories
  },
  {

    key: 'description',
    label: 'description',
    widget: 'textarea',
    placeholder: 'form.placeholderDescription',
    widgetProps: {autoSize: {minRows: 5, maxRows: 12}},
    className: 'form-view-mode-vertical-item'
  },
  {
    key: 'executionTime',
    label: 'time',
    required: true,
    colSpan: 1,
    widget: 'number',
    placeholder: 'form.placeholderExecutionTime',
    widgetProps: {
      className: 'custom-input-number input-text-right'
    }
  },
  {
    key: 'price',
    label: 'price',
    required: true,
    colSpan: 1,
    widget: 'number',
    placeholder: 'form.placeholderPrice',
    widgetProps: {
      className: 'custom-input-number input-text-right',
    }
  },
  {
    key: 'groupIva',
    label: 'iva',
    colSpan: 2,
    initialValue: 'typeA',
    widget: ServicesIvaGroup
  },
  {
    key: 'salesAccount',
    label: 'account',
    required: true,
    colSpan: 2,
    widget: 'number',
    placeholder: 'form.placeholderAccountSale',
    widgetProps: {
      className: 'custom-input-number'
    }
  },
];
