import NomenclatureCategoriesSelectColor from '@/modules/settings/components/NomenclatureCategoriesSelectColor';

export const fields = [
  {
    key: 'name',
    label: 'name',
    required: true, // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
    placeholder: 'form.placeholderName',
    colSpan: 2,
    whitespace: true,
  },
  {

    key: 'color',
    label: 'Color',
    placeholder: 'form.color',
    colSpan: 2,
    required: true,
    widget: NomenclatureCategoriesSelectColor
  },
  {

    key: 'description',
    label: 'description',
    widget: 'textarea',
    placeholder: 'form.placeholderDescription',
    widgetProps: {autoSize: {minRows: 5, maxRows: 12}},
    className: 'form-view-mode-vertical-item',
    colSpan: 2
  },
  {
    key: 'type',
    initialValue: 'ALL',
    onlyEdit: true,
    className: 'hidden-field',
  },
  {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    onlyView: true,
    className: 'hidden-field',
  },
];
