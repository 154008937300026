import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const ClientsCell = ({status, entity}) => {

  const {t} = useTranslation('clients');

  if (entity?.type === 'ESTABLISHMENT') return null;

  if (status) {
    return (
      <div className={`flex flex-row ${styles.statusActive}`}>
        <div className={'flex flex-col justify-center items-center w-full'}>
          <span className={
            'font-medium lg:font-normal ' +
          'mb-1 lg:mb-0'
          }>
            {t('states.active')}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={`flex flex-row ${styles.statusInactive}`}>
      <div className={'flex flex-col justify-center items-center w-full'}>
        <span className={
          'font-medium lg:font-normal ' +
            'mb-1 lg:mb-0'
        }>
          {t('states.inactive')}
        </span>
      </div>
    </div>
  );
};

ClientsCell.propTypes = {
  status: PropTypes.bool,
  entity: PropTypes.object,
};
ClientsCell.defaultProps = {
  entity: {},
};

export default memo(ClientsCell);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Email component in a table cell
 * @param {boolean} status this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderClientsStatus = (status, entity) => {
  return (
    <ClientsCell status={status} entity={entity}/>
  );
};

