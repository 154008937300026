export const CACHE_KEY_LIST_AUDITS = 'audits';
export const CACHE_KEY_AUDITS = 'audits-one';
export const CACHE_KEY_LIST_AUDITOR_AUDITS_SECTIONS = 'audit-sections';
export const ROUTE_PATH_AUDITS = '/audits';
export const TRANSLATION_PREFIX_AUDITS = 'audits';
export const TRANSLATION_PREFIX_PENDING_AUDITS = 'pendingAudits';

export const NUMBER_FORMAT = {
  temperature: ' º',
  currency: ' $',
};

export const STATUS_AUDIT = {
  Draft: 'draft',
  Running: 'running',
  Finished: 'finished',
};
