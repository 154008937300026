/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 10/3/22
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DateView = ({value}) => {

  if (!value) return <>N/A</>;

  return (
    <div>
      {!moment.isMoment(value) ? moment(value) :  value ? value.format('ll') : 'N/A'}
    </div>
  );
};

DateView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderDateView= (text) => {
  return (
    <DateView value={text}/>
  );
};

