/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 10/6/22
 */
import React, {memo} from 'react';
import { Skeleton, Space } from 'antd';
import classNames from 'classnames';
import styles from './index.less';

const HeaderSkeleton = () => {
  return (
    <div className={styles.manageAuditHeader}>
      <div className={styles.skeletonTitle}>
        <div className={classNames('flex items-center justify-center cursor-pointer', styles.hoverGoBack)}>
          <div className="flex items-center justify-center w-full h-full">
            <Skeleton.Button active size="large" shape="circle" block={false} />
          </div>

          <div className="flex items-start justify-center cursor-pointer mr-2 flex-col" style={{minWidth: '216px'}}>
            <div className="ml-4 template-section-title w-full">
              <Skeleton.Button className="w-full" active size={18} shape="default" />
            </div>

            <div className="flex items-center justify-start flex-wrap w-full">

              <div className="flex items-center justify-center ml-4 w-full">
                <Skeleton.Button className="w-full" active size={18} shape="square" block={false} />
                <Skeleton.Button className="mx-1" active size={20} shape="square" block={false} />
                <Skeleton.Button className="w-full" active size={18} shape="square" block={false} />
              </div>
            </div>

          </div>

        </div>
      </div>

      <div className={styles.skeletonActions}>
        <div className={classNames('flex lg:items-end lg:justify-end md:flex md:flex-col w-full', styles.buttons)}>
          <Space className="w-full">
            <Skeleton.Button active size={18} shape="square" block={false} />
          </Space>
          <div className="font-bold sm-ml-2 flex items-center w-full"
            style={{fontSize: '1.2rem'}}>
            <Skeleton.Button active size={25} shape="square" block={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderSkeleton.propTypes = {};

export default memo(HeaderSkeleton);
