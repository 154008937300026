/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 20/12/21
 */
import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'antd';
import SelectElementCmp from '@/utils/SelectElementCmp';
import ClientsApiService from '@/modules/clients/services/ClientsApiService';
import {useGetOneClient} from '@/modules/clients/hooks/useClients';
import {useClientFilter} from '@/hooks/useClientFilter';

const clientId = (value) => {
  return (typeof value === 'object') ? value?._id : value;
};

const AuditsSelectClients = ({value, filters, onChange, isViewMode, ...props}) => {
  const {data} = useGetOneClient(clientId(value));
  const { setCurrentClient } = useClientFilter();

  useEffect(() => {
    if(value)
      setCurrentClient({_id: data?._id, space: data?.space});
  }, [data, data?._id, data?.space, setCurrentClient, value]);

  if (isViewMode) {
    return (
      <>
        {data?.name || 'N/A'}
      </>
    );
  }

  return (
    <SelectElementCmp
      key={value}
      queryKey={'clients-select'}
      filters={filters}
      service={ClientsApiService}
      includeValue={''}
      value={typeof value === 'object' ? value?.name : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      identifyValue={value?._id}
      canAdd
      {...props}
    />
  );
};

AuditsSelectClients.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  isViewMode: PropTypes.func,
};

export default memo(AuditsSelectClients);

// eslint-disable-next-line react/prop-types
export const renderSelectClient = ({formItemProps, form, field, ...props}) => {
// eslint-disable-next-line react/prop-types
  const onChange = (value = {}) => {
    // eslint-disable-next-line react/prop-types
    form.setFieldsValue({
      establishment: null,
      ...value
    });
  };

  return (
  // eslint-disable-next-line react/prop-types
    <Form.Item {...formItemProps} required={field?.required}>
      <AuditsSelectClients onChange={onChange} {...props} />
    </Form.Item>
  );
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {object} entity this is entity type
 * */
export const renderCustomCompany = (text, entity) => {
  return (
    <AuditsSelectClients value={text} entity={entity} isViewMode={true}/>
  );
};

