import React, {useEffect} from 'react';
import MainApp from '@/modules/MainApp';
import { useUser } from '@dofleini/security';
import UnauthenticatedApp from '@/modules/UnauthenticatedApp';
import { authentication } from '@/settings/authentication';
import OnboardingModule from '@/modules/onboarding';
import {ApiClientService} from '@dofleini/security';
import {useLocation, useParams} from 'react-router-dom';
import ShareAuditReport from '@/modules/ShareAuditReport';
import {i18nSinglePage as i18n} from '@/settings';
import {AuditReportInternationalizationProvider} from '@/contexts/AuditReportInternationalizationContext';
import TokenService from '@/contexts/TokenService';

ApiClientService.setup({
  TokenService,
  loginPath: /(\/api\/signin)|(\/api\/refresh-token)/
});

function App () {
  const { user } = useUser();
  const {pathname} = useLocation();
  const {id} = useParams();

  useEffect(() => {
    ApiClientService.interceptors.request.use(function (config) {
      config.headers['client-date'] = new Date().toISOString();
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
  }, []);

  // if (href.includes(SPACE_KEY_DOMAIN)) {
  //   const space = window.location.host || window.location.hostname;
  //   ApiClientService.setSpace(space);
  // } else {
  ApiClientService.setSpace('ROOT_SPACE');
  // }

  useEffect(() => {
    if (!pathname?.includes('/audits/'+id+'/details')) i18n.changeLanguage('es');
  }, [id, pathname]);

  if (pathname?.includes('share-audit-report')) return (

    <AuditReportInternationalizationProvider>
      <ShareAuditReport />
    </AuditReportInternationalizationProvider>
  );


  if (user && authentication?.activeOnboarding && !user?.onboardingCompleted)
    return <OnboardingModule/>;

  if (user)
    return <MainApp/>;

  return <UnauthenticatedApp/>;
}

export default App;
