import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import SelectElementCmp from '@/utils/SelectElementCmp';
import GeoApiService from '@/services/GeoApiService';
import { FilterFactory } from '@dofleini/query-builder';
import {useTranslation} from 'react-i18next';
import {useGetOneState} from '@/hooks/useGeoPlaces';

const SelectState = ({ value, onChange, filter, countryId, setStateId, translation, clearOnChange, ...props }) => {
  const {t} = useTranslation(translation);

  const handleChange = useCallback((val) => {
    clearOnChange && clearOnChange();
    setStateId(val);
    onChange(val);
  }, [clearOnChange, onChange, setStateId]);

  const filters = FilterFactory.add({}, filter);

  if (!countryId) {
    return (
      <Select disabled={!countryId} placeholder={t('form.placeholderEmployeeProvinceOrState')} className="custom-select"/>
    );
  }

  return (
    <SelectElementCmp
      queryKey={'geo-places-states'}
      filters={filters.toQuery()}
      service={GeoApiService}
      customMethod={`/ms-core/api/states/${countryId}/search`}
      additionalParams={filters.toQuery()}
      includeValue={''}
      value={typeof value === 'object' ? value?.value : value}
      onChange={handleChange}
      sort={{ code: 1 }}
      customMapItem={(v) => ({...v, name: v })}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      {...props}
    />
  );
};

SelectState.propTypes = {
  value: PropTypes.string || PropTypes.object,
  onChange: PropTypes.func,
  setStateId: PropTypes.func,
  filter: PropTypes.object,
  countryId: PropTypes.string,
  translation: PropTypes.string,
  clearOnChange: PropTypes.func
};
SelectState.defaultProps = {
  entity: {},
};

export default memo(SelectState);

const StateView = ({value}) => {

  const {data} = useGetOneState(value);

  return (
    <div>
      {data?.name || 'N/A'}
    </div>
  );
};

StateView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderState = (text) => {
  return (
    <StateView value={text}/>
  );
};
