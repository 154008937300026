/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Divider} from 'antd';
import {useTranslation} from 'react-i18next';

const ClientCustomTaxes = () => {
  const {t} = useTranslation('clients');

  return (
    <div className="mt-2">
      <div>{t('form.taxData')}</div>
      <Divider style={{ marginBottom: '1.2rem', marginTop: '0.4rem' }}/>
    </div>
  );
};

ClientCustomTaxes.propTypes = {
  text: PropTypes.object
};

export default memo(ClientCustomTaxes);
