export const dataClients = [
  {    '_id' : '6149fdb2ee2d6b38410d68cb',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '6149fd350fbf7c37624e840d'
    ],
    type: 'structure',
    'name' : 'Structure 1',
    'code' : 'STR-1',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T15:43:46.063Z',
    'updatedAt' : '2021-09-21T15:43:46.063Z',
    '__v' : 0,
    subStructureCount: 10,
    children : [
      {
        '_id' : '614a33e88ca74a544cf91195',
        'deleted' : false,
        'deletedAt' : null,
        'secureSpacePath' : [
          '6148f40f6afa9a63cd46efbd'
        ],
        subStructureCount: 5,
        'establishmentCount' : 0,
        'phones' : [
          '78458748'
        ],
        'activities' : [
          '614a0f2228184e41118322a0'
        ],
        type: 'structure',
        'name' : 'Sub Structure 3',
        'code' : 'STR-85',
        'cif' : 'asd',
        'parent' : '614a173828184e41118322a4',
        'billingEmail' : 'structure1@yopmail.com',
        'auditReportEmail' : 'structure1@yopmail.com',
        'analyticalReportEmail' : 'structure1@yopmail.com',
        'contactPerson' : {
          'name' : 'Contact Person'
        },
        'owner' : '6148f40f6afa9a63cd46efc3',
        'space' : '6148f40f6afa9a63cd46efbd',
        'createdAt' : '2021-09-21T19:35:04.276Z',
        'updatedAt' : '2021-09-21T19:35:04.276Z',
        '__v' : 0,
        children : [
          {
            '_id' : '916532',
            'deleted' : false,
            'deletedAt' : null,
            'secureSpacePath' : [
              '6148f40f6afa9a63cd46efbd'
            ],
            'subStructureCount' : 0,
            'establishmentCount' : 0,
            'phones' : [
              '78458748'
            ],
            'activities' : [
              '614a0f2228184e41118322a0'
            ],
            'name' : 'Structure 3',
            'code' : 'STR-1',
            'cif' : 'asd',
            'parent' : '614a173828184e41118322a4',
            'billingEmail' : 'structure1@yopmail.com',
            'auditReportEmail' : 'structure1@yopmail.com',
            'analyticalReportEmail' : 'structure1@yopmail.com',
            'contactPerson' : {
              'name' : 'Contact Person'
            },
            'owner' : '6148f40f6afa9a63cd46efc3',
            'space' : '6148f40f6afa9a63cd46efbd',
            'createdAt' : '2021-09-21T19:35:04.276Z',
            'updatedAt' : '2021-09-21T19:35:04.276Z',
            '__v' : 0
          },
          {
            '_id' : '91555',
            'deleted' : false,
            'deletedAt' : null,
            'secureSpacePath' : [
              '6148f40f6afa9a63cd46efbd'
            ],
            subStructureCount: 5,
            type: 'structure',
            'establishmentCount' : 0,
            'phones' : [
              '78458748'
            ],
            'activities' : [
              '614a0f2228184e41118322a0'
            ],
            'name' : 'Structure 782',
            'code' : 'STR-1',
            'cif' : 'asd',
            'parent' : '614a173828184e41118322a4',
            'billingEmail' : 'structure1@yopmail.com',
            'auditReportEmail' : 'structure1@yopmail.com',
            'analyticalReportEmail' : 'structure1@yopmail.com',
            'contactPerson' : {
              'name' : 'Contact Person'
            },
            'owner' : '6148f40f6afa9a63cd46efc3',
            'space' : '6148f40f6afa9a63cd46efbd',
            'createdAt' : '2021-09-21T19:35:04.276Z',
            'updatedAt' : '2021-09-21T19:35:04.276Z',
            '__v' : 0,
            children: [
              {
                '_id' : '2e3e',
                'deleted' : false,
                'deletedAt' : null,
                'secureSpacePath' : [
                  '6148f40f6afa9a63cd46efbd'
                ],
                subStructureCount: 5,
                'establishmentCount' : 0,
                'phones' : [
                  '78458748'
                ],
                'activities' : [
                  '614a0f2228184e41118322a0'
                ],
                type: 'structure',
                'name' : 'Sub Structure 3',
                'code' : 'STR-85',
                'cif' : 'asd',
                'parent' : '614a173828184e41118322a4',
                'billingEmail' : 'structure1@yopmail.com',
                'auditReportEmail' : 'structure1@yopmail.com',
                'analyticalReportEmail' : 'structure1@yopmail.com',
                'contactPerson' : {
                  'name' : 'Contact Person'
                },
                'owner' : '6148f40f6afa9a63cd46efc3',
                'space' : '6148f40f6afa9a63cd46efbd',
                'createdAt' : '2021-09-21T19:35:04.276Z',
                'updatedAt' : '2021-09-21T19:35:04.276Z',
                '__v' : 0,
                children : [
                  {
                    '_id' : '353454',
                    'deleted' : false,
                    'deletedAt' : null,
                    'secureSpacePath' : [
                      '6148f40f6afa9a63cd46efbd'
                    ],
                    'subStructureCount' : 0,
                    'establishmentCount' : 0,
                    'phones' : [
                      '78458748'
                    ],
                    'activities' : [
                      '614a0f2228184e41118322a0'
                    ],
                    'name' : 'Structure 3',
                    'code' : 'STR-1',
                    'cif' : 'asd',
                    'parent' : '614a173828184e41118322a4',
                    'billingEmail' : 'structure1@yopmail.com',
                    'auditReportEmail' : 'structure1@yopmail.com',
                    'analyticalReportEmail' : 'structure1@yopmail.com',
                    'contactPerson' : {
                      'name' : 'Contact Person'
                    },
                    'owner' : '6148f40f6afa9a63cd46efc3',
                    'space' : '6148f40f6afa9a63cd46efbd',
                    'createdAt' : '2021-09-21T19:35:04.276Z',
                    'updatedAt' : '2021-09-21T19:35:04.276Z',
                    '__v' : 0
                  },
                  {
                    '_id' : '4546456',
                    'deleted' : false,
                    'deletedAt' : null,
                    'secureSpacePath' : [
                      '6148f40f6afa9a63cd46efbd'
                    ],
                    type: 'structure',
                    subStructureCount: 5,
                    'establishmentCount' : 4,
                    'phones' : [
                      '78458748'
                    ],
                    'activities' : [
                      '614a0f2228184e41118322a0'
                    ],
                    'name' : 'Structure 782',
                    'code' : 'STR-1',
                    'cif' : 'asd',
                    'parent' : '614a173828184e41118322a4',
                    'billingEmail' : 'structure1@yopmail.com',
                    'auditReportEmail' : 'structure1@yopmail.com',
                    'analyticalReportEmail' : 'structure1@yopmail.com',
                    'contactPerson' : {
                      'name' : 'Contact Person'
                    },
                    'owner' : '6148f40f6afa9a63cd46efc3',
                    'space' : '6148f40f6afa9a63cd46efbd',
                    'createdAt' : '2021-09-21T19:35:04.276Z',
                    'updatedAt' : '2021-09-21T19:35:04.276Z',
                    '__v' : 0,
                    children: [
                      {
                        '_id' : '546546546',
                        'deleted' : false,
                        'deletedAt' : null,
                        'secureSpacePath' : [
                          '6148f40f6afa9a63cd46efbd'
                        ],
                        'subStructureCount' : 0,
                        'establishmentCount' : 0,
                        'phones' : [
                          '78458748'
                        ],
                        'activities' : [
                          '614a0f2228184e41118322a0'
                        ],
                        type: 'establishment',
                        'name' : 'Structure 3',
                        'code' : 'STR-1',
                        'cif' : 'asd',
                        'parent' : '614a173828184e41118322a4',
                        'billingEmail' : 'structure1@yopmail.com',
                        'auditReportEmail' : 'structure1@yopmail.com',
                        'analyticalReportEmail' : 'structure1@yopmail.com',
                        'contactPerson' : {
                          'name' : 'Contact Person'
                        },
                        'owner' : '6148f40f6afa9a63cd46efc3',
                        'space' : '6148f40f6afa9a63cd46efbd',
                        'createdAt' : '2021-09-21T19:35:04.276Z',
                        'updatedAt' : '2021-09-21T19:35:04.276Z',
                        '__v' : 0
                      },
                      {
                        '_id' : '546546546',
                        'deleted' : false,
                        'deletedAt' : null,
                        'secureSpacePath' : [
                          '6148f40f6afa9a63cd46efbd'
                        ],
                        'subStructureCount' : 0,
                        'establishmentCount' : 0,
                        'phones' : [
                          '78458748'
                        ],
                        'activities' : [
                          '614a0f2228184e41118322a0'
                        ],
                        type: 'establishment',
                        'name' : 'Structure 3',
                        'code' : 'STR-1',
                        'cif' : 'asd',
                        'parent' : '614a173828184e41118322a4',
                        'billingEmail' : 'structure1@yopmail.com',
                        'auditReportEmail' : 'structure1@yopmail.com',
                        'analyticalReportEmail' : 'structure1@yopmail.com',
                        'contactPerson' : {
                          'name' : 'Contact Person'
                        },
                        'owner' : '6148f40f6afa9a63cd46efc3',
                        'space' : '6148f40f6afa9a63cd46efbd',
                        'createdAt' : '2021-09-21T19:35:04.276Z',
                        'updatedAt' : '2021-09-21T19:35:04.276Z',
                        '__v' : 0
                      },
                      {
                        '_id' : '546546546',
                        'deleted' : false,
                        'deletedAt' : null,
                        'secureSpacePath' : [
                          '6148f40f6afa9a63cd46efbd'
                        ],
                        'subStructureCount' : 0,
                        'establishmentCount' : 0,
                        'phones' : [
                          '78458748'
                        ],
                        'activities' : [
                          '614a0f2228184e41118322a0'
                        ],
                        type: 'establishment',
                        'name' : 'Structure 3',
                        'code' : 'STR-1',
                        'cif' : 'asd',
                        'parent' : '614a173828184e41118322a4',
                        'billingEmail' : 'structure1@yopmail.com',
                        'auditReportEmail' : 'structure1@yopmail.com',
                        'analyticalReportEmail' : 'structure1@yopmail.com',
                        'contactPerson' : {
                          'name' : 'Contact Person'
                        },
                        'owner' : '6148f40f6afa9a63cd46efc3',
                        'space' : '6148f40f6afa9a63cd46efbd',
                        'createdAt' : '2021-09-21T19:35:04.276Z',
                        'updatedAt' : '2021-09-21T19:35:04.276Z',
                        '__v' : 0
                      },
                      {
                        '_id' : '546546546',
                        'deleted' : false,
                        'deletedAt' : null,
                        'secureSpacePath' : [
                          '6148f40f6afa9a63cd46efbd'
                        ],
                        'subStructureCount' : 0,
                        'establishmentCount' : 0,
                        'phones' : [
                          '78458748'
                        ],
                        'activities' : [
                          '614a0f2228184e41118322a0'
                        ],
                        type: 'establishment',
                        'name' : 'Structure 3',
                        'code' : 'STR-1',
                        'cif' : 'asd',
                        'parent' : '614a173828184e41118322a4',
                        'billingEmail' : 'structure1@yopmail.com',
                        'auditReportEmail' : 'structure1@yopmail.com',
                        'analyticalReportEmail' : 'structure1@yopmail.com',
                        'contactPerson' : {
                          'name' : 'Contact Person'
                        },
                        'owner' : '6148f40f6afa9a63cd46efc3',
                        'space' : '6148f40f6afa9a63cd46efbd',
                        'createdAt' : '2021-09-21T19:35:04.276Z',
                        'updatedAt' : '2021-09-21T19:35:04.276Z',
                        '__v' : 0
                      }
                    ]
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        '_id' : '614a33e88ca74a544cf91195',
        'deleted' : false,
        'deletedAt' : null,
        'secureSpacePath' : [
          '6148f40f6afa9a63cd46efbd'
        ],
        subStructureCount: 0,
        'establishmentCount' : 0,
        'phones' : [
          '78458748'
        ],
        'activities' : [
          '614a0f2228184e41118322a0'
        ],
        type: 'structure',
        'name' : 'Sub Structure 4',
        'code' : 'STR-85',
        'cif' : 'asd',
        'parent' : '614a173828184e41118322a4',
        'billingEmail' : 'structure1@yopmail.com',
        'auditReportEmail' : 'structure1@yopmail.com',
        'analyticalReportEmail' : 'structure1@yopmail.com',
        'contactPerson' : {
          'name' : 'Contact Person'
        },
        'owner' : '6148f40f6afa9a63cd46efc3',
        'space' : '6148f40f6afa9a63cd46efbd',
        'createdAt' : '2021-09-21T19:35:04.276Z',
        'updatedAt' : '2021-09-21T19:35:04.276Z',
        '__v' : 0,
      },
      {
        '_id' : '614a33e88ca74a544cf91195',
        'deleted' : false,
        'deletedAt' : null,
        'secureSpacePath' : [
          '6148f40f6afa9a63cd46efbd'
        ],
        'subStructureCount' : 0,
        'establishmentCount' : 0,
        'phones' : [
          '78458748'
        ],
        'activities' : [
          '614a0f2228184e41118322a0'
        ],
        type: 'establishment',
        'name' : 'Structure 3',
        'code' : 'STR-1',
        'cif' : 'asd',
        'parent' : '614a173828184e41118322a4',
        'billingEmail' : 'structure1@yopmail.com',
        'auditReportEmail' : 'structure1@yopmail.com',
        'analyticalReportEmail' : 'structure1@yopmail.com',
        'contactPerson' : {
          'name' : 'Contact Person'
        },
        'owner' : '6148f40f6afa9a63cd46efc3',
        'space' : '6148f40f6afa9a63cd46efbd',
        'createdAt' : '2021-09-21T19:35:04.276Z',
        'updatedAt' : '2021-09-21T19:35:04.276Z',
        '__v' : 0
      }
    ]
  },
  {
    '_id' : '614a064eb1c8634021b54355',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '6149fd350fbf7c37624e840d'
    ],
    'name' : 'Structure 2',
    'code' : 'STR-2',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T16:20:30.142Z',
    'updatedAt': '2021-09-21T16:20:30.142Z',
    '__v' : 0
  },
  {
    '_id' : '614a173828184e41118322a4',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '6149fd350fbf7c37624e840d'
    ],
    'name' : 'Structure 2',
    'code' : 'STR-3',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T17:32:40.663Z',
    'updatedAt' : '2021-09-21T17:32:40.663Z',
    '__v' : 0,
    children : [
      {
        '_id' : '614a33e88ca74a544cf91195',
        'deleted' : false,
        'deletedAt' : null,
        'secureSpacePath' : [
          '6148f40f6afa9a63cd46efbd'
        ],
        'subStructureCount' : 0,
        'establishmentCount' : 0,
        'phones' : [
          '78458748'
        ],
        'activities' : [
          '614a0f2228184e41118322a0'
        ],
        'name' : 'Structure 3',
        'code' : 'STR-1',
        'cif' : 'asd',
        'parent' : '614a173828184e41118322a4',
        'billingEmail' : 'structure1@yopmail.com',
        'auditReportEmail' : 'structure1@yopmail.com',
        'analyticalReportEmail' : 'structure1@yopmail.com',
        'contactPerson' : {
          'name' : 'Contact Person'
        },
        'owner' : '6148f40f6afa9a63cd46efc3',
        'space' : '6148f40f6afa9a63cd46efbd',
        'createdAt' : '2021-09-21T19:35:04.276Z',
        'updatedAt' : '2021-09-21T19:35:04.276Z',
        '__v' : 0
      },
    ]
  },
  {
    '_id' : '614a33e88ca74a544cf91195',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 3',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a173828184e41118322a4',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:35:04.276Z',
    'updatedAt' : '2021-09-21T19:35:04.276Z',
    '__v' : 0
  },
  {
    '_id' : '614a353c0f096d6cf7b0c03a',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 4',
    'code' : 'STR-1',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:40:44.996Z',
    'updatedAt' : '2021-09-21T19:40:44.996Z',
    '__v' : 0
  },
  {
    '_id' : '614a35e0968f9c6f3b5c9081',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 4',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a353c0f096d6cf7b0c03a',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:43:28.340Z',
    'updatedAt' : '2021-09-21T19:43:28.340Z',
    '__v' : 0
  },
  {
    '_id' : '614a362a5640da6f827cbc73',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 4',
    'code' : 'STR-1',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:44:42.714Z',
    'updatedAt' : '2021-09-21T19:44:42.714Z',
    '__v' : 0
  },
  {
    '_id' : '614a36500ea8fb6fbeea41c8',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 7',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a362a5640da6f827cbc73',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:45:20.201Z',
    'updatedAt' : '2021-09-21T19:45:20.201Z',
    '__v' : 0
  },
  {
    '_id' : '614a371406eec670099a7c7a',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a36500ea8fb6fbeea41c8',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:48:36.271Z',
    'updatedAt' : '2021-09-21T19:48:36.271Z',
    '__v' : 0
  },
  {
    '_id' : '614a3aa4c4582471bd7d8c33',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a371406eec670099a7c7a',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:03:48.690Z',
    'updatedAt' : '2021-09-21T20:03:48.690Z',
    '__v' : 0
  },
  {
    '_id' : '614a3adb3665da71f3723638',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3aa4c4582471bd7d8c33',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:04:43.240Z',
    'updatedAt' : '2021-09-21T20:04:43.240Z',
    '__v' : 0
  },
  {
    '_id' : '614a3c196a747c72c438fad1',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3adb3665da71f3723638',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:10:01.998Z',
    'updatedAt' : '2021-09-21T20:10:01.998Z',
    '__v' : 0
  },
  {
    '_id' : '614a3c5fa7109c72f96bbd9e',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3c196a747c72c438fad1',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:11:11.733Z',
    'updatedAt' : '2021-09-21T20:11:11.733Z',
    '__v' : 0
  },
  {
    '_id' : '614a3c8f4b1c247324a02b95',
    'deleted' : true,
    'deletedAt' : '2021-09-21T20:14:27.701Z',
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3c5fa7109c72f96bbd9e',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:11:59.327Z',
    'updatedAt' : '2021-09-21T20:14:27.715Z',
    '__v' : 0
  },
  {
    '_id' : '614a3e263cf50a747a9f305d',
    'deleted' : true,
    'deletedAt' : '2021-09-21T20:19:36.104Z',
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3c8f4b1c247324a02b95',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:18:46.180Z',
    'updatedAt' : '2021-09-21T20:19:36.117Z',
    '__v' : 0
  },
  {
    '_id' : '614a4f920332f290cea57dc9',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3f0a9800d2769d51a7d1',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:33:06.282Z',
    'updatedAt' : '2021-09-21T21:33:06.282Z',
    '__v' : 0
  },
  {
    '_id' : '614a4ff92574b69175f03dba',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a4f920332f290cea57dc9',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:34:49.036Z',
    'updatedAt' : '2021-09-21T21:34:49.036Z',
    '__v' : 0
  },
  {
    '_id' : '614a5049ff129b9224d9aecf',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a4ff92574b69175f03dba',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:36:09.132Z',
    'updatedAt' : '2021-09-21T21:36:09.132Z',
    '__v' : 0
  },
  {
    '_id' : '614a50b0bb328f928ccd88aa',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a5049ff129b9224d9aecf',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:37:52.460Z',
    'updatedAt' : '2021-09-21T21:37:52.460Z',
    '__v' : 0
  },
  {
    '_id' : '614a50f4e7545892ec35a3d2',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a50b0bb328f928ccd88aa',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:39:00.399Z',
    'updatedAt' : '2021-09-21T21:39:00.399Z',
    '__v' : 0
  }
];

export const flatDataClients = [
  {    '_id' : '6149fdb2ee2d6b38410d68cb',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '6149fd350fbf7c37624e840d'
    ],
    type: 'structure',
    'name' : 'Structure 1',
    'code' : 'STR-1',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T15:43:46.063Z',
    'updatedAt' : '2021-09-21T15:43:46.063Z',
    '__v' : 0,
    subStructureCount: 10,
  },
  {
    '_id' : '614a064eb1c8634021b54355',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '6149fd350fbf7c37624e840d'
    ],
    'name' : 'Structure 2',
    'code' : 'STR-2',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T16:20:30.142Z',
    'updatedAt': '2021-09-21T16:20:30.142Z',
    '__v' : 0
  },
  {
    '_id' : '614a173828184e41118322a4',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '6149fd350fbf7c37624e840d'
    ],
    'name' : 'Structure 2',
    'code' : 'STR-3',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T17:32:40.663Z',
    'updatedAt' : '2021-09-21T17:32:40.663Z',
    '__v' : 0,
  },
  {
    '_id' : '614a33e88ca74a544cf91195',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 3',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a173828184e41118322a4',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:35:04.276Z',
    'updatedAt' : '2021-09-21T19:35:04.276Z',
    '__v' : 0
  },
  {
    '_id' : '614a353c0f096d6cf7b0c03a',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 4',
    'code' : 'STR-1',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:40:44.996Z',
    'updatedAt' : '2021-09-21T19:40:44.996Z',
    '__v' : 0
  },
  {
    '_id' : '614a35e0968f9c6f3b5c9081',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 4',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a353c0f096d6cf7b0c03a',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:43:28.340Z',
    'updatedAt' : '2021-09-21T19:43:28.340Z',
    '__v' : 0
  },
  {
    '_id' : '614a362a5640da6f827cbc73',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 4',
    'code' : 'STR-1',
    'cif' : 'asd',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:44:42.714Z',
    'updatedAt' : '2021-09-21T19:44:42.714Z',
    '__v' : 0
  },
  {
    '_id' : '614a36500ea8fb6fbeea41c8',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 7',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a362a5640da6f827cbc73',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:45:20.201Z',
    'updatedAt' : '2021-09-21T19:45:20.201Z',
    '__v' : 0
  },
  {
    '_id' : '614a371406eec670099a7c7a',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a36500ea8fb6fbeea41c8',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T19:48:36.271Z',
    'updatedAt' : '2021-09-21T19:48:36.271Z',
    '__v' : 0
  },
  {
    '_id' : '614a3aa4c4582471bd7d8c33',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a371406eec670099a7c7a',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:03:48.690Z',
    'updatedAt' : '2021-09-21T20:03:48.690Z',
    '__v' : 0
  },
  {
    '_id' : '614a3adb3665da71f3723638',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3aa4c4582471bd7d8c33',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:04:43.240Z',
    'updatedAt' : '2021-09-21T20:04:43.240Z',
    '__v' : 0
  },
  {
    '_id' : '614a3c196a747c72c438fad1',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3adb3665da71f3723638',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:10:01.998Z',
    'updatedAt' : '2021-09-21T20:10:01.998Z',
    '__v' : 0
  },
  {
    '_id' : '614a3c5fa7109c72f96bbd9e',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3c196a747c72c438fad1',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:11:11.733Z',
    'updatedAt' : '2021-09-21T20:11:11.733Z',
    '__v' : 0
  },
  {
    '_id' : '614a3c8f4b1c247324a02b95',
    'deleted' : true,
    'deletedAt' : '2021-09-21T20:14:27.701Z',
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3c5fa7109c72f96bbd9e',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:11:59.327Z',
    'updatedAt' : '2021-09-21T20:14:27.715Z',
    '__v' : 0
  },
  {
    '_id' : '614a3e263cf50a747a9f305d',
    'deleted' : true,
    'deletedAt' : '2021-09-21T20:19:36.104Z',
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3c8f4b1c247324a02b95',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T20:18:46.180Z',
    'updatedAt' : '2021-09-21T20:19:36.117Z',
    '__v' : 0
  },
  {
    '_id' : '614a4f920332f290cea57dc9',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a3f0a9800d2769d51a7d1',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:33:06.282Z',
    'updatedAt' : '2021-09-21T21:33:06.282Z',
    '__v' : 0
  },
  {
    '_id' : '614a4ff92574b69175f03dba',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a4f920332f290cea57dc9',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:34:49.036Z',
    'updatedAt' : '2021-09-21T21:34:49.036Z',
    '__v' : 0
  },
  {
    '_id' : '614a5049ff129b9224d9aecf',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a4ff92574b69175f03dba',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:36:09.132Z',
    'updatedAt' : '2021-09-21T21:36:09.132Z',
    '__v' : 0
  },
  {
    '_id' : '614a50b0bb328f928ccd88aa',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a5049ff129b9224d9aecf',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:37:52.460Z',
    'updatedAt' : '2021-09-21T21:37:52.460Z',
    '__v' : 0
  },
  {
    '_id' : '614a50f4e7545892ec35a3d2',
    'deleted' : false,
    'deletedAt' : null,
    'secureSpacePath' : [
      '6148f40f6afa9a63cd46efbd'
    ],
    'subStructureCount' : 0,
    'establishmentCount' : 0,
    'phones' : [
      '78458748'
    ],
    'activities' : [
      '614a0f2228184e41118322a0'
    ],
    'name' : 'Structure 8',
    'code' : 'STR-1',
    'cif' : 'asd',
    'parent' : '614a50b0bb328f928ccd88aa',
    'billingEmail' : 'structure1@yopmail.com',
    'auditReportEmail' : 'structure1@yopmail.com',
    'analyticalReportEmail' : 'structure1@yopmail.com',
    'contactPerson' : {
      'name' : 'Contact Person'
    },
    'owner' : '6148f40f6afa9a63cd46efc3',
    'space' : '6148f40f6afa9a63cd46efbd',
    'createdAt' : '2021-09-21T21:39:00.399Z',
    'updatedAt' : '2021-09-21T21:39:00.399Z',
    '__v' : 0
  }
];
