/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/12/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
// import {months} from '@/modules/audits/contants/months';
import {useGetOneAuditorForAudit} from '@/modules/users/hooks/useUsers';
import {useGetOneTemplateForAudit} from '@/modules/templates/hooks/audits/useTemplates';
import {useGetOneClientForAudit} from '@/modules/clients/hooks/useClients';
import {useGetOneEstablishmentForAudit} from '@/modules/clients/hooks/useClientsEstablishment';
import {useGetOneServiceForAudit} from '@/modules/settings/hooks/useServices';
import {useGetOne} from '@/modules/settings/hooks/useNomenclaturesAreaType';

const AuditsSummary = ({form}) => {
  const {t} = useTranslation('audits');

  const values = form?.getFieldsValue(true);

  const {data: auditor} = useGetOneAuditorForAudit(values?.auditor);
  const {data: template} = useGetOneTemplateForAudit(typeof values?.template === 'string' ? values?.template : values?.template?._id);
  const {data: clientData} = useGetOneClientForAudit(values?.client);
  const {data: establishmentData} = useGetOneEstablishmentForAudit(values?.establishment);
  const {data: serviceData} = useGetOneServiceForAudit(values?.service);
  const {data: areaAccessData} = useGetOne(values?.areaAccess);

  return (
    <div className="w-full">
      <Row gutter={16} className="my-4">
        <Col span={7}>
          <strong>{t('form.client')}:</strong>
        </Col>

        <Col span={17}>
          {clientData?.name}
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col span={7}>
          <strong>{t('form.establishment')}:</strong>
        </Col>

        <Col span={17}>
          {establishmentData?.name}
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col span={7}>
          <strong>{t('form.service')}:</strong>
        </Col>

        <Col span={17}>
          {serviceData?.name}
        </Col>
      </Row>

      <Row gutter={16} className="my-4">
        <Col span={7}>
          <strong>{t('form.areaAccess')}:</strong>
        </Col>

        <Col span={17}>
          {areaAccessData?.name}
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col span={7}>
          <strong>{t('form.yearAndMonth')}:</strong>
        </Col>

        <Col span={17}>
          {values?.startDate ? moment(values?.startDate).format('MM/YYYY') : null}
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col span={7}>
          <strong>{t('form.date')}:</strong>
        </Col>

        <Col span={17}>
          {values?.date?.length ? `${moment(values?.date?.[0]).format('ll')} / ${moment(values?.date?.[1]).format('ll')}` : null}
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col span={7}>
          <strong>{t('form.auditor')}:</strong>
        </Col>

        <Col span={17}>
          {auditor?.fullName}
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col span={7}>
          <strong>{t('form.template')}:</strong>
        </Col>

        <Col span={17}>
          {template?.name}
        </Col>
      </Row>
    </div>
  );
};

AuditsSummary.propTypes = {
  form: PropTypes.any
};

export default memo(AuditsSummary);
