import React, {createContext, useContext,  useEffect, useMemo} from 'react';
import {ApiClientService, useAuth, useUser} from '@dofleini/security';
import {isSpaceAdmin, isSpaceOwner} from '@/utils/utils';
import {useQueryClient} from 'react-query';
import TokenService from '@/contexts/TokenService';

const SessionControlContext = createContext();

const REFRESH_TOKEN_KEY = '__refresh_token__';
const SPACE_KEY = '__space__';
const EXPIRE_DATE_KEY = '__expire_date__';

export const setAuthData = (data = {}) => {
  const {accessToken, refreshToken, space} = data;
  window.localStorage.setItem(ApiClientService.ACCESS_TOKKEN_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  window.localStorage.setItem(SPACE_KEY, space);
  window.localStorage.setItem(EXPIRE_DATE_KEY, new Date().toISOString());
};


export const handleUserResponse = ({data}) => {
  setAuthData(data);
  return data;
};

const SessionControlProvider = (props) => {
  const {user} = useUser();
  const userPermissions = user?.permissions;
  const {logout} = useAuth();
  const queryClient = useQueryClient();

  const isAdmin = useMemo(() => {
    return isSpaceAdmin(user?.roles?.map(el => el?.role));
  }, [user?.roles]);

  const isOwner = useMemo(() => {
    return isSpaceOwner(user?.roles?.map(el => el?.role));
  }, [user?.roles]);

  // const refresh = useCallback(() => {
  //   !!user && refreshToken(logout).then(({ data }) => {
  //     setAuthData(data);
  //     setExpirationTime(getExpirationTime());
  //   });
  // }, [user, logout]);

  // useEffect(() => {
  //   if (!user) {
  //     return;
  //   }
  //
  //   const predictiveDate = moment(expirationTime).add(authentication.tokenExpirationTime, 'milliseconds');
  //
  //   const difference = predictiveDate - moment();
  //
  //   if (difference < 60000) {
  //     refreshToken(logout);
  //     return;
  //   }
  //
  //   const timer = setTimeout(() => {
  //     refreshToken(logout);
  //   }, difference - 60000);
  //
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [expirationTime, user, logout]);

  useEffect(() => {
    ApiClientService.onError(async (error) => {
      if (error.status === 401 && ApiClientService.ACCESS_TOKEN_KEY) {
        await logout().then();
        queryClient.invalidateQueries('user-me');
      }
    });
  }, [logout, queryClient]);
  return (
    <SessionControlContext.Provider value={
      {
        refresh: TokenService.refresh,
        userPermissions,
        user,
        isAdmin,
        isOwner
      }
    } {...props}/>
  );
};

const useSessionControl = () => {
  const context = useContext(SessionControlContext);
  if (context === undefined) {
    throw new Error('useSessionControl must be used within a SessionControlProvider');
  }
  const {refresh, userPermissions, user, isAdmin, isOwner} = context;


  return {refresh, userPermissions, user, isAdmin, isOwner};
};

SessionControlProvider.propTypes = {};


export {SessionControlProvider, useSessionControl};
