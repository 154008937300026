/**
 * @author Geider Arevalo
 * @version v0.0.1
 * @date  25/01/2022
 */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.less';
import { Card} from 'antd';
import ViewEditor from '@/components/Editor/viewEditor';
import Incidents from '../../Incidents';

const Text = ({question, isIncidence, title, idx, isCover}) => {
  const value = useMemo(() => {
    if (question?.type === 'text' && question?.text?.answerType === 'PARAGRAPH')
      return question?.answer?.templateAnswer?.paragraph;
    return question?.answer?.templateAnswer?.text;
  }, [question?.answer?.templateAnswer?.paragraph, question?.answer?.templateAnswer?.text, question?.text?.answerType, question?.type]);

  if(isIncidence){
    return <Card
      key={idx}
      className={classNames(styles.templateTextCard, idx !==0 && 'mt-2')}
      bodyStyle={{padding: '6px'}}
    >
      <div>
        {title}
      </div>
      <div className={'font-bold'}>{question?.name || question?.label}</div>
      <div className="mt-1">
        {question?.text?.answerType === 'PARAGRAPH'
          ? <ViewEditor value={value} />
          : <span>{value}</span>}
      </div>
    </Card>;
  }

  if(isCover){
    return (
      <Card
        className={styles.templateTextCard}
        bodyStyle={{padding: '6px'}}
        id={'question-view-' + question?._id}
      >
        <div className="mb-3">
          {question?.label}
        </div>
        <div className="custom-form-big">
          {question?.value || 'N/A'}
        </div>
      </Card>
    );
  }

  return (
    <Card
      className={styles.templateTextCard}
      bodyStyle={{padding: '6px'}}
      id={'question-view-' + question?._id}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">
        {!value || value === '' ? 'N/A' :
          question?.text?.answerType === 'PARAGRAPH'
            ? <ViewEditor value={value} />
            : <span>{value}</span>}
      </div>
      <Incidents data={question?.issuesAudit} question={question} hasIssues={question?.issuesAudit?.length}/>
    </Card>
  );
};

Text.propTypes = {
  question: PropTypes.object,
  title: PropTypes.string,
  idx: PropTypes.number,
  isIncidence: PropTypes.bool,
  isCover: PropTypes.bool,
};

Text.defaultProps = {
  question: {},
};

export default memo(Text);
