import {EntityApiService, ApiClientService} from '@dofleini/security';

class TemplatesApiService extends EntityApiService {

    addField = (params) => {
      return ApiClientService.post(this.getPath(`/${params.sectionId}/field/add`), params);
    };

    updateField = (params) => {
      return ApiClientService.patch(this.getPath(`/${params.sectionId}/field/${params?.fieldId}/update`), params);
    };

    deleteField = (params) => {
      return ApiClientService.delete(this.getPath(`/${params.sectionId}/field/${params?.fieldId}/remove`), params);
    };

    changeFieldOrder= ({sectionId, fieldId, ...params}) => {
      return ApiClientService.patch(this.getPath(`/${sectionId}/field/${fieldId}/order`), params);
    }

    addQuestion = (params) => {
      return ApiClientService.post(this.getPath(`/${params.sectionId}/question/add`), params);
    };

    updateQuestion = (params) => {
      return ApiClientService.patch(this.getPath(`/${params.sectionId}/question/${params?.questionId}/update`), params);
    };

    deleteQuestion = (params) => {
      return ApiClientService.delete(this.getPath(`/${params.sectionId}/question/${params?.questionId}/remove`), params);
    };

    changeSectionOrder= ({sectionId, ...params}) => {
      return ApiClientService.patch(this.getPath(`/${sectionId}/order`), params);
    }

    changeQuestionOrder= ({sectionId, questionId, ...params}) => {
      return ApiClientService.patch(this.getPath(`/${sectionId}/question/${questionId}/order`), params);
    }

}

export default new TemplatesApiService('/ms-core/api/sections');
