import {i18n} from '@/settings';

export const months = [
  {
    label: i18n.t('miscellaneous:months.January'),
    value: 1
  },
  {
    label: i18n.t('miscellaneous:months.February'),
    value: 2
  },
  {
    label: i18n.t('miscellaneous:months.March'),
    value: 3
  },
  {
    label: i18n.t('miscellaneous:months.April'),
    value: 4
  },
  {
    label: i18n.t('miscellaneous:months.May'),
    value: 5
  },
  {
    label: i18n.t('miscellaneous:months.June'),
    value: 6
  },
  {
    label: i18n.t('miscellaneous:months.July'),
    value: 7
  },
  {
    label: i18n.t('miscellaneous:months.August'),
    value: 8
  },
  {
    label: i18n.t('miscellaneous:months.September'),
    value: 9
  },
  {
    label: i18n.t('miscellaneous:months.October'),
    value: 10
  },
  {
    label: i18n.t('miscellaneous:months.November'),
    value: 11
  },
  {
    label: i18n.t('miscellaneous:months.December'),
    value: 12
  }
];
