/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Image, Row} from 'antd';
import {useTranslation} from 'react-i18next';

const CardEvidence = ({data}) => {
  const {t} = useTranslation('templates');

  return (<Card
    className={'mb-2 rounded'}
    bodyStyle={{padding: '6px'}}
  >
    <Row gutter={8}>
      <Col flex="auto">
        {/*<span className={'block'}>{t('details.observation')}</span>*/}
        {!data?.observation || data?.observation === '' ? 'N/A' : data?.observation}
      </Col>
      {data?.url && <Col flex="120px">
        <Image
          src={data?.pdfThumb ? data?.pdfThumb : data?.url}
          alt="evidence"
          height={100}
          style={{width: '100%'}}
          preview={{
            src: `${data?.url}`,
            mask: <span>{t('preview')}</span>
          }}
        />
      </Col>}
    </Row>
  </Card>);
};

CardEvidence.propTypes = {
  data: PropTypes.object
};

export default memo(CardEvidence);
