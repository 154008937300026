/**
 * @author Angel Labrada Massó
 * @version v0.0.1
 * @date  03/02/2022
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import { Card } from 'antd';
import Editor from '@/components/Editor';

const TemplateDetailObjective = ({question}) => {

  return (
    <Card
      className={styles.templateTextCard}
      id={'question-view-'+question?._id}
    >
      <div className="mb-3">
        {question?.name || question?.label}
      </div>
      <div className="custom-form-big">

        <Editor />
      </div>
    </Card>
  );
};

TemplateDetailObjective.propTypes = {
  runAudit: PropTypes.bool,
  sectionId: PropTypes.string,
  question: PropTypes.object,
  isCover: PropTypes.bool,
};
TemplateDetailObjective.defaultProps = {
  question: {},
};

export default memo(TemplateDetailObjective);


/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Detail Audit Card components in a detail drawer
 * @param {String} value this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderTemplateDetailObjective = (value, entity) => {
  return (
    <TemplateDetailObjective entity={entity}/>
  );
};
