/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 27/10/21
 */
import React, {memo} from 'react';
import {Skeleton} from 'antd';
import styles from './index.less';
import classNames from 'classnames';

const DirectoryLoading = () => {
  return (
    <div className={classNames('flex items-start justify-between', styles.cardLoadingDirectory)}>
      <div className={classNames('flex items-start justify-start mr-3')}>
        <div className={classNames('flex items-center justify-start mr-2', styles.loading)}>
          <Skeleton.Avatar active={true} size={32} shape="square" style={{backgroundColor: '#E4E6E8', borderRadius: '5px'}}/>
        </div>

        <div className="flex items-center justify-start flex-col">
          <div className="w-full flex font-bold wrapper-loading-name flex-col">
            <Skeleton.Input className="first-skeleton" style={{ borderRadius: '5px' }} active={true} size={20} block={false} />
            <Skeleton.Input className="second-skeleton" style={{ borderRadius: '5px' }} active={true} size={20} block={false} />
          </div>
        </div>
      </div>

      <div className={classNames('flex items-center justify-start mr-2')} style={{ marginTop: '0.1rem', opacity: '0.5' }}>
        <Skeleton.Input style={{ width: 50, borderRadius: '5px' }} active={true} size={20} block={false} />
      </div>
    </div>
  );
};

DirectoryLoading.propTypes = {};

export default memo(DirectoryLoading);
