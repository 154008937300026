/**
 * @author Ricardo Comendador
 * @since v0.0.1
 * @date 28/11/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import {CheckOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import styles from './index.less';
import { Scrollbars } from 'react-custom-scrollbars';
import {useTranslation} from 'react-i18next';

const BookmarksPreview = ({activeSection, setActiveSection, data, dataCover, onScroll}) => {

  const {t} = useTranslation('templates');

  return (
    <div className="w-full h-full">
      <Scrollbars style={{ height: 'calc(100%)', width: '90%' }} onScroll={onScroll}>
        <ul className={styles.bookmarksList}>
          {dataCover?.length === 0
            ?
            <div className="w-full h-full flex items-center justify-center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginLeft: '-20px' }}/>
            </div>
            :
            dataCover?.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={classNames(styles.bookmarksItem, activeSection?._id === item?._id ? styles.activeSection : '')}
                  onClick={() => {
                    setActiveSection(item);
                  }}
                >
                  {activeSection?._id === item?._id
                    ? <CheckOutlined style={{ fontSize: '0.75rem' }}/>
                    : <div className={styles.dot}/>
                  }
                  <span className="ml-2 w-full">{t(item?.title.capitalize())}</span>
                </li>
              );
            })
          }
          {data?.length === 0
            ? <div className="w-full h-full flex items-center justify-center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginLeft: '-20px' }}/>
            </div>
            :
            data?.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={classNames(styles.bookmarksItem, activeSection?._id === item?._id ? styles.activeSection : '')}
                  onClick={() => {
                    setActiveSection(item);
                  }}
                >
                  {activeSection?._id === item?._id
                    ? <CheckOutlined style={{ fontSize: '0.75rem' }}/>
                    : <div className={styles.dot}/>
                  }
                  <span className="ml-2 w-full">{t(item?.title.capitalize())}</span>
                </li>
              );
            }
            )}
        </ul>
      </Scrollbars>
    </div>
  );
};

BookmarksPreview.propTypes = {
  activeSection: PropTypes.object,
  setActiveSection: PropTypes.func,
  data: PropTypes.array,
  dataCover: PropTypes.array,
  onScroll: PropTypes.func,
};

export default memo(BookmarksPreview);
