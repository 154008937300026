/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 5/2/22
 */
import {useMemo} from 'react';
import moment from 'moment';

export const useRealTimeFromNow = (currentTemplate) => {

  return useMemo(() => {
    const diffSeconds = moment().diff(currentTemplate?.updatedAt, 'seconds');
    const diffMinutes = moment().diff(currentTemplate?.updatedAt, 'minutes');
    const diffHours = moment().diff(currentTemplate?.updatedAt, 'hours');
    const diffDays = moment().diff(currentTemplate?.updatedAt, 'days');
    const diffMonths = moment().diff(currentTemplate?.updatedAt, 'months');
    const diffYears = moment().diff(currentTemplate?.updatedAt, 'years');

    if (diffSeconds < 60) {
      return moment(currentTemplate?.updatedAt).startOf('second').fromNow();
    }
    if (diffMinutes < 60) {
      return moment(currentTemplate?.updatedAt).startOf('minute').fromNow();
    }
    if (diffHours > 0 && diffHours < 24) {
      return moment(currentTemplate?.updatedAt).startOf('hour').fromNow();
    }
    if (diffDays > 0 && diffDays < 30) {
      return moment(currentTemplate?.updatedAt).startOf('day').fromNow();
    }
    if (diffMonths > 0 && diffMonths < 12) {
      return moment(currentTemplate?.updatedAt).startOf('month').fromNow();
    }
    if (diffYears > 1) {
      return (moment(currentTemplate?.updatedAt).startOf('year').fromNow());
    }
    return moment(currentTemplate?.updatedAt).startOf('minute').fromNow();
  }, [currentTemplate?.updatedAt]);
};
