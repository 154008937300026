import {useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import NomenclaturesAreaTypeApiService from '@/modules/settings/services/NomenclaturesAreaTypeApiService';
import {CACHE_KEY_NOMENCLATURES_AREA_TYPE} from '@/modules/settings/constants/nomenclaturesAreaType';
import {fields} from '@/modules/settings/constants/nomenclaturesAreaType.form';
import {useQuery} from 'react-query';

export const useGetOneNomenclaturesAreaType = createGetOneHook(NomenclaturesAreaTypeApiService, 'id', CACHE_KEY_NOMENCLATURES_AREA_TYPE);
export const useFields = createFieldsHook('nomenclaturesAreaType', fields);

export const useGetOne = (areaTypeId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(areaTypeId)};
  }, [areaTypeId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_NOMENCLATURES_AREA_TYPE, areaTypeId], async () => {
    const {data} = await NomenclaturesAreaTypeApiService.getOneAreaType({areaTypeId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};
