/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 17/11/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './index.less';
import {Tooltip} from 'antd';

const stopPropagation = e => e?.stopPropagation();

const CategoryName= ({record, isItem, setSelected, onClick}) => {

  return (
    <>
      <span
        onClick={(e) => {
          if(!isItem){
            stopPropagation(e);
            setSelected(record);
            onClick(record);
          }
        }}
        className={'inline-flex items-center text-left w-full h-full justify-between'}
      >
        <Tooltip title={record?.name}>
          <div className={classNames(style.contentEditable, 'h-8  w-full flex flex-start')}
          >
            {record?.name}
          </div>
        </Tooltip>
      </span>
    </>

  );
};

CategoryName.propTypes = {
  editable: PropTypes.bool,
  key: PropTypes.string,
  record: PropTypes.object,
  field: PropTypes.string,
  value: PropTypes.string,
  onChangeName: PropTypes.func,
  isItem: PropTypes.bool,
  setSelected: PropTypes.func,
  onClick: PropTypes.func,
  setError: PropTypes.func
};

export default memo(CategoryName);

