/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 18/9/21
 */
import React, {memo} from 'react';
// import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import './index.less';

/** Images */
// import melia from '../../assets/images/melia.png';
// import occidental from '../../assets/images/occidental.png';
// import ihg from '../../assets/images/ihg.png';

const WelcomeLeftSidebar = () => {
  const {t} = useTranslation('common');
  return (
    <div className="w-full h-full flex flex-col items-center justify-between wrapper-welcome-left-sidebar">
      <div className="welcome-header">
        <div className="logo mt-2"><Logo /></div>
      </div>

      <div className="welcome-content w-full flex flex-col items-center justify-center">
        <div className="download-app my-8 flex items-center justify-center">{t('welcomeSidebar.appBioaudit')}</div>
        <div className="title text-center mb-5">{t('welcomeSidebar.infoI')}</div>
        <div className="description text-center mb-5">{t('welcomeSidebar.infoII')}</div>
        <div className="request-audit mb-5">
          <Button className="btn-request-audit" type="primary" size="large" shape="round">{t('welcomeSidebar.requestA')}  <span className="ml-1" style={{ fontSize: '0.9rem', fontWeight: 900 }}>{' '}{t('welcomeSidebar.audit')}</span></Button>
        </div>
      </div>

      <div className="welcome-footer">
        {/*<div className="trusty mb-3">{t('welcomeSidebar.trusty')}</div>*/}
        {/*<div className="trusty-companies w-full flex items-center justify-between">*/}
        {/*  <img src={melia} alt="Melia"/>*/}
        {/*  <img src={occidental} alt="Occidental"/>*/}
        {/*  <img src={ihg} alt="IHG"/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

WelcomeLeftSidebar.propTypes = {

};

export default memo(WelcomeLeftSidebar);
