import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { USER_STATUS } from '@/modules/admin/contants/user.status';
import UserStatus from '@/modules/admin/components/UserStatus';
import EntityMenu from '@/components/EntityMenu';

const activeOption = {
  title: <UserStatus status={USER_STATUS.ACTIVE} full help/>,
  action: USER_STATUS.ACTIVE,
  style: { minWidth: 150 }
};

const lockOption = {
  title: <UserStatus status={USER_STATUS.LOCK} full help/>,
  action: USER_STATUS.LOCK,
};

const FieldUserStatus = ({ value, onChange }) => {
  
  const options = useMemo(() => {
    switch (value) {
      case USER_STATUS.ACTIVE:
        return [lockOption];
      case USER_STATUS.LOCK:
        return [activeOption];
      default:
        return [
          activeOption,
          lockOption,
        ];
    }
  }, [value]);
  
  const handleClick = useCallback(({ action }) => onChange(action), [onChange]);
  
  return (
    <EntityMenu options={options} translate={'admin'} onClick={handleClick} mapOptions={false} placement="bottomLeft">
      <UserStatus status={value} className="cursor-pointer p-1 w-max"/>
    </EntityMenu>
  );
};

FieldUserStatus.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

FieldUserStatus.defaultProps = {};

export default memo(FieldUserStatus);

// eslint-disable-next-line react/prop-types
export const renderStatusSelector = props => {
  return <FieldUserStatus {...props}/>;
};
