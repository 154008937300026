import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesTemplatesCategoryApiService from '@/modules/settings/services/NomenclaturesTemplatesCategoryApiService';
import {useGetOneNomenclaturesTemplatesCategory} from '@/modules/settings/hooks/useNomenclaturesTemplatesCategory';

const SelectTemplateCategory = ({ value, onChange, ...props }) => {

  return (
    <SelectElementCmp
      queryKey={'incidents-categories-select'}
      service={NomenclaturesTemplatesCategoryApiService}
      customMethod={'/ms-core/api/categories/search-categories-subcategories'}
      additionalParams={{type: 'ALL'}}
      includeValue={''}
      value={typeof value === 'object' ? value?.name : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      {...props}
    />
  );
};

SelectTemplateCategory.propTypes = {
  value: PropTypes.string || PropTypes.object,
  onChange: PropTypes.func,
  filters: PropTypes.object
};
SelectTemplateCategory.defaultProps = {
  entity: {},
};

export default memo(SelectTemplateCategory);

const CategoryView = ({value}) => {

  const {data} = useGetOneNomenclaturesTemplatesCategory({id: value});

  return (
    <div className={'italic text-primary'}>
      {data?.data?.name || 'N/A'}
    </div>
  );
};

CategoryView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table
 * */
export const renderTemplateCategory = (text) => {
  return (
    <CategoryView value={text}/>
  );
};
