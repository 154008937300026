/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 17/6/22
 */
import React, {createContext, useContext, useState} from 'react';

const AuditWizardContext = createContext({
  templateId: '',
  setTemplateId: val => val
});

const useAuditWizard = () => {
  const context = useContext(AuditWizardContext);
  if (context === undefined) {
    throw new Error('useAuditWizard must be used within a AuditWizardProvider');
  }

  return context;
};

const AuditWizardProvider = ({ ...props }) => {

  const [templateId, setTemplateId] = useState('');

  return (
    <AuditWizardContext.Provider
      value={{
        templateId,
        setTemplateId
      }}
      {...props}
    />
  );
};

export { useAuditWizard, AuditWizardProvider };
