/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Divider, Row, Col} from 'antd';
import {useTranslation} from 'react-i18next';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesPaymentTypeApiService from '@/modules/settings/services/NomenclaturesPaymentTypeApiService';
import {useGetOne} from '@/modules/settings/hooks/useNomenclaturesPaymentType';

const ClientCustomPaymentType = ({value, filters, onChange, isViewMode = false, ...props}) => {
  const {t} = useTranslation('clients');

  return (
    <div className="mt-2">
      <div>{t('form.taxData')}</div>
      <Divider style={{ marginBottom: '1.2rem', marginTop: '0.4rem' }}/>

      <div className={isViewMode ? 'flex flex-row' : 'flex flex-col'}>
        <div id="payment-id" className="mb-2 mr-2 font-black">{t('form.paymentType')}{isViewMode && ':'}</div>
        <Row>
          <Col span={isViewMode ? 24 : 12}>
            <SelectElementCmp
              queryKey={'payment-types'}
              filters={filters}
              containerId={'payment-id'}
              service={NomenclaturesPaymentTypeApiService}
              includeValue={''}
              value={typeof value === 'object' ? value?._id : value}
              onChange={onChange}
              sort={{code: 1}}
              queryConfig={{refetchOnWindowFocus: false}}
              className="custom-select"
              canAdd
              {...props}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

ClientCustomPaymentType.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  isViewMode: PropTypes.bool,
};

export default memo(ClientCustomPaymentType);

const ClientCustomPaymentTypeView = ({value}) => {
  const {t} = useTranslation('clients');
  const {data: paymentType} = useGetOne(value);

  return (
    <div className="flex flex-row">
      <div className="mb-2 mr-2 font-black">{t('form.paymentType')}:</div>
      <div className="flex">{paymentType?.name || 'N/A'}</div>
    </div>
  );
};

ClientCustomPaymentTypeView.propTypes = {
  value: PropTypes.string,
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderCustomPaymentType= (text) => {
  return (
    <ClientCustomPaymentTypeView value={text} />
  );
};
