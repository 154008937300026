/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 20/12/21
 */
import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import CategoryTree from '@/modules/audits/components/AuditsSelectTemplateCell/components/CategoryTree';
import {CACHE_KEY_LIST_TEMPLATES} from '@/modules/templates/contants/audits/templates';
import {FilterProvider} from '@/contexts/FilterContext';
import TemplateList from '@/modules/audits/components/AuditsSelectTemplateCell/components/TemplateList';

const AuditsSelectTemplateCell = ({value, onChange}) => {
  const [currentTemplate, setCurrentTemplate] = useState(value?.parent);
  const [currentCategory, setCurrentCategory] = useState();

  return (
    <FilterProvider id={CACHE_KEY_LIST_TEMPLATES}>
      <Row gutter={16}>
        <Col span={8}>
          <CategoryTree setCurrentCategory={setCurrentCategory} />
        </Col>
        <Col span={16}>
          <TemplateList
            currentCategory={currentCategory}
            currentTemplate={currentTemplate}
            setCurrentTemplate={setCurrentTemplate}
            value={value}
            onChange={onChange}
          />
        </Col>
      </Row>
    </FilterProvider>
  );
};

AuditsSelectTemplateCell.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default memo(AuditsSelectTemplateCell);
