import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import {useGetOneNomenclaturesTemplatesCategory} from '@/modules/settings/hooks/useNomenclaturesTemplatesCategory';

const TemplatesNameCell = ({value, initialValues}) => {

  const {data} = useGetOneNomenclaturesTemplatesCategory({id: initialValues?.category});

  return (
    <div className="flex items-center justify-start">
      <div className={'row-flex'}>
        <span className={styles.detailTemplateTitle}> {value}</span>
        <p className={'font-normal italic text-primary mb-1 lg:mb-0'}>
          {data?.data?.name}
        </p>
      </div>
    </div>
  );
};

TemplatesNameCell.propTypes = {
  value: PropTypes.string,
  initialValues: PropTypes.object,
};
TemplatesNameCell.defaultProps = {
  entity: {},
};

export default memo(TemplatesNameCell);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the TemplatesNameCell component in a table cell
 * @param {string} value this is the row instance
 * @param {object} form this is the row instance
 * @param {object} initialValues this is the row instance
 * */
export const renderTemplatesName = (value,form,initialValues) => {

  return (
    <TemplatesNameCell value={value} initialValues={initialValues}/>
  );
};

