import React, {createContext, useContext, useState} from 'react';
import {I18nextProvider} from 'react-i18next';
import PropTypes from 'prop-types';
import {i18nSinglePage as i18n} from '@/settings';
import es from 'antd/es/locale/es_ES';
import en from 'antd/es/locale/en_US';
import {ConfigProvider} from 'antd';

const AuditReportInternationalizationContext = createContext();

const ANT_LOCALE = {
  es,
  en,
};

function AuditReportInternationalizationProvider({children, id}) {
  const [locale, setLocale] = useState(i18n.language);

  const validateMessages = i18n.t('validateMessages', { returnObjects: true });

  return (
    <AuditReportInternationalizationContext.Provider value={{
      locale,
      setLocale,
      id,
    }}>

      <I18nextProvider i18n={i18n} id={id}>
        <ConfigProvider locale={ANT_LOCALE[locale]}  form={{ validateMessages }} id={id}>
          {children}
        </ConfigProvider>
      </I18nextProvider>
    </AuditReportInternationalizationContext.Provider>
  );
}

function useAuditReportInternationalization() {
  const context = useContext(AuditReportInternationalizationContext);
  if (context === undefined) {
    throw new Error('useAuditReportInternationalization must be used within a InternationalizationProvider');
  }
  const {
    locale,
    setLocale,
    id,
  } = context;

  return {locale, setLocale, id};
}

AuditReportInternationalizationProvider.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string
};


export {AuditReportInternationalizationProvider, useAuditReportInternationalization};
