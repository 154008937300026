import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {message} from 'antd';
import defaults from 'lodash/defaults';
import get from 'lodash/get';

const defaultConfig = {translation: 'alert', prefix: 'error'};

export default (config) => {
  const {translation, prefix} = defaults(config, defaultConfig);

  const {t} = useTranslation(translation);

  const putErrorCode = useCallback((err) => {
    const code = get(err, ['response', 'data', 'reference'], '0');
    const name = get(err, ['response', 'data', 'name'], 'INTERNALERROR');
    message.error(t(`${prefix}.${name}.${code}`));
  }, [prefix, t]);

  return {
    putErrorCode
  };
};
