import {useMemo, useState, useCallback} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import UsersApiService from '@/modules/users/services/UsersApiService';
import {CACHE_KEY_USERS, CACHE_KEY_LIST_USERS} from '@/modules/users/contants/users';
import {fields} from '@/modules/users/contants/users.form';
import { TermFilter } from '@dofleini/query-builder';
import {useQuery} from 'react-query';
import {useTranslation} from 'react-i18next';
import {renderStatusSelector} from '@/modules/admin/components/FieldUserStatus';

export const useGetOneUsers = createGetOneHook(UsersApiService, 'id', CACHE_KEY_USERS);
export const useFieldsBase = createFieldsHook('users', fields);

export const useFields = (isViewMode = false, form) => {
  const fields = useFieldsBase(isViewMode);
  const {t} = useTranslation('users');

  const initialValues  = form.getFieldsValue();

  const [status, setStatus] = useState(initialValues?.status);

  const onChangeStatus = useCallback((val) => {
    setStatus(val);
    form.setFieldsValue({verified: Boolean(val === 'ACTIVE')});
  }, [form]);


  return useMemo(() => {
    const fieldsMap = [...fields];
    if (!isViewMode) {
      fieldsMap.splice(
        9, 0,
        {
          key: 'status',
          colSpan: 2,
          label: t('user.form.status'),
          onlyEdit: true,
          initialValue: initialValues?.status,
          value: status,
          onChange: onChangeStatus,
          widget: renderStatusSelector,
        },
      );
    }
    return fieldsMap;
  }, [fields, initialValues?.status, isViewMode, onChangeStatus, status, t]);
};

export const useGetUsersByIds = (arrayId) => {
  const filters = new TermFilter({ field: '_id', value: { $in: arrayId } });

  const queryConfig = useMemo(() => {
    return {enabled: arrayId && arrayId.length > 0};
  }, [arrayId]);

  return useQuery([CACHE_KEY_LIST_USERS, filters], async () => {
    const {data} = await UsersApiService.search({
      size: 9999,
      search: '',
      filters
    });
    return data;
  }, queryConfig);
};

export const useGetOne = (_id) => {

  const queryConfig = useMemo(() => {
    return {enabled: Boolean(_id)};
  }, [_id]);

  return useQuery([CACHE_KEY_USERS, _id], async () => {
    const {data} = await UsersApiService.getOne(_id);
    return data;
  }, queryConfig);
};

export const useGetOneAuditorForAudit = (_id) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(_id)};
  }, [_id]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_USERS, _id], async () => {
    if (typeof _id !== 'string') return _id;

    const {data} = await UsersApiService.getOne(_id);
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};
