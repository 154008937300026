/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/9/21
 */
import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Form, Typography} from 'antd';
import cn from 'classnames';
import {getFromSource} from '@/utils/getImages';
import UploadImage from '@/components/UploadImage/UploadImage';
import {useTranslation} from 'react-i18next';
import styles from './index.less';

const {Text, Title} = Typography;

const ClientUploadImage = ({editing, value, onChange, className, helperText, onChangeFullValue, CustomDefaultIcon, entityType}) => {

  const {t} = useTranslation('clients');

  const handleChange = useCallback((data) => {
    onChange(data);
    onChangeFullValue(data);
  }, [onChange, onChangeFullValue]);

  return (
    <>
      {
        helperText && <Text className={'-mt-2 mb-2 block'} type={'secondary'}>{helperText}</Text>
      }
      {editing ?
        <div className={styles.wrapperUploadImage}>
          <UploadImage
            onChange={handleChange}
            value={value?.thumb}
            CustomDefaultIcon={CustomDefaultIcon}
            size={67}
            shape="square"
          />
          <div className={styles.info}>{t(`form.${entityType === 'establishment' ? 'infoEstablishmentAboutLogo' : 'infoStructureAboutLogo'}`)}</div>
        </div>
        :
        <div className={cn(['flex items-center', className])}>
          <Avatar src={getFromSource(value?.thumb)} size={32}/>
          <div className={'ml-2'}>
            <Title level={4}>{value?.fullName}</Title>
            <Text type={'secondary'}>{value?.username}</Text>
          </div>
        </div>
      }
    </>
  );
};

export default memo(ClientUploadImage);

ClientUploadImage.propTypes = {
  value: PropTypes.any,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeFullValue: PropTypes.func,
  className: PropTypes.string,
  helperText: PropTypes.string,
  CustomDefaultIcon: PropTypes.any,
  entityType: PropTypes.string,
};

ClientUploadImage.defaultProps = {
  onChangeFullValue: v => v,
};

// eslint-disable-next-line react/prop-types
export const renderClientUploadImage = ({formItemProps, form, field, CustomDefaultIcon}) => {

  const onChange = (image = {}) => {
    // eslint-disable-next-line react/prop-types
    form.setFieldsValue({
      image
    });
  };

  return (
  // eslint-disable-next-line react/prop-types
    <Form.Item {...formItemProps} required={field?.required}>
      <ClientUploadImage
        onChangeFullValue={onChange}
        CustomDefaultIcon={CustomDefaultIcon}
        editing
        entityType={field?.entityType || 'structure'}
      />
    </Form.Item>
  );
};

