import {ApiClientService, EntityApiService} from '@dofleini/security';

class AuditsApiService extends EntityApiService {
  constructor(path) {
    super();
    this.summaryParams = {};
    this.path = path;
  }
  getSummaryParams(){
    return this.summaryParams;
  }

  setSummaryParams(params){
    this.summaryParams = params;
  }

    summarySearch = () => {
      return ApiClientService.post(this.getPath('/search-summary-from-client'), this.getSummaryParams());
    }

    getAllSections = ({auditId}) => ApiClientService.post(this.getPath(`/${auditId}/questions-answers`));

    reOpen = ({auditId, ...params}) => ApiClientService.patch(this.getPath(`/${auditId}/reopen`), params);
}

export default new AuditsApiService('/ms-core/api/audits');
