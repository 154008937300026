import React, {memo, useCallback, useEffect} from 'react';
import {Button, Form, Input, message} from 'antd';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useDialogsContext} from '@/contexts/DialogsContext';
import UserEmailSearch from '@/components/NewEmailDialog/components/UserEmailSearch';
import Editor from '@/components/Editor';
import UserEmailApiService from '@/modules/admin/services/UserEmailApiService';
import map from 'lodash/map';
import FloatContainer, {TYPE_CONTAINER} from '@/components/FloatContainer';
import {useResponsive} from '@/contexts/ResponsiveContext';

export const DIALOG_NAMESPACE = 'new-email-dialog';

const NewEmailDialog = () => {
  const {t} = useTranslation('common');
  const {isOpen, closeDialog, payload} = useDialogsContext(DIALOG_NAMESPACE);
  const {isMobile} = useResponsive();
  const {mutateAsync: mutate, isLoading} = useMutation(UserEmailApiService.sendEmail);
  const [form] = Form.useForm();

  useEffect(() => {
    isOpen && form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    values.to = map(values.to, v => {
      return /\w+@.*/.test(v) ? {email: v} : {_id: v};
    });

    await mutate(values).then(() => {
      message.success(t('sendEmailSuccess'));
    });
    closeDialog();
    form.resetFields();
  }, [form, mutate, closeDialog, t]);

  return (
    <FloatContainer
      type={isMobile ? TYPE_CONTAINER.DRAWER : TYPE_CONTAINER.MODAL}
      visible={isOpen}
      title={t('newEmail')}
      onClose={closeDialog}
      width={700}
      footer={[
        <Button className="mx-2" key="back" onClick={closeDialog} disabled={isLoading}>
          {t('cancel')}
        </Button>,
        <Button className="mx-2"
          key="submit" type="primary"
          onClick={handleSubmit}
          loading={isLoading}>
          {t('send')}
        </Button>,
      ]}>
      <Form form={form} className="p-4" onFinish={handleSubmit} layout="vertical">

        <Form.Item name="to" label={t('emailCC')}>
          <UserEmailSearch disabled={!isOpen} initUsers={payload?.users} initEmails={payload?.emails}/>
        </Form.Item>

        <Form.Item name="subject" label={t('summary')}>
          <Input/>
        </Form.Item>

        <Form.Item name="body" label={t('body')}>
          <Editor/>
        </Form.Item>

      </Form>
    </FloatContainer>
  );
};

export default memo(NewEmailDialog);

