/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 2/12/21
 */
import React, {createContext, useContext, useState} from 'react';

const TemplatesStatusContext = createContext({
  isLoading: false,
  setLoading: val => val
});

const useTemplatesStatus = () => {
  const context = useContext(TemplatesStatusContext);
  if (context === undefined) {
    throw new Error('useTemplatesStatus must be used within a TemplatesStatusProvider');
  }

  return context;
};

const TemplatesStatusProvider = ({ ...props }) => {

  const [isLoading, setIsLoading] = useState(false);

  return (
    <TemplatesStatusContext.Provider
      value={{
        isLoading, setIsLoading
      }}
      {...props}
    />
  );
};

export { useTemplatesStatus, TemplatesStatusProvider };
