/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 7/1/22
 */
import React, {createContext, useContext, useState, useEffect} from 'react';

const AuditDataContext = createContext({
  currentAudit: null,
  setCurrentAudit: val => val,
  dataIntro: [],
  setDataIntro: val => val,
  dataSections: [],
  setDataSections: val => val,
});

const useAuditData = () => {
  const context = useContext(AuditDataContext);
  if (context === undefined) {
    throw new Error('useAuditData must be used within a AuditDataProvider');
  }

  return context;
};

// const URL = 'https://ip.nf/me.json';

const AuditDataProvider = ({ ...props }) => {

  const [currentAudit, setCurrentAudit] = useState(null);
  const [dataIntro, setDataIntro] = useState([]);
  const [dataSections, setDataSections] = useState([]);
  const [locationData, setLocationData] = useState('');

  useEffect(() => {
    // const fetchData = async () => {
    //   // eslint-disable-next-line no-undef
    //   const result = await fetch(URL, {method: 'get'})?.then((res) => res.json());
    //   setLocationData(`${result?.ip?.city}, ${result?.ip?.country}`);
    // };
    // fetchData()?.then();
  }, []);

  return (
    <AuditDataContext.Provider
      value={{
        currentAudit,
        setCurrentAudit,
        dataIntro,
        setDataIntro,
        dataSections,
        setDataSections,
        locationData,
        setLocationData
      }}
      {...props}
    />
  );
};

export { useAuditData, AuditDataProvider };

