export const LAYOUT_TYPE = {
  HORIZONTAL: 'HORIZONTAL',
  TOP: 'TOP',
};

export const TRIGGER_POSITION = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
};


export const layout = {
  type: LAYOUT_TYPE.HORIZONTAL,
  options: {
    width: 290,
    // this same value need to be configured at @/assets/less/antd.customize.less
    // variable @menu-collapsed-width
    collapsedWidth: 70,
    zeroWidthTriggerStyle: {display: 'none'},
    useTriggerText: true,
    triggerPosition: TRIGGER_POSITION.CENTER,
    theme: 'light'
  }
};
