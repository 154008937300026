import {renderSelectClient} from '@/modules/audits/components/AuditsSelectClients';
import {renderAuditDateRangeCell} from '@/modules/audits/components/AuditsDateRangeCell';
import AuditsSelectTemplateCell from '@/modules/audits/components/AuditsSelectTemplateCell';
import AuditsSummary from '@/modules/audits/components/AuditsSummary';
import AuditsSelectEstablishment from '@/modules/audits/components/AuditsSelectEstablishment';
import AuditsSelectService from '@/modules/audits/components/AuditsSelectService';
// import {months} from '@/modules/audits/contants/months';
import AuditsSelectAuditor from '@/modules/audits/components/AuditsSelectAuditor';
import AuditsStartDate from '@/modules/audits/components/AuditsStartDate';
import SelectAreaAccess from '@/modules/audits/components/SelectAreaAccess';

export const fields = [
  {
    title: 'setting',
    key: 'setting',
    required: true,
    fields: [
      { key: 'client', label: 'form.client', placeholder: 'form.select', required: true, render: renderSelectClient },
      { key: 'establishment', label: 'form.establishment', placeholder: 'form.select', required: true, widget: AuditsSelectEstablishment, dependencies: ['client'], },
      { key: 'service', label: 'form.service', placeholder: 'form.select', required: true, widget: AuditsSelectService },
      // { key: 'month', label: 'form.month', placeholder: 'form.select', widget: 'select', options: months, colSpan: 1 },
      { key: 'valueGoal', label: 'form.valueGoal', placeholder: 'form.placeholderValueGoal', colSpan: 1 },
      { key: 'areaAccess', label: 'form.areaAccess', required: true, widget: SelectAreaAccess,  colSpan: 1 },
      { key: 'startDate', label: 'form.yearAndMonth', placeholder: 'form.select', widget: AuditsStartDate, colSpan: 1 },
      { key: 'date', label: 'form.date', render: renderAuditDateRangeCell, colSpan: 1},
      { key: 'auditor', label: 'form.auditor', placeholder: 'form.select', widget: AuditsSelectAuditor },
    ],
  },
  {
    title: 'template',
    key: 'template',
    required: true,
    fields: [
      { key: 'template', label: '', widget: AuditsSelectTemplateCell },
    ],
  },
  {
    title: 'confirmation',
    key: 'confirmation',
    required: true,
    fields: [
      // eslint-disable-next-line react/display-name
      { key: 'summary', label: 'summary', render: ({...props}) => <AuditsSummary {...props} /> },
    ],
  },
];
