/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 29/9/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Preloader = ({bg = '#FFF'}) => {
  const {t} = useTranslation('common');
  return (
    <div className="w-full h-full flex items-center justify-center indicator-loader">
      <Spin indicator={antIcon} tip={t('loading')} style={{ backgroundColor: bg, borderRadius: '5px' }} className="px-6 py-2"/>
    </div>
  );
};

Preloader.propTypes = {
  bg: PropTypes.string,
};

export default memo(Preloader);
