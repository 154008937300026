import React, {memo} from 'react';
import {application} from '@/settings';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

const getComponent = (Src, customClass) => {
  if (typeof Src === 'string') {
    return <img className={classNames(customClass, 'w-full my-4 mx-0 cursor-pointer max-h-10')} src={Src} alt={application.name}/>;
  }
  return <Src className={classNames(customClass, 'w-full my-4 mx-0 cursor-pointer max-h-10')}/>;
};

const Logo = ({ mini, customClass }) => {
  return <Link to="/">
    {mini ? getComponent(application.logo.srcMin, customClass) : getComponent(application.logo.src, customClass)}
  </Link>;
};

Logo.propTypes = {
  customClass: PropTypes.any,
  mini: PropTypes.any
};

export default memo(Logo);
