/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/12/21
 */
import React, {memo, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {List, Typography, Button} from 'antd';
import classNames from 'classnames';
import {useInfinite} from '@/utils/createInfiniteHook';
import { useInfiniteScroll } from '@dofleini/use-scroll';
import {CACHE_KEY_LIST_TEMPLATES} from '@/modules/templates/contants/audits/templates';
import TemplatesApiService from '@/modules/templates/services/audits/TemplatesApiService';
import {useFilter} from '@/contexts/FilterContext';
import {/*useHistory, */useParams} from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useAuditWizard} from '@/modules/audits/hooks/useWizard';
import { Scrollbars } from 'react-custom-scrollbars';
import styles from './index.less';
import {stringToHslColor} from '@/utils/utils';

const SERVER_PAGE = 25;

const TemplateList = ({currentCategory, value, onChange}) => {
  const {globalFilter} = useFilter();
  // const {push} = useHistory();
  const {t} = useTranslation('audits');
  const {id} = useParams();

  const {templateId, setTemplateId} = useAuditWizard('');

  useEffect(() => {
    if (value?.parent && id)
      setTemplateId(value?.parent);
  }, [id, setTemplateId, value?.parent]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isSuccess,
    onLoadCompleted
  } = useInfinite({
    key: [CACHE_KEY_LIST_TEMPLATES, globalFilter, currentCategory],
    service: TemplatesApiService.search,
    filters: globalFilter,
    queryConfig: { size: Math.max(25, SERVER_PAGE) },
  });

  useInfiniteScroll({
    fromWindow: false,
    hasNext: hasNextPage && !(isFetching || isLoading),
    onLoadMore: fetchNextPage
  });

  useEffect(() => {
    if (isSuccess)
      onLoadCompleted && onLoadCompleted(data);
  }, [data, isSuccess, onLoadCompleted]);

  // const goTo = useCallback((templateId) => {
  //   push(`/templates/manage/${templateId}`);
  // }, [push]);

  const handleSelect = useCallback((useTemplateId) => {
    setTemplateId(useTemplateId);
    onChange(useTemplateId);
  }, [onChange, setTemplateId]);

  return (
    <div className={classNames('w-full h-full')}>
      <div className={styles.header}>
        { t('templates') }
      </div>
      <Scrollbars
        style={{ height: 'calc(100vh - 300px)' }}
        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
        disableHorizontalScrolling
      >
        <List
          style={{ marginTop: '5px' }}
          bordered
          dataSource={data}
          className="w-full"
          renderItem={item => {
            return (
              <List.Item
                className={classNames('w-full wrapper-template-list', styles.wrapper)}
                style={{
                  backgroundColor: templateId === item?._id ? '#E6F7FF' : '#fff',
                  borderLeft: item?.category?.color ? `6px solid ${item?.category?.color}` : item?.category?.name ? stringToHslColor(item?.category?.name, 50, 50) : '6px solid #ccc'
                }}
              >
                <div className="w-full flex items-start justify-center flex-col">
                  <div>
                    <Typography.Text className="text-primary cursor-pointer"
                      onClick={() => handleSelect(item?._id)/*goTo(item?._id)*/}>{item?.name}</Typography.Text>
                  </div>
                  <div>
                    {moment(item?.createdAt).format('ll')}
                  </div>
                </div>

                <div className="actions">
                  {templateId !== item?._id && <Button type="primary"
                    onClick={() => handleSelect(item?._id)}>{t('selectThisTemplate')}</Button>}
                </div>
              </List.Item>
            );
          }}
        />
      </Scrollbars>
    </div>
  );
};

TemplateList.propTypes = {
  entity: PropTypes.object,
  currentCategory: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(TemplateList);

