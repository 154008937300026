import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import filterFP from 'lodash/fp/filter';
import mapFieldItems from '@/utils/mapFieldItems';
import mapFP from 'lodash/fp/map';

export const createWizardFieldsHook = (translation, fields) => {
  return (isViewMode) => {
    const { t } = useTranslation(translation);
    return useMemo(
      () => compose(
        mapFP(wizard => {
          const fields = compose(
            filterFP(({ onlyView, onlyEdit }) => {
              if (onlyView) return isViewMode;
              if (onlyEdit) return !isViewMode;
              return true;
            }),
            mapFieldItems(t),
          )(wizard.fields);
          return {
            ...wizard,
            fields
          };
        }),
        filterFP(({ onlyView, onlyEdit }) => {
          if (onlyView) return isViewMode;
          if (onlyEdit) return !isViewMode;
          return true;
        }),
      )(fields), [isViewMode, t]);
  };
};

