import React from 'react';
import {TitleUpdateTask} from '@/components/NotificationDrawer/components/NotificationItem/components/UpdateTask';
import {TitleSharedFile} from '@/components/NotificationDrawer/components/NotificationItem/components/SharedFile';

// eslint-disable-next-line react/display-name
export default (notification) => {
  switch (notification?.type) {
    case 'TASK_UPDATE_STATE':
      return <TitleUpdateTask item={notification}/>;
    case 'SHARE_FILE':
      return <TitleSharedFile item={notification}/>;
    default:
      // eslint-disable-next-line
      return () => null;
  }
};