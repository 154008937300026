/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 22/11/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Card} from 'antd';
import {ClockCircleOutlined} from '@ant-design/icons';
import TaskStatus from '@/modules/templates/components/Audits/IncidenceDetailTasks/TaskStatus';
import styles from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditContent/components/WrapperFieldType/components/Auditor/index.less';
import moment from 'moment';
import {useAuditData} from '@/modules/audits/hooks/useAuditData';
import {stringToHslColor} from '@/utils/utils';

const CardTask = ({data, idx}) => {
  const {currentAudit} = useAuditData();

  return (
    <Card
      key={data?._id}
      className={classNames(styles.templateAuditorCard, idx > 0 && 'mt-2')}
    >
      <div className={'font-bold'} style={{color: currentAudit?.template?.category ? stringToHslColor(currentAudit?.template?.category, 50, 50) : '#4db7f9'}}>{data?.name}</div>
      <div className={'inline-flex items-center mt-2 flex-wrap'}>
        <div className={'mr-6 sm:mb-2'}>
          <TaskStatus status={data?.status} className={'leading-7'}/>
        </div>
        <div className="incident-date">
          <ClockCircleOutlined/>
          <span className={'ml-2'}>{moment(data?.dueDate).format('LL')}</span>
        </div>
      </div>
    </Card>
  );
};

CardTask.propTypes = {
  data: PropTypes.object,
  idx: PropTypes.number
};

export default memo(CardTask);
