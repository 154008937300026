import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {message} from 'antd';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import {CACHE_KEY_CLIENT, CACHE_KEY_LIST_CLIENTS} from '@/modules/clients/contants/clients';
import {fields} from '@/modules/clients/contants/clientsEstablishment.form';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {useTranslation} from 'react-i18next';
import SelectCountryEmployee from '@/components/SelectCountry';
import SelectStateEmployee from '@/components/SelectStateCell';
import SelectCityEmployee from '@/components/SelectCityCell';
import {useParams, useLocation} from 'react-router-dom';
import ClientsEstablishmentApiService from '@/modules/clients/services/ClientsEstablishmentApiService';
import {difference} from 'lodash';
import useAlerts from '@/hooks/useAlerts';
import {CACHE_KEY_LIST_CLIENTS_TREE} from '@/modules/clients/contants/clientsTree';

export const useGetOneEstablishments = createGetOneHook(ClientsEstablishmentApiService, 'id', CACHE_KEY_CLIENT);

const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export const useGetOneEstablishment = (clientId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(clientId)};
  }, [clientId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_CLIENT, clientId], async () => {
    const {data} = await ClientsEstablishmentApiService.getOneClient({clientId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};

export const useGetOneEstablishmentForAudit = (clientId) => {
  const queryConfig = useMemo(() => {
    return {enabled: !!(clientId)};
  }, [clientId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_CLIENT, clientId], async () => {
    if (typeof clientId !== 'string') return clientId;

    const {data} = await ClientsEstablishmentApiService.getOneClient({clientId});
    return data;
  }, queryConfig);
  return {isLoading, isError, error, data};
};

const useFieldsBase = createFieldsHook('clients', fields);

export const useFields = (isViewMode, form, initialValues) => {
  const fields = useFieldsBase(isViewMode);
  const {t} = useTranslation('clients');

  const {id} = useParams();
  const {pathname} = useLocation();

  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState('');

  const clearOnChange = useCallback((type) => {
    type === 'country' ? form.setFieldsValue({province: null, municipality: null})
      : form.setFieldsValue({municipality: null});
  }, [form]);

  useEffect(() => {
    if (initialValues?.country) {
      setCountryId(initialValues?.country);
    }
  }, [initialValues?.country]);

  useEffect(() => {
    if (initialValues?.provinceOrState || initialValues?.province) setStateId(initialValues?.provinceOrState || initialValues?.province);
  }, [initialValues?.province, initialValues?.provinceOrState]);

  const {visitDays, notVisitDays} = form?.getFieldsValue();

  return useMemo(() => {
    const fieldsMap = [...fields];
    if (!isViewMode) {
      fieldsMap.splice(
        2, 0,
        {
          key: 'country',
          placeholder: t('form.placeholderEmployeeCountry'),
          label: t('form.country'),
          colSpan: 1,
          onlyEdit: true,
          initialValue: initialValues?.country,
          // eslint-disable-next-line react/display-name
          widget: ({...props}) => <SelectCountryEmployee setCountryId={setCountryId} clearOnChange={() => clearOnChange('country')} {...props} />
        }
      );
      fieldsMap.splice(
        3, 0,         {
          key: 'province',
          placeholder: t('form.placeholderEmployeeProvinceOrState'),
          label: t('form.provinceOrState'),
          colSpan: 1,
          onlyEdit: true,
          disabled: !countryId,
          initialValue: initialValues?.provinceOrState,
          // eslint-disable-next-line react/display-name
          widget: ({...props}) => <SelectStateEmployee countryId={countryId} setStateId={setStateId} translation="clients" clearOnChange={() => clearOnChange('province')} {...props} />
        }
      );
      fieldsMap.splice(
        4, 0, {
          key: 'municipality',
          placeholder: t('form.placeholderEmployeeMunicipality'),
          label: t('form.municipality'),
          colSpan: 1,
          onlyEdit: true,
          disabled: !stateId,
          initialValue: initialValues?.municipality,
          // eslint-disable-next-line react/display-name
          widget: ({...props}) => <SelectCityEmployee stateId={stateId} translation="clients" {...props} />
        }
      );
      fieldsMap.splice(
        15, 0, {
          key: 'visitDays',
          label: t('form.visitDays'),
          placeholder: t('form.placeholderVisitDays'),
          onlyEdit: true,
          className: 'custom-select custom-select-multiple',
          colSpan: 1,
          widget: 'select',
          initialValue: initialValues?._id ? difference(days, initialValues?.notVisitDays) : [],
          options: difference(days, notVisitDays),
          dependencies: ['notVisitDays'],
          widgetProps: {
            mode: 'multiple'
          }
        }
      );
      fieldsMap.splice(
        16, 0,   {
          key: 'notVisitDays',
          label: t('form.notVisitDays'),
          placeholder: t('form.placeholderNotVisitDays'),
          onlyEdit: true,
          className: 'custom-select custom-select-multiple',
          colSpan: 1,
          widget: 'select',
          initialValue: initialValues?._id ? difference(days, initialValues?.visitDays) : [],
          options: difference(days, visitDays),
          dependencies: ['visitDays'],
          widgetProps: {
            mode: 'multiple'
          }
        }
      );
      if (pathname?.includes(id+'/structure'))
        fieldsMap.splice(
          fieldsMap?.length, 0, {
            key: 'structure',
            colSpan: 1,
            onlyEdit: true,
            initialValue: id,
            className: 'hidden-field',
            widgetProps: {
              hidden: true
            }
          }
        );
    }
    return fieldsMap;
  }, [clearOnChange, countryId, fields, id, initialValues?._id, initialValues?.country, initialValues?.municipality, initialValues?.notVisitDays, initialValues?.provinceOrState, initialValues?.visitDays, isViewMode, notVisitDays, pathname, stateId, t, visitDays]);
};

export const useRemove = (parentId) => {
  const {t} = useTranslation('common');
  const queryClient = useQueryClient();
  const {putErrorCode} = useAlerts();

  const {mutateAsync: remove, isLoading} = useMutation(ClientsEstablishmentApiService.delete,
    {
      onSuccess: () => {
        message.success(t('deleteSuccess'));
        queryClient.invalidateQueries([CACHE_KEY_LIST_CLIENTS, parentId]);
        queryClient.invalidateQueries(CACHE_KEY_LIST_CLIENTS_TREE);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    }
  );
  return {
    remove,
    isLoading
  };
};

export const useDuplicateEstablishments = () => {
  const {t} = useTranslation('common');
  const queryClient = useQueryClient();

  const {putErrorCode} = useAlerts();

  const {mutateAsync: duplicate, isLoading: isDuplicating} = useMutation(ClientsEstablishmentApiService.duplicate,
    {
      onSuccess: () => {
        message.success(t('duplicateSuccess'));
        queryClient.invalidateQueries(CACHE_KEY_LIST_CLIENTS_TREE);
      },
      onError: (e) => {
        putErrorCode(e);
      }
    });
  return {duplicate, isDuplicating};
};
