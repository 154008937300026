/**
 * @author Geider Arevalo
 * @since v0.0.1
 * @date 02/02/22
 */
import React, {memo, useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Button, message} from 'antd';
import {ArrowLeftOutlined, InfoCircleOutlined, LinkOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import Download from '@/modules/audits/components/WrapperDetailAudit/components/Download';
import {useAuditData} from '@/modules/audits/hooks/useAuditData';
import {FilterProvider} from '@/contexts/FilterContext';
import {CACHE_KEY_LIST_AUDITS} from '@/modules/audits/contants/audits';
import {useUser} from '@dofleini/security';
import {i18n} from '@/settings';
import {i18nSinglePage as i18} from '@/settings';
import HeaderSkeleton from '@/modules/audits/components/WrapperDetailAudit/components/ManageAuditHeader/HeaderSkeleton';
import styles from './index.less';
import {useRealTimeFromNow} from '@/hooks/useRealTimeFromNow';
import {useTemplatesStatus} from '@/modules/templates/hooks/audits/useTemplatesStatus';

const shareAuditReport = (auditId) => {
  const el = document.createElement('input');
  el.value = `${process.env.REACT_APP_SPACE_DOMAIN}/share-audit-report/${auditId}`;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  message.success(i18n.t('templates:copyURLToShare'));
};

const ManageAuditHeader = ({isLoading: loading}) => {
  const {t} = useTranslation('templates');
  const {currentAudit} = useAuditData();
  const {push} = useHistory();
  const { user } = useUser();
  const elapsedTime = useRealTimeFromNow(currentAudit);

  const {setIsLoading, isLoading} = useTemplatesStatus();

  useEffect(() => setIsLoading && setIsLoading(isLoading), [isLoading, setIsLoading]); // just to update global status

  const goTo = useCallback(() => {
    i18.changeLanguage('es');
    push('/audits');
  }, [push]);

  const updatedAt = useMemo(() => {
    if (!isLoading) return elapsedTime;
  }, [elapsedTime, isLoading]);

  if (loading) return <HeaderSkeleton />;

  return (
    <div className={styles.manageAuditHeader}>
      <div className={styles.title}>
        <div onClick={goTo} className={classNames('flex items-center justify-center cursor-pointer', styles.hoverGoBack)}>
          {user && <ArrowLeftOutlined style={{fontSize: '1.3rem'}} className="arrow-go-back"/>}

          <div className="flex items-start justify-center cursor-pointer mr-2 flex-col" style={{minWidth: '216px'}}>
            <div className="ml-4 template-section-title">{currentAudit?.service?.name}</div>
            <div className="flex items-center justify-start flex-wrap">
              <div className="flex items-center justify-center ml-4 text-primary">
                <div className="w-full">{currentAudit?.establishment?.name}</div>
              </div>

              <div className="flex items-center justify-center ml-2">
                <span className="hidden md:flex">&gt;</span>
                <InfoCircleOutlined className="ml-2" style={{fontSize: '1.1rem', color: '#B2B2B2'}}/>
                <div className="flex items-center justify-center">
                  <span className="ml-2" style={{color: '#B2B2B2'}}>{t('lastUpdated')}</span>
                  <span className="ml-2">{updatedAt}</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div className={styles.actions}>
        <Download />
        <Button
          type="primary"
          icon={<LinkOutlined />}
          className={'rounded ml-3 ant-btn-lg'}
          onClick={() => shareAuditReport(currentAudit?._id)}
        >
          {t('share')}
        </Button>
      </div>
    </div>
  );
};

ManageAuditHeader.propTypes = {
  currentAudit: PropTypes.string,
  isLoading: PropTypes.bool,
};

const WrapperAudit = ({...props}) => {
  return <FilterProvider id={CACHE_KEY_LIST_AUDITS}>
    <ManageAuditHeader {...props} />
  </FilterProvider>;
};

export default memo(WrapperAudit);

