import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';
import {Select} from 'antd';
import spain from '@/assets/images/spainFlag.png';
import ukFlag from '@/assets/images/ukFlag.png';
import {useTranslation} from 'react-i18next';

const { Option } = Select;

const options = {
  SPANISH: 'es',
  ENGLISH: 'en',
};

const SelectTemplateLanguage = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('templates');

  const spainOption = useMemo(() => {
    return (<div className={'flex items-center justify-start'}>
      <img src={spain} alt="spainFlag" width={'20px'} height={'auto'} className={'mr-2'}/>
      <span>{t('es')}</span>
    </div>);
  }, [t]);

  const ukOption = useMemo(() => {
    return (<div className={'flex items-center justify-start'}>
      <img src={ukFlag} alt="ukFlag" width={'20px'} height={'25px'} className={'mr-2'}/>
      <span>{t('en')}</span>
    </div>);
  }, [t]);

  return (
    <Select defaultValue={value || options.SPANISH} onChange={onChange} {...props}>
      <Option value={options.SPANISH}>{spainOption}</Option>
      <Option value={options.ENGLISH}>{ukOption}</Option>
    </Select>
  );
};

SelectTemplateLanguage.propTypes = {
  value: PropTypes.string || PropTypes.object,
  onChange: PropTypes.func,
};

export default memo(SelectTemplateLanguage);
