import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Table, Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {getloadingArray} from '@/utils/loadingData';
import PropTypes from 'prop-types';
import style from './index.less';
import classNames from 'classnames';
import {InfoCircleFilled, CaretRightOutlined} from '@ant-design/icons';
import {useTableGrid} from '@/contexts/tableGridContact';
import {useTranslation} from 'react-i18next';
import map from 'lodash/map';
import filter from 'lodash/filter';
import {useExpandableRows} from '@/components/TableTree/hooks/useExpandableRows';
import {useTableTreeData} from '@/hooks/useTableTreeData';

const LOADING_DATA = getloadingArray(10);

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const TableTreeData = ({
  tableHook,
  canSelect,
  selectedActions,
  onMountData,
  rowKey,
  expandable,
  useGetChildren = () => ({data: []}),
  loadAsyncChildren = false,
  ...props}) => {
  const { setPage, totalPages, isFetching, data, setSize, page, size: pageSize } = tableHook();
  const [selectedRowsData, setSelectedRows] = useState([]);
  const {t} = useTranslation('table');

  const {data: getData, setData} = useTableTreeData();

  useEffect(() => {
    setData(data || []);
  }, [data, setData]);

  const syncSelectedData = useCallback(() => {
    const validIds = map(getData, ({_id}) => _id);

    const [selectedIds, selectedRows] = selectedRowsData;

    setSelectedRows([
      filter(selectedIds, _id => validIds.includes(_id)),
      filter(selectedRows, ({_id}) => validIds.includes(_id)),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    onMountData(getData);
    syncSelectedData();
  }, [getData, onMountData, syncSelectedData]);

  const {visibleColumns, size} = useTableGrid();

  /**
   * @param {Array} list of selected rows key
   * @param {Array} list of selected full entity items
   * */
  const onChangeRowsSelected = useCallback((...args) => setSelectedRows(args), []);

  const selectedRowKeys = useMemo(() => selectedRowsData[0] || [], [selectedRowsData]);
  const selectedRows = useMemo(() => selectedRowsData[1] || [], [selectedRowsData]);

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onChange: onChangeRowsSelected
  }), [onChangeRowsSelected, selectedRowKeys]);

  const paginationConfig = useMemo(() => {
    return {
      pageSize,
      hideOnSinglePage: true,
      onChange: setPage,
      onShowSizeChange: (index, value) => setSize(value),
      total: totalPages,
      size: 'default',
      current: page
    };
  }, [pageSize, setPage, totalPages, page, setSize]);

  const [parentId, setParentId] = useState('');
  const [closeCollapse, setCloseCollapse] = useState(false);

  /** Get dynamics children from the endpoint */
  const {data: children, isLoading} = useGetChildren(parentId);

  const handleGetChildren = useCallback((parent) => {
    setParentId(parent);
  }, []);

  /** Find a node by id using recursion */
  const findById = useCallback((arr, id, nestingKey) => {
    // if empty array then return
    if (arr?.length === 0) return;

    // return element if found else collect all children(or other nestedKey) array and run this function
    return arr.find((d) => d?._id === id) || findById(arr.flatMap((d) => d[nestingKey] || []), id, nestingKey) || null;
  }, []);

  useEffect(() => {
    if (parentId && !isLoading) {
      const newData = [...getData];
      const parent = findById(closeCollapse ? [] : newData, parentId, 'children');
      if (parent) {
        if (closeCollapse) {
          console.log();
        } else {
          if (!parent?.children || !parent?.children?.length) {
            parent.children = children || [];
            setData(newData);
          }else {
            if (closeCollapse) delete parent.children;
            setData(prevState => prevState);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, findById, isLoading, parentId, getData, closeCollapse]);

  const {defaultExpandedRowKeys, onExpandedRowsChange} = useExpandableRows();

  return (
    <div className={style.tableContainer}>
      {
        !!selectedRowKeys.length &&
        <div className={classNames(['hidden md:flex items-center justify-between mb-3', style.selectionActions])}>
          <div className={'selected'}>
            <InfoCircleFilled/>
            {selectedRowKeys.length}
            <span className={'lg:hidden'}>{t('selectedElementsXs')}</span>
            <span className={'hidden lg:inline-block'}>{t('selectedElements')}</span>
          </div>
          <div className={'actions flex'}>
            <Button type="text" onClick={() => setSelectedRows([])}>{t('deselect')}</Button>
            {selectedActions({selectedRows, selectedRowKeys})}
          </div>
        </div>
      }
      <Table
        {...props}
        size={size}
        rowSelection={canSelect ? rowSelection : false}
        columns={visibleColumns}
        rowKey={rowKey}
        pagination={paginationConfig}
        dataSource={!getData && isFetching ? LOADING_DATA : getData}
        expandable={{
          onExpand: useCallback((expanded, record) => {
            if (loadAsyncChildren) {
              handleGetChildren(record?._id);
              setCloseCollapse(false);
            }
            if (!expanded && loadAsyncChildren) {
              setCloseCollapse(true);
            }
          }, [handleGetChildren, loadAsyncChildren]),
          expandIcon: useCallback(({expanded, onExpand, record}) => {
            return expandable(record) && isLoading && parentId === record?._id ?
              <Spin indicator={antIcon} spinning={isLoading && parentId === record?._id}>
                <div className="wrapper-icon-caret flex items-center justify-center mr-3">
                  <CaretRightOutlined className="table-icon-caret" rotate={expanded ? 90 : 0}
                    onClick={e => onExpand(record, e)}/>
                </div>
              </Spin> :
              expandable(record) && <div className="wrapper-icon-caret flex items-center justify-center mr-3">
                <CaretRightOutlined className="table-icon-caret" rotate={expanded ? 90 : 0}
                  onClick={e => onExpand(record, e)}/>
              </div>;
          }, [expandable, isLoading, parentId]),
          onExpandedRowsChange,
          defaultExpandedRowKeys
        }}
      />
    </div>
  );
};

export default memo(TableTreeData);

TableTreeData.propTypes = {
  tableHook: PropTypes.func,
  canSelect: PropTypes.bool,
  selectedActions: PropTypes.func,
  onMountData: PropTypes.func,
  rowKey: PropTypes.string,
  expandable: PropTypes.func,
  useGetChildren: PropTypes.func,
  loadAsyncChildren: PropTypes.async,
};

TableTreeData.defaultProps = {
  onMountData: v => v,
  rowKey: '_id'
};
