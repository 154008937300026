import {EntityApiService, ApiClientService} from '@dofleini/security';
import {flatDataClients} from '../contants/mockup';
import {data} from '../contants/data';

class ClientsApiService extends EntityApiService {

  // searchInFilter = (params) => ApiClientService.post(this.getPath('/search'), {...params, filters: {...params?.filters, type: 'TERM', 'field':'active','value': true, 'objectId': false}});

    searchClients = (params) => ApiClientService.post(this.getPath('/parent/search'), params);

    searchChildStructures = (params) => ApiClientService.post(this.getPath(`/${params?.parentId}/children`), params);

    getOneClient = ({clientId}) => ApiClientService.get(this.getPath(`/${clientId}`));

    getChildren = ({parentId, ...params}) => ApiClientService.post(this.getPath(`/${parentId}/children`), params);

    delete = (client) => ApiClientService.delete(this.getPath(`/${client?._id || client}?cascadeDelete=true`));

    setStructureId = (currentId) => {
      localStorage.setItem('structure-current-id', currentId);
    }

    getStructureId = () => {
      return localStorage.getItem('structure-current-id');
    }

    /** Get Mockup Data */
    getMockupData = () => {
      return {data};
    };

    /** Get Mockup Data */
    getMockupFlatData = () => {
      return {data: flatDataClients};
    };

    /** Get Mockup Data */
    getMockupDirectoryData = () => {
      return {data: flatDataClients};
    };

    duplicate = (params) => {
      return ApiClientService.post(this.getPath('/duplicate'), params);
    };

    getAllClients = (params) => {
      return ApiClientService.post(this.getPath('/clients/search'), params);
    };
}

export default new ClientsApiService('/ms-core/api/structures');
