/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/12/21
 */
import React, {memo, useState, useRef, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';
import ContentEditable from 'react-contenteditable';
import classNames from 'classnames';

const EditableField = ({value, onChange, key}) => {
  const [editing, setEditing] = useState(false);
  const [html, setHtml] = useState(value || 'Ej: Esto es una evidencia');
  const text = useRef(html);

  useEffect(() => setHtml(value), [value]);

  const handleEdit = useCallback(async () => {
    if (!text?.current || !text?.current?.trim() || value === text?.current) {
      setEditing(false);
      text.current = html;
      if (document.getElementById(`editable-field-${key}`))
        setTimeout(() => document.getElementById(`editable-field-${key}`).blur(), 300);
      return;
    }

    onChange(text?.current?.trim());

    setHtml(text?.current?.trim());
    setEditing(false);
    if (document.getElementById(`editable-field-${key}`))
      setTimeout(() => document.getElementById(`editable-field-${key}`).blur(), 300);
  }, [value, onChange, html, key]);

  const handleChange = useCallback(evt => {
    text.current = evt.currentTarget.textContent;
    setHtml(evt.currentTarget.textContent);
    setEditing(true);
  }, []);

  const handleKeyDown = useCallback((evt) => {
    if(evt.key === 'Enter') {
      setEditing(false);
      handleEdit().then();
    }
  }, [handleEdit]);

  return (
    <div className="flex items-center template-edit-name">
      <ContentEditable
        disabled={false}
        id={`editable-field-${key}`}
        key={`editable-field-${key}`}
        onKeyDown={handleKeyDown}
        className={classNames(styles.contentEditable, editing ? 'field-is-editing w-full' : '', 'w-full flex flex-start', !value ? styles.emptyValue : '')}
        html={html || ''}
        onFocus={() => {
          setEditing(true);
        }}
        onBlur={handleEdit}
        onChange={handleChange}
        isEditing={editing ? 'true' : 'false'}
      />
    </div>
  );
};

EditableField.propTypes = {
  value: PropTypes.string,
  key: PropTypes.string,
  onChange: PropTypes.func
};

export default memo(EditableField);
