/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 13/10/21
 */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Divider} from 'antd';
import DynamicPhones from '@/components/DynamicPhones';
import {useTranslation} from 'react-i18next';

const ClientCustomPhone = ({text, isViewMode = false, required}) => {
  const {t} = useTranslation('clients');

  return (
    <div className="mt-2">
      <div>{t('form.contactData')}</div>
      <Divider style={{ marginBottom: '1.2rem', marginTop: '0.4rem' }}/>

      <DynamicPhones text={text} isViewMode={isViewMode} required={required} mixData/>
    </div>
  );
};

ClientCustomPhone.propTypes = {
  text: PropTypes.object,
  isViewMode: PropTypes.bool,
  required: PropTypes.bool,
};

export default memo(ClientCustomPhone);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {object} entity this is entity type
 * @param {Boolean} required this is entity type
 * */
export const renderCustomPhones= (text, entity, required) => {
  return (
    <ClientCustomPhone text={text} entity={entity} isViewMode={true} required={required}/>
  );
};
