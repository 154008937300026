import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@dofleini/security';
import UserAvatar from '@/components/UserAvatar';
import { Layout, Menu, Popover } from 'antd';
import { RightOutlined, UserOutlined } from '@ant-design/icons';
import NavMenu from '@/layout/Menu';
import { getUserFullName } from '@/utils/userFullName';
import classNames from 'classnames';

const { Item } = Menu;

const UserActionPopup = ({ config, onClick, collapsed, menu, subMenuProps, popupProps, ...props }) => {
  const { user } = useUser();
  const fullName = useMemo(() => getUserFullName(user), [user]);
  const [visible, setVisible] = useState(false);
  
  const handleClick = useCallback(e => {
    setVisible(false);
    onClick(e);
  }, [onClick]);
  
  return (
    <Popover
      visible={visible}
      onVisibleChange={setVisible}
      destroyTooltipOnHide
      trigger="click"
      {...popupProps}
      content={<div className="min-w-48">
        {config?.showInfo && <div className="p-2">
          <h3 className="m-0">{fullName}</h3>
          <span>{user?.email}</span>
        </div>}
        <Layout.Sider>
          <NavMenu
            theme="light" menu={menu} onClick={handleClick}
            menuProps={{ className: 'border-none px-2', selectable: false }}
            subMenuProps={{ className: 'p-0 m-0', ...subMenuProps }}/>
        </Layout.Sider>
      </div>}
    >
      <Menu className="relative" {...props}>
        <Item
          key={'user-popup'}
          icon={<span className={classNames('pr-2', { '-ml-1': !collapsed, '-ml-px': collapsed })}>
            <UserAvatar className={classNames({ '-mt-1': !collapsed })} user={user} icon={<UserOutlined/>}/></span>}
          {...props}>
          {!collapsed && <>
            {fullName}
            <span className="absolute right-0 h-full inline-block align-middle pr-2">
              <RightOutlined style={{ fontSize: '10px' }}/>
            </span>
          </>}
        </Item>
      </Menu>
    </Popover>
  );
};

export default memo(UserActionPopup);

UserActionPopup.propTypes = {
  config: PropTypes.object,
  popupProps: PropTypes.object,
  collapsed: PropTypes.any,
  onClick: PropTypes.func,
  menu: PropTypes.array,
  subMenuProps: PropTypes.object,
};

UserActionPopup.defaultProps = {
  config: {},
  onClick: e => e,
  subMenuProps: {},
  popupProps: {
    getPopupContainer: () => document.getElementById('side-bar-nav'),
    placement: 'rightBottom',
    align: {
      offset: [0, -7]
    }
  }
};
