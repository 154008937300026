/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 30/11/21
 */
import React, {memo, useMemo, useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Popover, Select, InputNumber, Button, Slider, Row, Col, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {useCrudQuestions} from '@/modules/templates/hooks/audits/useSections';
import {useTemplatesStatus} from '@/modules/templates/hooks/audits/useTemplatesStatus';

const { Option } = Select;
const { Text } = Typography;

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 999999;

export const getKeyToTranslate = (key) => {
  switch (key) {
    case 'ruleNone': return 'ruleNone';
    case 'greaterThan': return 'ruleGreaterThan';
    case 'lessThan': return 'ruleLessThan';
    case 'equal': return 'ruleEqual';
    default: return 'ruleBetween';
  }
};

const NumberFormatRules = ({rule, setRule, question, sectionId}) => {

  const {t} = useTranslation('templates');

  const [visible, setVisible] = useState(false);
  const [min, max] = useMemo(() => [DEFAULT_MIN, DEFAULT_MAX], []);

  const {update, isUpdating} = useCrudQuestions();
  const {setIsLoading} = useTemplatesStatus();

  useEffect(() => setIsLoading(isUpdating), [isUpdating, setIsLoading]); // just to update global status

  const handleChangeRule = useCallback(async (opt) => {
    setRule({operation: opt, minValue: 0, maxValue: 0});
  }, [setRule]);

  const handleChangeRuleValue = useCallback(async (val) => {
    setRule({...rule, minValue: val});
  }, [rule, setRule]);

  const handleChangeRuleBetweenValue = useCallback(async (pos, val) => {
    const temp = rule;
    if (pos === 'min') {
      setRule({...rule, minValue: val, maxValue: temp?.maxValue || 0});
    }
    else {
      setRule({...rule, minValue: temp?.minValue || 0, maxValue: val});
    }
  }, [rule, setRule]);

  const handleChangeRuleBetweenSlideValue = useCallback((val) => {
    setRule({...rule, minValue: val?.[0], maxValue: val?.[1]});
  }, [rule, setRule]);

  const onUpdate = useCallback(async () => {
    await update({...question, sectionId, questionId: question?._id, number: {...question?.number, ...rule}});
    setVisible(false);
  }, [question, rule, sectionId, update]);

  const renderRuleType = useMemo(() => {
    return (
      <Select defaultValue={rule?.operation === 'ruleNone' ? t(rule?.operation) : rule?.operation} className="w-full my-2" onChange={handleChangeRule}>
        <Option value="greaterThan">{t('ruleGreaterThan')}</Option>
        <Option value="lessThan">{t('ruleLessThan')}</Option>
        <Option value="equal">{t('ruleEqual')}</Option>
        <Option value="between">{t('ruleBetween')}</Option>
      </Select>
    );
  }, [handleChangeRule, rule?.operation, t]);

  const renderRuleValue = useMemo(() => {
    if (rule?.operation === 'between') {
      return (
        <div className="flex items-center justify-center w-full flex-col">
          <Slider
            className="w-full"
            range
            defaultValue={[rule?.minValue || min, rule?.maxValue || max]}
            min={min}
            max={max}
            value={[rule?.minValue || min, rule?.maxValue || max]}
            onChange={handleChangeRuleBetweenSlideValue}
          />

          <div className="flex items-center justify-center w-full my-2">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <InputNumber min={min} className="w-full" value={rule?.minValue} onChange={(val) => handleChangeRuleBetweenValue('min', val)} />
              </Col>
              <Col span={12}>
                <InputNumber max={max} className="w-full" value={rule?.maxValue} onChange={(val) => handleChangeRuleBetweenValue('max', val)} />
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <div className="flex items-center justify-center w-full">
        <InputNumber className="w-full my-2" min={0} max={99999} onChange={handleChangeRuleValue} />
      </div>
    );
  }, [handleChangeRuleBetweenSlideValue, handleChangeRuleBetweenValue, handleChangeRuleValue, max, min, rule?.maxValue, rule?.minValue, rule?.operation]);

  const content = useMemo(() => {
    return (
      <div style={{ width: 200 }}>
        {renderRuleType}
        {renderRuleValue}

        <Button className="w-full my-2" type="primary" onClick={onUpdate}>{t('ruleBtnConfirm')}</Button>
      </div>
    );
  }, [onUpdate, renderRuleType, renderRuleValue, t]);

  return (
    <div className="flex items-center justify-center cursor-pointer" style={{ color: 'var(--primary-color)' }}>
      <Popover placement="bottom" content={content} trigger="click" style={{width: 150}} visible={visible} onVisibleChange={setVisible}>
        <div className="flex items-center justify-center">
          <div className="mr-2">{t(getKeyToTranslate(rule?.operation))}</div>
          {rule?.operation === 'ruleNone'
            ? rule?.minValue
            : rule?.operation === 'between'
              ? <Text
                style={{ width: 100, color: 'var(--primary-color)' }}
                ellipsis={{ tooltip: `${rule?.minValue} - ${rule?.maxValue}` }}
              >
                {`(${rule?.minValue || min} - ${rule?.maxValue || max})`}
              </Text>
              : `(${rule?.minValue})`}
        </div>
      </Popover>
    </div>
  );
};

NumberFormatRules.propTypes = {
  rule: PropTypes.object,
  setRule: PropTypes.func,
  question: PropTypes.object,
  sectionId: PropTypes.string,
};

export default memo(NumberFormatRules);
