import {ReactComponent as LogoMin}  from '@/assets/images/logo.svg';
import {ReactComponent as Logo}  from '@/assets/images/logo.svg';

export const application = {
  name: 'BioAudit',
  logo: {
    src: Logo,
    srcMin: LogoMin
  },
};
