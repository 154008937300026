import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Tag} from 'antd';
import {
  STATUS_TASK,
  STATUS_TASK_APPEARANCE
} from '@/modules/templates/contants/audits/tasks';

export const TaskStatus = ({status, className, ...props}) => {
  const {t} = useTranslation('tasks');
  if (!STATUS_TASK[status])
    return (
      <Tag key={status} className={'w-16 text-center'}>
          -
      </Tag>);

  return (
    <Tag
      className={classNames('w-full rounded', className)}
      color={STATUS_TASK_APPEARANCE[status].color}
      key={status}
      {...props}
    >
      {t(`status.${status}`)}
    </Tag>
  );
};

TaskStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};

export default memo(TaskStatus);
