import RegistersModule from '@/modules/registers';
import MainModule from '@/modules/main';
import SettingsModule from '@/modules/settings';
import EmployeesModule from '@/modules/employees';
import TemplatesModule from '@/modules/templates';
import UsersModule from '@/modules/users';
import AnalyticsModule from '@/modules/analytics';
import AuditsModule from '@/modules/audits';
import ContractsModule from '@/modules/contracts';
import ClientsModule from '@/modules/clients';
import AdminModule from '@/modules/admin';

export default {
  Registers: {
    exact: false,
    path: '/registers',
    component: RegistersModule
  },
  Main: {
    exact: false,
    path: '/home',
    component: MainModule
  },
  Settings: {
    exact: false,
    path: '/settings',
    component: SettingsModule
  },
  Employees: {
    exact: false,
    path: '/employees',
    component: EmployeesModule
  },
  Templates: {
    exact: false,
    path: '/templates',
    component: TemplatesModule
  },
  Users: {
    exact: false,
    path: '/users',
    component: UsersModule
  },
  Analytics: {
    exact: false,
    path: '/analytics',
    component: AnalyticsModule
  },
  Audits: {
    exact: false,
    path: '/audits',
    component: AuditsModule
  },
  Contracts: {
    exact: false,
    path: '/contracts',
    component: ContractsModule
  },
  Clients: {
    exact: false,
    path: '/clients',
    component: ClientsModule
  },
  // Home: {
  //   exact: true,
  //   path: '/',
  //   component: null
  // },
  User: {
    exact: false,
    path: '/admin',
    component: AdminModule
  }
};
