import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SelectElementCmp from '@/utils/SelectElementCmp';
import NomenclaturesZonesApiService from '@/modules/settings/services/NomenclaturesZonesApiService';

const ClientEstablishmentZone = ({ value, onChange, ...props }) => {

  return (
    <SelectElementCmp
      queryKey={'establishment-zone-select'}
      service={NomenclaturesZonesApiService}
      includeValue={''}
      value={typeof value === 'object' ? value?._id : value}
      onChange={onChange}
      sort={{ code: 1 }}
      queryConfig={{ refetchOnWindowFocus: false }}
      className="custom-select"
      nameToShow={'community'}
      {...props}
    />
  );
};

ClientEstablishmentZone.propTypes = {
  value: PropTypes.string || PropTypes.object,
  onChange: PropTypes.func,
  filters: PropTypes.object
};

export default memo(ClientEstablishmentZone);

const ClientEstablishmentZoneView = ({value}) => {

  return (
    <div>
      {value?.community || 'N/A'}
    </div>
  );
};

ClientEstablishmentZoneView.propTypes = {
  value: PropTypes.string
};

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderClientEstablishmentZone = (text) => {
  return (
    <ClientEstablishmentZoneView value={text}/>
  );
};
