/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/9/21
 */
import React, {createContext, useContext, useState} from 'react';

const TableTreeDataContext = createContext({
  data: [],
  setData: val => val,
});

const useTableTreeData = () => {
  const context = useContext(TableTreeDataContext);
  if (context === undefined) {
    throw new Error('useTableTreeData must be used within a TableTreeDataProvider');
  }

  return context;
};

const TableTreeDataProvider = ({ ...props }) => {
  const [data, setData] = useState([]);

  return (
    <TableTreeDataContext.Provider
      value={{data, setData}}
      {...props}
    />
  );
};

export { useTableTreeData, TableTreeDataProvider };

