import {useMemo} from 'react';
import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import NomenclaturesTemplatesCategoryApiService from '@/modules/settings/services/NomenclaturesTemplatesCategoryApiService';
import {
  CACHE_KEY_LIST_NOMENCLATURES_TEMPLATES_CATEGORY,
  CACHE_KEY_NOMENCLATURES_TEMPLATES_CATEGORY
} from '@/modules/settings/constants/nomenclaturesTemplatesCategory';
import {fields} from '@/modules/settings/constants/nomenclaturesTemplatesCategory.form';
import {useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';

export const  useGetOneNomenclaturesTemplatesCategory = createGetOneHook(NomenclaturesTemplatesCategoryApiService, 'id', CACHE_KEY_NOMENCLATURES_TEMPLATES_CATEGORY);
export const useFields = createFieldsHook('nomenclaturesTemplatesCategory', fields);

export const useSubCategoryFields = (isViewMode) => {
  const fields = useFields(isViewMode);

  const {state} = useLocation();

  return useMemo(() => {
    const fieldsMap = [...fields];
    if (!isViewMode) {
      fieldsMap.splice(
        5, 0,
        {
          key: 'parentCategory',
          initialValue: state?.parentId,
          onlyEdit: true,
          className: 'hidden-field',
          widgetProps: {
            hidden: true
          }
        });

    }
    return fieldsMap;
  }, [fields, isViewMode, state.parentId]);
};

export const useGetChildren = (parentId) => {

  const queryConfig = useMemo(() => {
    return {enabled: !!(parentId)};
  }, [parentId]);

  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_LIST_NOMENCLATURES_TEMPLATES_CATEGORY, parentId], async () => {
    const {data} = await NomenclaturesTemplatesCategoryApiService.getChildren({parentId});
    return (data);
  }, queryConfig);

  return {isLoading, isError, error, data};
};

export const useGetAllCategories = () => {
  const {isLoading, isError, error, data} = useQuery([CACHE_KEY_LIST_NOMENCLATURES_TEMPLATES_CATEGORY], async () => {
    const {data} = await NomenclaturesTemplatesCategoryApiService.search();
    return (data);
  });

  return {isLoading, isError, error, data};
};
