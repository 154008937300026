import {EntityApiService, ApiClientService} from '@dofleini/security';

class TemplatesApiService extends EntityApiService {
  duplicate = (params) => {
    return ApiClientService.post(this.getPath(`/${params._id}/duplicate`), params);
  };

  getOneTemplate = (_id) => ApiClientService.get(this.getPath(`/${_id}`));

  reorder = (_id) => ApiClientService.post(this.getPath(`/${_id}/reorder`));

}

export default new TemplatesApiService('/ms-core/api/audit-templates');
