import {EntityApiService, ApiClientService} from '@dofleini/security';

class NomenclaturesTemplatesCategoryApiService extends EntityApiService {

    search = (params) => ApiClientService.post(this.getPath('/search'), {...params, type: 'ALL'});

    getFamily = (params) => ApiClientService.post(this.getPath('/search-categories-subcategories'), {...params, type: 'ALL'});

    getChildren = ({parentId, ...params}) => ApiClientService.post(this.getPath(`/${parentId}/children`), {...params, type: 'ALL'});

}

export default new NomenclaturesTemplatesCategoryApiService('/ms-core/api/categories');
