import React from 'react';
import ReactDOM from 'react-dom';
import '@/assets/tailwindcss/styles.css';
import '@/assets/less/index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MainProvider from '@/contexts/MainProvider';
import * as dotenv from 'dotenv';

dotenv.config();

ReactDOM.render(
  <MainProvider><App/></MainProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to registers() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
