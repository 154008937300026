import React, {memo} from 'react';
import AdminHeader from '@/components/TableGrid/TableHeader';
import PropTypes from 'prop-types';
import TableData from '@/components/TableGrid/TableData';
import {Card} from 'antd';
import {TableContextProvider} from '@/contexts/tableGridContact';
import EntityMenu from '@/components/EntityMenu';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {ADD_HANDLER_DIALOG} from '@/utils/createEntityContainer';

const TableGrid = ({
  tableTitle,
  extras,
  columns,
  useTableData,
  canSelect,
  rowActions,
  actionsLabel,
  addLabel,
  translation,
  selectedActions,
  filters,
  canAdd,
  showTitle,
  route,
  useCreatePath,
  useMapColumns,
  searchPlaceholder,
  canSearch,
  hasTable = true,
  actionsViewByRoute,
  actions,
  onAddModalName,
  withDynamicColumns,
  useDynamicColumns = () => ({cols: []}),
  ...props
}) => {
  const {openDialog} = useDialogsContext(onAddModalName || module || ADD_HANDLER_DIALOG);

  const {cols} = useDynamicColumns();

  if (rowActions && !columns.some(c => c.dataIndex === 'actions')) {
    columns.push(
      {
        title: actionsLabel,
        dataIndex: 'actions',
        width: 100,
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
          <EntityMenu data={record}/>
        )
      }
    );
  }

  return (
    <Card className={'responsive-card w-full'}>
      <TableContextProvider columns={withDynamicColumns ? cols : columns} translation={translation} useMapColumns={useMapColumns}>
        <AdminHeader
          title={tableTitle}
          onAdd={() => openDialog()}
          useCreatePath={useCreatePath}
          route={route}
          addLabel={addLabel}
          extras={extras}
          hasTable={hasTable}
          canAdd={canAdd}
          showTitle={showTitle}
          canSearch={canSearch}
          filters={filters}
          translation={translation}
          searchPlaceholder={searchPlaceholder}
          actionsViewByRoute={actionsViewByRoute}
          actions={actions}
        />
        <TableData
          selectedActions={selectedActions}
          tableHook={useTableData}
          canSelect={canSelect}
          {...props}/>
      </TableContextProvider>
    </Card>
  );

};

TableGrid.propTypes = {
  actionsLabel: PropTypes.string,
  addLabel: PropTypes.string,
  canSelect: PropTypes.bool,
  canSearch: PropTypes.bool,
  hasTable: PropTypes.bool,
  columns: PropTypes.array,
  extras: PropTypes.node,
  filters: PropTypes.any,
  onAdd: PropTypes.func,
  route: PropTypes.string,
  rowActions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  selectedActions: PropTypes.func,
  tableHook: PropTypes.func,
  tableTitle: PropTypes.string.isRequired,
  translation: PropTypes.string,
  translationPrefix: PropTypes.string,
  useCreatePath: PropTypes.bool,
  useTableData: PropTypes.func.isRequired,
  canAdd: PropTypes.bool,
  showTitle: PropTypes.bool,
  useMapColumns: PropTypes.any,
  searchPlaceholder: PropTypes.any,
  actionsViewByRoute: PropTypes.bool,
  actions: PropTypes.array,
  onAddModalName: PropTypes.string,
  withDynamicColumns: PropTypes.bool,
  useDynamicColumns: PropTypes.func,
};

TableGrid.defaultProps = {
  canAdd: true,
  showTitle: true,
  canSearch: true
};

export default memo(TableGrid);
