import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';

const REFRESH_TOKEN_KEY = '__refresh_token__';
const SPACE_KEY = '__space__';
const EXPIRE_DATE_KEY = '__expire_date__';
export const setAuthData = (data = {}) => {
  const {accessToken, refreshToken, space} = data;
  window.localStorage.setItem(ApiClientService.ACCESS_TOKKEN_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  window.localStorage.setItem(SPACE_KEY, space);
  window.localStorage.setItem(EXPIRE_DATE_KEY, new Date().toISOString());
};

export const handleUserResponse = ({data}) => {
  setAuthData(data);
  return data;
};


class TokenService {

  constructor() {
    this.refreshPromiseSingleton = null;
  }

    refresh = () => {
      console.log('--------------------------------');
      console.log('TokenService');
      if (!this.refreshPromiseSingleton) {
        const token = ApiClientService.getToken();
        const refresh = window.localStorage.getItem(REFRESH_TOKEN_KEY);
        const space = ApiClientService.getSpace();

        this.refreshPromiseSingleton = ApiClientService.post(`${authentication.path}/api/refresh-token`, {
          token,
          refresh,
          space
        }, {ignoreToken: true});

        this.refreshPromiseSingleton.then((data) => {
          handleUserResponse(data);
          this.refreshPromiseSingleton = null;
          return data;
        });

        this.refreshPromiseSingleton.catch((e) => {
          console.log('TokenService refreshPromiseSingleton error',);
          this.refreshPromiseSingleton = null;
          return e;
        });
      }
      return this.refreshPromiseSingleton;
    };
}

export default new TokenService();
