import React, {memo, /*useCallback,*/ useMemo} from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const AuditsDateRangeCell = ({value, onChange, startDate}) => {

  /* const customStartDate = useMemo(() => {
    let newDate = moment().format('DD/MM/YYYY');
    if (moment(startDate) > moment()) {
      newDate = moment(startDate).set('date', 1);
    } else {
      newDate = moment(startDate).set('date',  parseInt(moment().format('DD')));
    }
    return newDate;
  }, [startDate]);*/

  /*  const disabledDate = useCallback((current) => {
    // Can not select days before today and today
    return current && current < moment(customStartDate).startOf('day');
  }, [customStartDate]);*/

  const initialValue = useMemo(() => {
    if (!value?.length) return [];
    return [moment(value?.[0]), moment(value?.[1])];
  }, [value]);

  return (
    <RangePicker autoFocus={startDate} /*disabledDate={disabledDate}*/ value={initialValue} onChange={onChange} style={{ width: '100%' }} format="DD-MM-YYYY"/>
  );
};

AuditsDateRangeCell.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  startDate: PropTypes.string,
};

AuditsDateRangeCell.defaultProps = {
  value: [],
};

export default memo(AuditsDateRangeCell);


// eslint-disable-next-line react/prop-types
export const renderAuditDateRangeCell = ({formItemProps, form, field}) => {
  const startDate = form.getFieldValue('startDate');

  return (
  // eslint-disable-next-line react/prop-types
    <Form.Item {...formItemProps} required={field?.required}>
      <AuditsDateRangeCell startDate={startDate} />
    </Form.Item>
  );
};

