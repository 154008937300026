/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 28/9/21
 */
import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Form, Typography } from 'antd';
import {useTranslation} from 'react-i18next';
import DeleteBin5LineIcon from 'remixicon-react/DeleteBin5LineIcon';
import styles from './index.less';

const { Option } = Select;
const { Text } = Typography;

const pattern = '^\\+(?:[0-9]●?){6,14}[0-9]$';

const DynamicPhones = ({text, isViewMode = false, required, mixData = false}) => {
  const {t} = useTranslation('common');

  const prefixSelector = useCallback((field) => (
    <Form.Item {...field} name={[field.name, 'type']} fieldKey={[field.fieldKey, 'type']} initialValue={'mobile'}>
      <Select style={{ width: 120, backgroundColor: '#F3F5F7', borderColor: '#d9d9d9' }}>
        <Option value="mobile">{t('dynamicPhones.mobile')}</Option>
        <Option value="home">{t('dynamicPhones.home')}</Option>
        <Option value="work">{t('dynamicPhones.work')}</Option>
        <Option value="other">{t('dynamicPhones.other')}</Option>
      </Select>
    </Form.Item>
  ), [t]);

  const getPhones = useMemo(() => {
    if (text?.length === 0) {
      return null;
    }
    return (
      <div className="flex items-center justify-start flex-wrap">
        <div className="font-black mr-2">{t('phones')}:</div>
        {text?.map((phone, idx) => {return<div key={idx} className="flex items-center justify-start">
          <div className="mr-1 font-black">{t('dynamicPhones.' + phone?.type)}:</div>
          <div className="mr-4">{phone?.phone}</div>
        </div>;
        })}
      </div>
    );
  }, [text, t]);

  if (isViewMode && mixData) {
    return getPhones;
  }

  if (isViewMode) {
    return (
      <div className="flex items-center justify-start">
        <div>{text?.map(x => t(x.type)+': '+ x.phone)?.join(' / ') || 'N/A'}</div>
      </div>
    );
  }

  return (
    <Form.List name="phones">
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <div key={field.key} className="mb-3 flex items-start justify-center">
              <Form.Item
                {...field}
                name={[field.name, 'phone']}
                fieldKey={[field.fieldKey, 'phone']}
                className="mb-0 w-full"
                rules={[{
                  required: !!required,
                  whitespace: !!required,
                  message: t('dynamicPhones.missingPhone'),
                }, {
                  required: false,
                  pattern,
                  message: t('dynamicPhones.notValidNumber'),
                }
                ]}
              >
                <Input addonBefore={prefixSelector(field)} style={{ width: '100%' }} className={`${styles.customInput} custom-input-phone-numbers`} />

              </Form.Item>
              <div className={styles.wrapperRemove}>
                <DeleteBin5LineIcon className={`ml-2 ant-icon ${styles.removeIcon}`} onClick={() => remove(field.name)} />
              </div>
            </div>
          ))}
          <Text className={styles.actionBtn} onClick={() => add()}>{t('dynamicPhones.addPhone')}</Text>
        </>
      )}
    </Form.List>
  );
};

DynamicPhones.propTypes = {
  text: PropTypes.array,
  isViewMode: PropTypes.bool,
  required: PropTypes.bool,
  mixData: PropTypes.bool,
};

DynamicPhones.defaultProps = {
  required: true
};

export default memo(DynamicPhones);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the DynamicPhones component in a table cell
 * @param {String} text this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} entity this is the row instance
 * */
export const renderPhones = (text, entity) => {
  return (
    <DynamicPhones entity={entity} isViewMode text={text}/>
  );
};
