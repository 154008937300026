import React from 'react';
import { /*BellFilled,*/ ExportOutlined, UserOutlined } from '@ant-design/icons';
import { useHandleClick } from '@/components/UserOptions/hooks';
import { USER_ACTION_TYPE } from '@/components/UserOptions/constants';
import Home6LineIcon from 'remixicon-react/Home6LineIcon';
// import FileTextLineIcon from 'remixicon-react/FileTextLineIcon';
import Briefcase2LineIcon from 'remixicon-react/Briefcase2LineIcon';
// import FileList3LineIcon from 'remixicon-react/FileList3LineIcon';
import SurveyLineIcon from 'remixicon-react/SurveyLineIcon';
import BubbleChartLineIcon from 'remixicon-react/BubbleChartLineIcon';
import UserSettingsLineIcon from 'remixicon-react/UserSettingsLineIcon';
import Layout3LineIcon from 'remixicon-react/Layout3LineIcon';
import ContactsBook2LineIcon from 'remixicon-react/ContactsBook2LineIcon';
import Settings3LineIcon from 'remixicon-react/Settings3LineIcon';

/**
 * Navigation define all the routes and link that
 * will be render in the sidebar or navbar(depending of the layout)
 *
 * It can be an
 * {Array} Use array when yo have group sections, like Main, Admin , etc
 * Ex: [{title:'name', menu:[...],}, ....]
 *
 * {Object} Use object when yo don't have section , it is just one,
 * Ex: {menu: [.....]}
 * */
export const navigation = {
  menu: [
    {
      title: 'home',
      exact: 'true',
      link: '/home',
      permissions: ['USER'],
      icon: <Home6LineIcon className="antd-icon"/>,
    },
    {
      title: 'clients',
      exact: 'true',
      link: '/clients',
      permissions: ['CLIENTS:MANAGEMENT'],
      icon: <Briefcase2LineIcon className="antd-icon"/>
    },
    // {
    //   title: 'contracts',
    //   exact: 'true',
    //   link: '/contracts',
    //   permissions: ['CONTRACT:READ'],
    //   icon: <FileList3LineIcon className="antd-icon"/>
    // },
    {
      title: 'audits',
      exact: 'true',
      link: '/audits',
      permissions: ['AUDIT:READ'],
      icon: <SurveyLineIcon className="antd-icon"/>
    },
    // {
    //   title: 'registers',
    //   exact: 'true',
    //   link: '/registers',
    //   permissions: ['REGISTER:READ'],
    //   icon: <FileTextLineIcon className="antd-icon"/>
    // },
    {
      title: 'analytics',
      exact: 'true',
      link: '/analytics',
      permissions: ['ANALYTICS:READ'],
      icon: <BubbleChartLineIcon className="antd-icon"/>
    },
    {
      title: 'users',
      exact: 'true',
      link: '/users',
      permissions: ['ADMIN'],
      icon: <UserSettingsLineIcon className="antd-icon"/>,
      menu: [
        {
          title: 'users',
          exact: 'true',
          link: '/users/users',
          permissions: ['USER:MANAGEMENT'],
        },
        {
          title: 'rolesAndPermissions',
          exact: 'true',
          link: '/users/roles-and-permissions',
          permissions: ['USER:MANAGEMENT'],
        }
      ]
    },
    {
      title: 'templates',
      link: '/templates',
      permissions: ['TEMPLATE:MANAGEMENT'],
      icon: <Layout3LineIcon className="antd-icon"/>,
      menu: [
        {
          title: 'auditTemplates',
          exact: 'true',
          link: '/templates/audit-type/audit',
          permissions: ['TEMPLATE:MANAGEMENT'],
        },
        // {
        //   title: 'registerTemplates',
        //   exact: 'true',
        //   link: '/templates/register-type/register',
        //   permissions: ['TEMPLATE:MANAGEMENT'],
        // }
      ]
    },
    {
      title: 'employees',
      exact: 'true',
      link: '/employees',
      permissions: ['WORKER:READ'],
      icon: <ContactsBook2LineIcon className="antd-icon"/>
    },
    {
      title: 'settings',
      // exact: 'true',
      link: '/settings',
      // permissions: ['SERVICES:READ', 'SOCIAL_REASON:READ', 'PROFILE-WORKER:READ', 'ACTIVITY:READ', 'BILLING-METHOD:READ', 'ENTERPRISE:READ', 'CATEGORY:READ', 'TEMPLATES:READ', 'ENTERPRISE:READ'],
      permissions: ['NOMENCLATURES:MANAGEMENT', 'SERVICES:READ', 'SOCIAL_REASON:READ', 'API:READ'],
      icon: <Settings3LineIcon className="antd-icon"/>,
      menu: [
        // {
        //   title: 'apiKeys',
        //   exact: 'true',
        //   link: '/settings/apikeys',
        //   permissions: ['API:READ'],
        // },
        {
          title: 'services',
          exact: 'true',
          link: '/settings/services',
          permissions: ['SERVICES:READ'],
        },
        {
          title: 'socialReasons',
          exact: 'true',
          link: '/settings/social-reason',
          permissions: ['SOCIAL_REASON:READ'],
        },
        {
          title: 'nomenclatures',
          exact: 'true',
          link: '/settings/nomenclatures',
          // permissions: ['PROFILE-WORKER:READ', 'PAYMENT_TYPE:READ', 'TYPE_AREA:READ', 'ACTIVITY:READ', 'BILLING-METHOD:READ', 'CATEGORY:READ', 'ENTERPRISE:READ'],
          permissions: ['NOMENCLATURES:MANAGEMENT'],
        },
        // {
        //   title: 'parameters',
        //   exact: 'true',
        //   link: '/settings/parameters',
        //   permissions: ['PARAMETER:READ'],
        // },
      ]
    },
  ]
};

export const userOptions = {
  // options: [
  //   {
  //     title: 'notifications',
  //     action: 'NOTIFICATIONS',
  //     icon: <BellFilled/>
  //   }
  // ],
  userActions: {
    viewType: USER_ACTION_TYPE.POPUP,
    showInfo: true,
    options: [
      {
        divider: true
      },
      {
        title: 'profile',
        action: 'PROFILE',
        icon: <UserOutlined/>,
      },
      {
        divider: true
      },
      {
        title: 'logout',
        action: 'LOGOUT',
        icon: <ExportOutlined/>,
      }
    ],
  },
  useHandleClick
};
