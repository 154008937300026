import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Form, Input, Spin} from 'antd';
import FilterContent from '../../FilterContent';
import classNames from 'classnames';
import map from 'lodash/map';
import find from 'lodash/find';
import reduceFP from 'lodash/fp/reduce';
import compose from 'lodash/fp/compose';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import {TermFilter} from '@dofleini/query-builder';
import {useQuery} from 'react-query';
import createSearchService from '@/utils/createSearchService';

const saveTemp = (key) => (value) => localStorage.setItem(key, JSON.stringify(value));

const getTemp = (key) => JSON.parse(localStorage.getItem(key));

export const RemoteDataComponent = ({filter, t, applyFilter}) => {
  const [searchText, setSearchText] = useState('');

  const [valueKey, labelKey] = useMemo(() => [
    filter?.valueKey || '_id',
    filter?.labelKey || 'name',
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  const searchService = useMemo(() => createSearchService(filter.request), [filter.request]);

  const {isLoading, data} = useQuery([`filter-${filter?.name}`, searchText, filter.filters], async () => {
    const {data} = await searchService({size: 50, search: searchText, filters: filter.filters || {}});
    return (data);
  });

  const options = useMemo(() => map(data, item => {
    const value = item[valueKey];
    const label = typeof labelKey === 'function' ? labelKey(item) : item[labelKey];
    return {
      label,
      value,
    };
  }), [data, labelKey, valueKey]);

  const onFinish = useCallback(({value: values}) => {
    compose(
      saveTemp(`filter-${filter?.name}`),
      reduceFP((acc, value) => ({
        ...acc,
        [value]: find(options, {value})?.label
      }), {}),
    )(values);

    applyFilter(
      {
        'type': 'OR',
        'filters': map(values, value => new TermFilter({...filter, value }).toQuery())
      }
    );
  }, [applyFilter, filter, options]);

  useEffect(() => () => localStorage.removeItem(`filter-${filter?.name}`), [filter.name]);

  return (<>
    <div className="p-2">
      <Input suffix={<SearchOutlined/>} value={searchText} onChange={({target}) => setSearchText(target?.value)}/>
    </div>
    <Form onFinish={onFinish}>
      <FilterContent t={t}>
        {
          isLoading
            ? (
              <div className="flex items-center justify-center py-8">
                <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
              </div>
            )
            : (
              <Form.Item name="value" className="m-0">
                <Checkbox.Group className="w-full">
                  <div className="w-full flex flex-col max-h-300px overflow-y-auto">
                    {
                      options.map(({value, label}) => (
                        <Checkbox className={classNames('mx-0 my-px p-2')} key={value} value={value}>
                          {label}
                        </Checkbox>
                      ))
                    }
                  </div>
                </Checkbox.Group>
              </Form.Item>
            )
        }
      </FilterContent>
    </Form>
  </>);
};

RemoteDataComponent.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueRemoteData = ({filterValue, filter}) => {
  const mapOptions = getTemp(`filter-${filter?.name}`);

  const options = useMemo(() => map(
    filterValue.filters, ({value}) => mapOptions?.[value]
  ).join(', '), [filterValue, mapOptions]);

  return <p className="m-0 break-world leading-snug whitespace-pre-wrap">{options}</p>;
};

ValueRemoteData.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
};
