import React, {memo} from 'react';
import MainRouter from './MainRouter';
import MainLayout from '@/layout/MainLayout';
import 'antd/es/slider/style';
import NewEmailDialog from '@/components/NewEmailDialog';
import {RenderViewTypeProvider} from '@/hooks/useRenderViewType';
import {CurrentClientProvider} from '@/hooks/useCurrentClient';
import {AsyncBreadcrumbsProvider} from '@/hooks/useAsyncBreadcrumbs';
import {ClientFilterProvider} from '@/hooks/useClientFilter';
import {AuditDataProvider} from '@/modules/audits/hooks/useAuditData';

const MainApp = () => {
  return (
    <AsyncBreadcrumbsProvider>
      <CurrentClientProvider>
        <ClientFilterProvider>
          <AuditDataProvider>
            <RenderViewTypeProvider>
              <MainLayout>
                <MainRouter/>
                <NewEmailDialog/>
              </MainLayout>
            </RenderViewTypeProvider>
          </AuditDataProvider>
        </ClientFilterProvider>
      </CurrentClientProvider>
    </AsyncBreadcrumbsProvider>
  );
};

export default memo(MainApp);
