/**
 * @author: Angel Labrada Massó
 * @project: biolinea-backoffice
 * @entity: SelectCategoryColor
 * @version: v0.0.1
 * @date: 7/14/22
 */
import React, {memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
// import {Popover} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import { SketchPicker } from 'react-color';
import styles from './index.less';
import {hexToRgb} from '@/utils/utils';

const colors = [
  '#9c6d1e','#fe8500','#ffb000','#81b532',
  '#13855f','#00b6cb','#648fff','#0044a3',
  '#c22dd5','#dc267f','#707070','#c60022', ''
];

const SelectCategoryColor = ({value, onChange}) => {
  const [color, setColor] = useState({
    displayColorPicker: false,
    hex: value || '',
    color: value ? hexToRgb(value) : {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
  });
  const [colorTemp, setColorTemp] = useState(!colors?.includes(value) ? value : '');

  const handleClick = useCallback(() => {
    setColor({ displayColorPicker: !color?.displayColorPicker });
  }, [color?.displayColorPicker]);

  const handleClose = useCallback(() => {
    setColor({ displayColorPicker: false });
  }, []);

  const handleChange = useCallback((color) => {
    if (!color.rgb) {
      const rgb = hexToRgb(color.hex);
      setColor(prev => ({ ...prev, color: rgb, hex: color.hex }));
      setColorTemp('');
    }
    else {
      setColor(prev => ({...prev, color: color.rgb}));
      setColorTemp(color.hex);
    }
    onChange(color.hex);
  }, [onChange]);

  return (
    <div className="mb-4">
      <div className="flex flex-wrap gap-2 w-full">
        {colors?.map((bg, index) => {

          if (index === 12) {
            return (
              <div
                key={index}
                className="flex items-center justify-center"
                style={{
                  width: '36px',
                  height: '36px',
                  borderRadius: '36px',
                  border: colorTemp ? '2px solid' + colorTemp :'2px solid transparent',
                }}
                onClick={ handleClick }>
                <div
                  className="cursor-pointer flex items-center justify-center"
                  style={{
                    background: colorTemp ? colorTemp : '#ccc',
                    width: '28px',
                    height: '28px',
                    borderRadius: '28px',
                  }}
                >
                  <PlusCircleOutlined style={{ color: '#333', fontSize: '1rem', background: '#fff', borderRadius: '1.1rem', padding: '2px'}} />
                </div>
              </div>
            );
          }

          return (
            <div
              key={index}
              className="flex items-center justify-center"
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '36px',
                border: color?.hex === bg ? '2px solid' + bg : '2px solid transparent',
              }}
            >
              <div
                className={classNames('cursor-pointer')}
                style={{
                  background: bg,
                  width: '28px',
                  height: '28px',
                  borderRadius: '28px',
                }}
                onClick={() => handleChange({hex: bg, displayColorPicker: false})}
              />
            </div>
          );
        })}
      </div>


      { color.displayColorPicker ? <div className={ styles.popover }>
        <div className={ styles.cover } onClick={ handleClose }/>
        <SketchPicker color={ color.color } onChange={ handleChange } />
      </div> : null }
    </div>
  );
};

SelectCategoryColor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(SelectCategoryColor);

/**
 * cellRender
 * */
/**
 * This is not a components,
 * it is a function to render the Email components in a table cell
 * @param {Object} text this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderSelectColor = (text) => {
  return (
    <SelectCategoryColor entity={text}/>
  );
};
