import React, {memo, useState, useCallback, useMemo} from 'react';
import {PlusOutlined, FileImageOutlined, DeleteOutlined} from '@ant-design/icons';
import {Upload, Modal, Spin} from 'antd';
import useUploadImage from '@/components/UploadImage/useUploadImage';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.less';
import EditableField from '@/modules/templates/components/Audits/TemplateDetailsPicturesWall/EditableField';

import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const PicturesWall = ({translation, fileList, setFileList}) => {
  const {t} = useTranslation(translation);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const {props} = useUploadImage();

  // const mappingFileList = useMemo(() => {
  //   return fileList?.map(x => ({...x, name: <div className="flex items-start justify-center flex-col custom-picture-item">
  //     <div className="custom-picture-item-name">{x?.name}</div>
  //     <div className="custom-picture-item-note">Nota: jejej</div>
  //   </div>}));
  // }, [fileList]);

  const handleCancel = useCallback(() => setPreviewVisible(false),[]);

  const handlePreview = useCallback(async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  }, []);

  const handleChange = useCallback(({ fileList }) => setFileList( fileList ), [setFileList]);

  const uploadButton = useMemo(() => {
    return (
      <div className={styles.uploadPictureButton}>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>{t('details.upload')}</div>
      </div>
    );}, [t]);


  return (
    <div className={styles.wrapperPictures}>
      <Upload
        {...props}
        listType="picture"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        className="upload-list-inline"
        itemRender={(originNode, file, currFileList, actions) => {
          if (file?.status === 'error') {
            return (
              <div className="flex items-center justify-start wrapper-picture-item-error">
                <div className="mr-2">
                  <FileImageOutlined style={{fontSize: '24px', color: '#ff4d4f'}} />
                </div>
                <div className="flex items-start justify-center flex-col custom-picture-item w-full">
                  <div className="custom-picture-item-name">{t('errorToUpload')}</div>
                  <div className="custom-picture-item-note flex items-center justify-center">
                    <div className="mr-2">{t('tryAgain')}</div>
                  </div>
                </div>
                <div className={classNames('flex items-center justify-center', styles.iconDelete)}>
                  <DeleteOutlined style={{ fontSize: '1rem' }} onClick={() => actions?.remove()} />
                </div>
              </div>
            );
          }

          return (
            <Spin tip={t('uploadingImage')} indicator={antIcon} spinning={file?.status === 'uploading'}>
              <div className="flex items-center justify-start wrapper-picture-item">
                <div className="mr-2">
                  {file?.status === 'uploading' ? <FileImageOutlined style={{fontSize: '24px'}} /> : <img src={file?.response?.image} alt={t('evidence')} style={{width: '48px', height: '48px'}}/>}
                </div>
                <div className="flex items-start justify-center flex-col custom-picture-item w-full">
                  <div className="custom-picture-item-name">{t('evidence')} {currFileList.findIndex(x => x?.uid === file?.uid) + 1}</div>
                  <div className="custom-picture-item-note flex items-center justify-center">
                    <div className="mr-2">{t('note')}:</div>
                    <EditableField value={file?.note} key={file?.uid} onChange={() => null} />
                  </div>
                </div>
                <div className={classNames('flex items-center justify-center', styles.iconDelete)}>
                  <DeleteOutlined style={{ fontSize: '1rem' }} onClick={() => actions?.remove()} />
                </div>
              </div>
            </Spin>
          );
        }}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

PicturesWall.propTypes = {
  translation: PropTypes.string,
  fileList: PropTypes.array,
  setFileList: PropTypes.func
};

export default memo(PicturesWall);
