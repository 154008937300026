import SelectTemplateCategory, {renderTemplateCategory} from '@/modules/templates/components/Audits/SelectTemplateCategory';
import {renderDateView} from '@/modules/templates/components/Audits/TemplateViewDate';
import {renderTemplatesName} from '@/modules/templates/components/Audits/TemplatesNameCell';
import {renderTemplatesDescription} from '@/modules/templates/components/Audits/TemplatesDescriptionCell';
import  {renderTemplateSections} from '@/modules/templates/components/Audits/TemplateSectionsDrawer';
import SelectTemplateLanguage from '@/modules/templates/components/Audits/SelectTemplateLanguage';

export const fields = [
  {
    key: 'name',
    label: 'form.title',
    placeholder: 'form.placeholderTemplateTitle',
    required: true,
    onlyEdit: true,
  },
  {
    key: 'name',
    onlyView: true,
    renderView: renderTemplatesName
  },
  {
    key: 'description',
    onlyView: true,
    renderView: renderTemplatesDescription
  },
  {
    key: 'category',
    label: 'form.category',
    placeholder: 'form.placeholderTemplateCategory',
    renderView: renderTemplateCategory,
    required: true,
    widget: SelectTemplateCategory,
    onlyEdit: true,
    colSpan: 1.5
  },
  {
    key: 'language',
    label: 'form.language',
    required: true,
    widget: SelectTemplateLanguage,
    // className: 'form-view-mode-vertical-item',
    onlyEdit: true,
    colSpan: 0.5,
    initialValue: 'es'
  },
  // {
  //   key: 'access',
  //   label: 'form.access',
  //   placeholder: 'form.placeholderTemplateAccess',
  //   widget: 'select',
  //   className: 'custom-select',
  //   options: accessOptions
  // },
  {
    key: 'description',
    label: 'form.description',
    placeholder: 'form.placeholderTemplateDescription',
    widget: 'textarea',
    widgetProps: {autoSize: {minRows: 5, maxRows: 12}},
    className: 'form-view-mode-vertical-item',
    onlyEdit: true
  },
  {
    key: 'sections',
    className: 'mix-sections-cmp',
    onlyView: true,
    renderView: renderTemplateSections,
  },
  {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    dataIndex: 'createdAt',
    responsive: ['lg'],
    renderView: renderDateView,
    className: 'hidden'
  },
  {
    key: 'updatedAt',
    label: 'updatedAt',
    type: 'date',
    dataIndex: 'updatedAt',
    responsive: ['lg'],
    renderView: renderDateView,
    className: 'hidden'
  },
];
