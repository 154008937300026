/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 21/11/21
 */
import React, {memo, useState, useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import StackFillIcon from 'remixicon-react/StackFillIcon';
import classNames from 'classnames';
import styles from './index.less';
import {useTranslation} from 'react-i18next';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import {useHistory} from 'react-router-dom';
import SectionBox from '@/modules/templates/components/Audits/TemplateSectionsDrawer/components/SectionBox';
import BookmarksPreview from '@/modules/templates/components/Audits/TemplatePreview/components/BookmarksPreview';
import {useInfinite} from '@/utils/createInfiniteHook';
import {CACHE_KEY_LIST_SECTIONS, TYPE_SECTION} from '@/modules/templates/contants/audits/sections';
import { useInfiniteScroll } from '@dofleini/use-scroll';
import SectionsApiService from '@/modules/templates/services/audits/SectionsApiService';
import {OperatorFilter, TermFilter} from '@dofleini/query-builder';

const SERVER_PAGE = 35;

const TemplateSections = ({entity}) => {
  const {t} = useTranslation('templates');
  const [visible, setVisible] = useState(false);
  const [dataIntro, setDataIntro] = useState([]);
  const [dataSections, setDataSections] = useState([]);
  const {push} = useHistory();

  const showDrawer = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const closeDrawer = useCallback(() => {
    setVisible(false);
  }, []);


  const handleEditTemplate = useCallback(() => {
    push(`/templates/manage/audit-type/${entity?._id}`);
  }, [entity?._id, push]);

  const filters = useMemo(() => new OperatorFilter({
    type: 'AND', filters: [
      new TermFilter({field: 'template', value: entity?._id})
    ]
  }), [entity?._id]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isSuccess,
    onLoadCompleted
  } = useInfinite({
    key: [CACHE_KEY_LIST_SECTIONS, filters],
    service: SectionsApiService.search,
    filters,
    sort: '_id',
    queryConfig: { size: Math.max(25, SERVER_PAGE) },
  });

  useInfiniteScroll({
    fromWindow: false,
    hasNext: hasNextPage && !(isFetching || isLoading),
    onLoadMore: fetchNextPage
  });

  useEffect(() => {
    if (isSuccess) {
      onLoadCompleted && onLoadCompleted(data);
      setDataIntro(data?.filter((x) => x.type === TYPE_SECTION.DATA));
      setDataSections(data?.filter((x) => x.type !== TYPE_SECTION.DATA));
    }
  }, [data, isSuccess, onLoadCompleted]);

  const onScroll = useCallback((event) => {
    const target = event.target;
    if (!(isLoading && isFetching) && target.scrollTop + target.offsetHeight >= (target.scrollHeight - 100) && hasNextPage) {
      fetchNextPage();
    }
  }, [isLoading, isFetching, hasNextPage, fetchNextPage]);


  const [activeSection, setActiveSection] = useState(data?.[0]);

  useEffect(() => {
    if (document.querySelector(`#section-${activeSection?._id}`)) {
      document.querySelector(`#section-${activeSection?._id}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [activeSection?._id]);

  return (
    <div className={classNames('w-full', styles.wrapperEditablePreview)}>
      <div className="section-play-and-edit" onClick={closeDrawer}>
        <Button className="flex" type="primary" size="large" icon={<EditLineIcon />} onClick={handleEditTemplate}>
          <span className="ml-2">{t('templateEditor')}</span>
        </Button>
      </div>

      <div className={classNames(styles.wrapperSidebarBookMarkDrawer, 'w-full h-full')}>
        <div className="sidebar-drawer" onBlur={() => {
          if (visible) {
            closeDrawer();
          }
        }}>
          <div className={styles.bookMark}>
            <div className={styles.bookMarkBar} onClick={showDrawer}>
              {t('audits.navigation')}
              <StackFillIcon style={{transform: 'rotate(180deg)', margin: '10px 0 0 0', padding: '2px'}} />
            </div>
          </div>
          <div className={classNames(styles.sidebarBookMarkDrawer, visible ? styles.animateSectionsDrawer : styles.hiddenSectionsDrawer)} id="sidebarBookMarkDrawer">
            <BookmarksPreview
              currentTemplate={entity}
              data={data}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              onScroll={onScroll}
            />
          </div>
        </div>

        <div className={classNames('p-4 ml-10', styles.sectionsDrawerContent)} onClick={() => setVisible(false)}>
          {
            dataIntro?.map((item) => {
              return (
                <SectionBox key={item?._id} section={item} id={'section-'+item?._id}/>
              );
            })
          }
          {
            dataSections?.map((item) => {
              return (
                <SectionBox key={item?._id} section={item} id={'section-'+item?._id}/>
              );
            })
          }
          <div className="w-full m-6 just-to-spacing" />
        </div>
      </div>
    </div>
  );
};

TemplateSections.propTypes = {
  entity: PropTypes.object,
};

export default memo(TemplateSections);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Code component in a table cell
 * @param {String} text this is the value of the dataIndex of the table
 * @param {Object} form
 * @param {Object} initialValues
 * */
export const renderTemplateSections = (text, form, initialValues) => {

  return (
    <TemplateSections entity={initialValues}/>
  );
};
