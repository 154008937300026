/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 22/9/21
 */
import React, {memo} from 'react';
import {Button, Checkbox, Dropdown, Menu, Space, Tooltip} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {TABLE_SIZE, useTableGrid} from '@/contexts/tableGridContact';
import {useTranslation} from 'react-i18next';

const {SubMenu} = Menu;

const TableMenu = () => {
  const {handleChange, size, visibleColumns, handleDeletedCols, toggleAllHidden, columns} = useTableGrid();
  const {t} = useTranslation('table');
  const someColumnHidden = visibleColumns.length > 0 && visibleColumns.length !== columns.length;
  const allVisible = visibleColumns.length === columns.length;

  const menu = (
    <Menu onClick={handleChange}>
      <SubMenu title={t('tableSize')}>
        <Menu.Item key={TABLE_SIZE.big}>
          <div className={'w-40'}>
            <Checkbox checked={size === TABLE_SIZE.big}> {t('big')}</Checkbox>
          </div>
        </Menu.Item>
        <Menu.Item key={TABLE_SIZE.middle}>
          <div className={'w-40'}>
            <Checkbox checked={size === TABLE_SIZE.middle}> {t('medium')}</Checkbox>
          </div>
        </Menu.Item>
        <Menu.Item key={TABLE_SIZE.small}>
          <div className={'w-40'}>
            <Checkbox checked={size === TABLE_SIZE.small}> {t('small')}</Checkbox>
          </div>
        </Menu.Item>
      </SubMenu>
      <SubMenu title={t('showHideColumns')}>
        <div className={'pt-1'}>
          <div className={'py-1 px-3 border-0 border-b border-solid border-gray-300 mb-3'}>
            <Space>
              <Checkbox
                checked={allVisible}
                indeterminate={someColumnHidden}
                onChange={() => toggleAllHidden(someColumnHidden ? true : !allVisible)}>{t('showColumns')}</Checkbox>
              <Button type={'link'} onClick={() => toggleAllHidden(true)} disabled={allVisible}>
                {t('reset')}
              </Button>
            </Space>
          </div>
          {
            columns.map(c => <div key={`col-${c.dataIndex}`} className={'py-1 px-3 hover:bg-item-hover-bg'}>
              <Checkbox checked={c.visible} onChange={() => handleDeletedCols(c.dataIndex)}>
                {c.title}
              </Checkbox>
            </div>)
          }
        </div>
      </SubMenu>
    </Menu>
  );

  return <div className={'hidden lg:block'}>
    <Dropdown overlay={menu} trigger={['click']}>
      <Tooltip title={t('tableSettings')}>
        <Button icon={<SettingOutlined/>} type={'text'}/>
      </Tooltip>
    </Dropdown>
  </div>;
};

export default memo(TableMenu);
