/**
 * @author Angel Labrada
 * @since v0.0.1
 * @date 23/9/21
 */
import React, {memo} from 'react';
import AdminHeader from '@/components/TableTree/TableHeader';
import PropTypes from 'prop-types';
import {Card} from 'antd';
import {TableContextProvider} from '@/contexts/tableGridContact';
import EntityMenu from '@/components/EntityMenu';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {ADD_HANDLER_DIALOG} from '@/utils/createEntityContainer';
import DirectoriesData from '@/components/DirectoryLits/DirectoriesData';
import {useTranslation} from 'react-i18next';

const DirectoryList = ({
  tableTitle,
  extras,
  columns,
  useTableData,
  canSelect,
  rowActions,
  actionsLabel,
  addLabel,
  translation,
  selectedActions,
  filters,
  canAdd,
  route,
  useCreatePath,
  useMapColumns,
  searchPlaceholder,
  canSearch,
  hasTable = true,
  showLatest,
  countLatest,
  latestTitle,
  actionsViewByRoute,
  actions,
  onAddModalName,
  module,
  ...props
}) => {
  const { openDialog } = useDialogsContext(onAddModalName || module || ADD_HANDLER_DIALOG);

  const {t} = useTranslation(translation);

  if (rowActions && !columns.some(c => c.dataIndex === 'actions')) {
    columns.push(
      {
        title: actionsLabel,
        dataIndex: 'actions',
        width: 100,
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
          <EntityMenu data={record}/>
        )
      }
    );
  }

  return (
    <Card className={'responsive-card w-full'}>
      <TableContextProvider columns={columns} translation={translation} useMapColumns={useMapColumns}>
        {showLatest && (
          <div className="mb-8">
            <AdminHeader
              title={t(latestTitle)}
              onAdd={() => openDialog()}
              useCreatePath={useCreatePath}
              route={route}
              addLabel={addLabel}
              extras={extras}
              hasTable={hasTable}
              canAdd={canAdd}
              canSearch={canSearch}
              filters={filters}
              translation={translation}
              searchPlaceholder={searchPlaceholder}
              actionsViewByRoute={actionsViewByRoute}
              actions={actions}
              hideActionTable
            />
            <DirectoriesData
              selectedActions={selectedActions}
              tableHook={useTableData}
              canSelect={canSelect}
              showLatest={showLatest}
              countLatest={countLatest}
              translation={translation}
              module={module}
              {...props}/>
          </div>
        )}

        <AdminHeader
          title={tableTitle}
          onAdd={() => openDialog()}
          useCreatePath={useCreatePath}
          route={route}
          addLabel={addLabel}
          extras={extras}
          hasTable={!showLatest && hasTable}
          canAdd={!showLatest && canAdd}
          canSearch={!showLatest && canSearch}
          filters={!showLatest && filters}
          translation={translation}
          searchPlaceholder={searchPlaceholder}
          actionsViewByRoute={actionsViewByRoute}
          actions={actions}
          hideActionTable
        />

        <DirectoriesData
          selectedActions={selectedActions}
          tableHook={useTableData}
          canSelect={canSelect}
          translation={translation}
          module={module}
          {...props}
        />
      </TableContextProvider>
    </Card>
  );

};

DirectoryList.propTypes = {
  actionsLabel: PropTypes.string,
  addLabel: PropTypes.string,
  canSelect: PropTypes.bool,
  canSearch: PropTypes.bool,
  hasTable: PropTypes.bool,
  columns: PropTypes.array,
  extras: PropTypes.node,
  filters: PropTypes.any,
  onAdd: PropTypes.func,
  route: PropTypes.string,
  rowActions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  selectedActions: PropTypes.func,
  tableHook: PropTypes.func,
  tableTitle: PropTypes.string.isRequired,
  translation: PropTypes.string,
  translationPrefix: PropTypes.string,
  useCreatePath: PropTypes.bool,
  useTableData: PropTypes.func.isRequired,
  canAdd: PropTypes.bool,
  useMapColumns: PropTypes.any,
  searchPlaceholder: PropTypes.any,
  showLatest: PropTypes.bool,
  countLatest: PropTypes.number,
  latestTitle: PropTypes.string,
  actionsViewByRoute: PropTypes.bool,
  actions: PropTypes.array,
  onAddModalName: PropTypes.string,
  module: PropTypes.string,
};

DirectoryList.defaultProps = {
  canAdd: true,
  canSearch: true
};

export default memo(DirectoryList);
